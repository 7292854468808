import { WebStorageStateStore } from "oidc-client-ts";

//vonage : 63c40af00f5463ce4984727f
// Likewatercs: 63c40af00f5463ce4984727f
// Site Contabil: 64e51929056350740186a0d3
// Likewatercs Atendimento interno: 651481863ab7ac42288cd2dd
// Santucci: 653ac574ce931c0584602e91
// Progesa: 65bbf7251cce506b9c487dfc
//prefeitura: 667dbe016373120f9fcf54af

export default {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: "https://login.espressolw.com",
  client_id: "64e51929056350740186a0d3",
  redirect_uri: new URL("/callback.html", document.location.href).href,
  automaticSilentRenew: true,
  silent_redirect_uri: new URL("/silent-renew.html", document.location.href)
    .href,
  response_type: "code",
  scope: "openid offline_access api",
  post_logout_redirect_uri: new URL("/", document.location.href).href,
  filterProtocolClaims: true,
};
