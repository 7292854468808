import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import ChatIcon from '@mui/icons-material/Chat';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewListIcon from '@mui/icons-material/ViewList';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HelpIcon from '@mui/icons-material/Help';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import DraftsIcon from '@mui/icons-material/Drafts';

import SmallModal from "../UI/Modals/Small";

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

function statusCheck(status) {
  switch (status) {
    case "error": return <ErrorIcon style={{ color: "#e74c3c" }} />
    case "sent": return <CheckCircleIcon style={{ color: "#1abc9c" }} />
    case "new": return <FiberNewIcon style={{ color: "#e67e22" }} />
    case "delivered": return <LocalPostOfficeIcon style={{ color: "#3498db" }} />
    case "read": return <DraftsIcon style={{ color: "#1abc9c" }} />
    default: return <HelpIcon style={{ color: "#f39c12" }}/>
  }
}

function statusPortuguese(status) {
  switch(status) {
    case "error": return 'Erro';
    case "sent" : return 'Enviado';
    case "new" : return 'Novo';
    case "delivered" : return 'Entregue';
    case 'read' : return 'Lido';
    default: return 'Falha';
  }
}

export default function SummarizeTable({ retornoAPI, nameList }) {
  var rows = [];

  const columns = [
    {
      field: "Data do envio",
      headerAlign: "center",
      align: "center",
      headerName: "Data do envio",
      type: "dateTime",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="table-cell-trucate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "Telefone envio",
      headerName: "Destinatario",
      headerAlign: "center",
      align: "center",
      flex: 1.2,
    },
    {
      field: "Usuario",
      headerAlign: "center",
      align: "center",
      headerName: "Usuario",
      flex: 1.8,
    },
    {
      field: "Status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      renderCell: (params) => (
        <div>{statusPortuguese(params.value)}</div>
        // <Tooltip title={params.value}>
        //   <span className="table-cell-trucate">{statusCheck(params.value)}</span>
        // </Tooltip>
      ),
    },
    {
      field: "Nome da Lista",
      headerName: "Nome da Lista",
      headerAlign: "center",
      align: "center",
      flex: 1.3,
    },
    // {
    //   field: "Id do Broker",
    //   headerName: "Id do Broker",
    //   flex: 1.3,
    //   headerAlign: "center",
    // },
    // {
    //   field: "Id do envio",
    //   headerName: "Id do envio",
    //   flex: 1.9,
    //   headerAlign: "center",
    // },
    // {
    //   field: "Id da Lista",
    //   headerName: "Id da Lista",
    //   flex: 1.9,
    //   headerAlign: "center",
    // },
    {
      field: "Detalhe",
      headerName: "Detalhe",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
       <Tooltip title={params.value}>
         <div className="break-line">
          {params.value}
        </div>
       </Tooltip>
        // <strong>
        //   <SmallModal
        //     modalSize={{ height: 40, width: 30 }}
        //     iconButton={true}
        //     buttonString={<VisibilityIcon style={{ color: "#1abc9c" }} />}
        //     boxName="Descrição dos Detalhes"
        //     styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
        //   >
        //     {params.value}
        //   </SmallModal>
        // </strong>
      ),
    },
    {
      field: "codes",
      headerName: "Códigos",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      valueFormatter:(value)=>`${value.value.lista}`,
      renderCell: (params) => (
        <strong>
          <SmallModal
            iconButton={true}
            buttonString={<ViewListIcon style={{ color: "#1abc9c" }} />}
            boxName="Códigos (ID's)"
            styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
          >
            <div className="codeAlign">
              <div className="codeStyle">
                <label>ID do Broker</label>
                <input value={params.value.broker} />
              </div>

              <div className="codeStyle">
                <label>ID do Envio</label>
                <input value={params.value.envio} />
              </div>

              <div className="codeStyle">
                <label>ID da Lista</label>
                <input value={params.value.lista} />
              </div>
            </div>
          </SmallModal>
        </strong>
      ),
    },
    {
      field: "Mensagem",
      headerName: "Mensagem",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <strong>
          <SmallModal
            iconButton={true}
            buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
            boxName="Mensagem"
            styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
          >
            <div className="msgAdjust">{params.value.replace(/\\n/g, "\n")}</div>
          </SmallModal>
        </strong>
      ),
    },
  ];

  if (retornoAPI.length !== 0) {
    for (let i = 0; i < retornoAPI.length; i++) {
      retornoAPI[i]["Data do envio"] = retornoAPI[i]["Data do envio"].toLocaleString();
      rows.push({
        id: i + 1, ...retornoAPI[i],
        codes: {
          broker: retornoAPI[i]["Id do Broker"],
          envio: retornoAPI[i]["Id do envio"],
          lista: retornoAPI[i]["Id da Lista"],
        },
      });
    }
  }

  return (
    <div style={{ height: "110%" }}>
      <DataGrid
        columnVisibilityModel={{
          "Nome da Lista": nameList,
        }}
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
