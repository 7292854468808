import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import GenericTable from "../../components/tables/genericTable.js";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import { config } from "../../services/utils";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ActionBtn from "../../components/UI/actionbtn";
import ModalAnaliticsA from "./modals/analiticsA";
import ModalCall from "../../components/tables/modalOpen";
import CircularLoading from "../../components/loadModules/circularLoading";

export default function Summarize() {
  const [data, setData] = useState([]);
  const [call, setCall] = useState(true);

  const clearData = () => {
    setData([]);
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-4 pt-2">
        <p className="titlePage">Sentimento - Analítico</p>
        <div className="d-flex">
          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <ModalCall
            modalSize={{ height: 80, width: 35 }}
            buttonString={<SearchIcon />}
            boxName="Pesquisa de Sentimento Analítico"
            className="send"
            styleButton={{
              bgcolor: "#007bff;",
              color: "white",
              padding: 1.1,
              fontSize: 16,
              fontWeight: 700,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff;" },
            }}
          >
            <div className="center">
              <ModalAnaliticsA setData={setData} setCall={setCall} />
            </div>
          </ModalCall>
        </div>
      </div>

      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          {call ? (
            <GenericTable
              data={data}
              columns={[
                {
                  field: "date",
                  headerName: "Data",
                  flex: 1,
                  type: "dateTime",
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <div>
                      <Tooltip title={new Date(params.value).toLocaleString()}>
                        <span className="table-cell-trucate">
                          {new Date(params.value).toLocaleString()}
                        </span>
                      </Tooltip>
                    </div>
                  ),
                },
                {
                  field: "phone",
                  headerName: "Telefone",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "origin",
                  headerName: "Origem",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "from",
                  headerName: "Remetente",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "word",
                  headerName: "Resposta",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "sentimento",
                  headerName: "Sentimento",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => {
                    switch (params.value) {
                      case -1:
                        return <span>Negativo</span>;
                      case 0:
                        return <span>Neutro</span>;
                      case 1:
                        return <span>Positivo</span>;
                      default:
                        return <span>-</span>;
                    }
                  },
                },
              ]}
            />
          ) : (
            <div style={{ width: "96%", height: "110%" }}>
              <CircularLoading />
            </div>
          )}
        </StyledEngineProvider>
      </div>
    </div>
  );
}
