import React, { useEffect, useState } from "react";
import "./subHeaderComponent.css";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import api from "../../../services/api";
import { config } from "../../../services/utils";

export default function SubHeaderComponent({ onFilter, telefone, origin }) {
  const handleChange = (event) => {
    setInput(event.target.value);
    onFilter(input, type);
  };
  // const openFilter = () => {
  //   onFilter("");
  //   setShowInput(!showInput);
  // };
  const cleanFilter = (event) => {
    setInput("");
    onFilter("", type);
  };
  // const [showInput, setShowInput] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [type, setType] = React.useState("message");
  const [placeholder, setPlaceholder] = React.useState("Digite a mensagem");
  const [borderColor, setBorderColor] = useState("border-green");
  const [percent, setPercent] = useState(0);
  const [timer, setTimer] = useState("00:00:00");
  const [intervalID, setIntervalID] = useState(null);
  const [status, setStatus] = useState({});
  const [state, setState] = useState(false);

  const hasHibernationOption = config.application.features.prolongHibernation;

  const handleChangeSelect = (event) => {
    setType(event.target.value);
    if (event.target.value == "date") {
      setPlaceholder("Digite a data");
    } else {
      setPlaceholder("Digite a mensagem");
    }
  };

  useEffect(() => {
    if (hasHibernationOption) {
      getStatus();
      setTimeout(() => {
        setState(!state);
      }, 10000);
    }
  }, [state]);

  useEffect(() => {
    if (status.lastMessage) {
      timerCreate(status.startHibernationTime, status.lastMessage);
    }
  }, [status.lastMessage, status.startHibernationTime]);

  async function getStatus() {
    await api
      .get(`api/espresso/hibernation/status/${telefone}/${origin}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStatus(res.data);
      })
      .catch((e) => {
        return e;
      });
  }
  function timerCreate(el, lastMessage) {
    const timer = el * 60000;
    const lastDate = new Date(lastMessage);
    const limitTimer = lastDate.getTime() + timer;

    if (intervalID) {
      clearInterval(intervalID);
    }

    const newIntervalID = setInterval(() => {
      const nowTimer = new Date();
      const timerRemain = Math.max(limitTimer - nowTimer.getTime(), 0);
      const percent = Math.floor((timerRemain / timer) * 100);

      setPercent(percent);
      setTimer(formatTimer(timerRemain / 1000));

      if (timerRemain <= 0) {
        clearInterval(newIntervalID);
      }
    }, 1000);

    setIntervalID(newIntervalID);
  }

  function formatTimer(timer) {
    if (timer > 0) {
      const hour = Math.floor(timer / 3600);
      const minutes = Math.floor((timer % 3600) / 60);
      const seconds = Math.floor(timer % 60);
      return `${hour.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return "NaN:NaN:NaN";
    }
  }

  function setColor() {
    if (percent) {
      let remainPercent = 100 - percent;

      if (remainPercent <= 50) {
        return "border-green";
      }
      if (remainPercent > 50 && remainPercent <= 70) {
        return "border-yellow";
      }
      if (remainPercent > 70) {
        return "border-red";
      }
    } else {
      return "border-red";
    }
  }

  useEffect(() => {
    setBorderColor(setColor());
  }, [percent]);

  return (
    <div className="filter-container d-flex align-items-center justify-content-between">
      <div className={"search-container"}>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            maxHeight: 100,
            color: "#fff",
            border: "none",
            boxShadow: "none",
            "&:focus": {
              borderRadius: 4,
              borderColor: "#fff",
              boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
            },
          }}
          size="small"
        >
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={type}
            label="Tipo"
            onChange={handleChangeSelect}
            sx={{ color: "#fff", height: "30px", fontSize: "12px" }}
          >
            <MenuItem sx={{ fontSize: "12px" }} value={"date"}>
              Data
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px" }} value={"message"}>
              Mensagem
            </MenuItem>
          </Select>
        </FormControl>
        <input
          className={"input-search"}
          placeholder={placeholder}
          value={input}
          onChange={handleChange}
        />
        <button className={"btn-search"}>Pesquisar</button>
        <button onClick={cleanFilter} className="btn-no-borders">
          <CloseIcon className={"icon-close"} />
        </button>
      </div>

      {timer !== "NaN:NaN:NaN" &&
      hasHibernationOption &&
      timer !== "00:00:00" ? (
        <div className={`py-2 me-5 timer-container ${borderColor}`}>
          <span>{timer}</span>
        </div>
      ) : null}
    </div>
  );
}
