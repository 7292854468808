import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles({
  header: {
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  },
  lateralHeader: {
    color: "black",
    fontSize: "14px",
  },
  lateralCell: {
    color: "black",
    fontSize: "14px",
  },
  totalCell: {
    color: "black",
    fontWeight: "bold",
    fontSize: "14px",
    background: "#e6e6e6",
  },
  noRowsOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "18px",
    color: "black",
  },
});

const columns = [
  {
    field: "qtdCasos",
    headerName: "Qtd. Casos",
    width: 150,
  },
  { field: "disconnected", headerName: "Desconectado", width: 150 },
  { field: "percentDisconnected", headerName: "%", width: 150 },
  { field: "returnedAgent", headerName: "Retorno para agente", width: 150 },
  { field: "percentReturnedAgent", headerName: "%", width: 150 },
  { field: "returnedQueue", headerName: "Retorno para fila", width: 250 },
  { field: "percentReturnedQueue", headerName: "%", width: 150 },
];
function NoRowsOverlay() {
  const classes = useStyles();
  return (
    <Box className={classes.noRowsOverlay}>
      <Typography variant="h6">Nenhum dado disponível</Typography>
    </Box>
  );
}

function UniqueNumber({ data }) {
  const classes = useStyles();

  return (
    <div style={{ height: "85vh", minHeight: 350, width: "100%" }}>
      <DataGrid rows={data} columns={columns} hideFooter />
    </div>
  );
}

export default UniqueNumber;
