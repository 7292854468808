import React from "react";
import "./cardMonitor.css";
import Chart from "react-apexcharts";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";

const statusOptions = {
  ativo: {
    color: "rgba(0, 255, 0, 1)",
    percent: 100,
  },
  alerta: {
    color: "rgba(251, 247, 0, 1)",
    percent: 50,
  },
  inativo: {
    color: "rgba(255, 0, 0, 1)",
    percent: 0,
  },
};

function zero(status) {
  if (status == "inativo") {
    return "rgba(255, 0, 0, 1)";
  } else {
    return "#ededed";
  }
}
function classLabel(status) {
  if (status == "inativo") {
    return "card-footer-status card-footer-monitoring-error";
  }
  if (status == "alerta") {
    return "card-footer-monitoring-danger card-footer-status";
  }
  if (status == "ativo") {
    return "card-footer-monitoring-success card-footer-status";
  }
}

function icon(status) {
  if (status == "inativo") {
    return (
      <ReportOffOutlinedIcon className="card-body-monitoring-icon card-body-monitoring-icon-danger" />
    );
  }
  if (status == "alerta") {
    return (
      <ReportGmailerrorredOutlinedIcon className="card-body-monitoring-icon card-body-monitoring-icon-alert" />
    );
  }
  if (status == "ativo") {
    return (
      <CheckCircleOutlinedIcon className="card-body-monitoring-icon card-body-monitoring-icon-success" />
      
    );
  }
}

export default function CardMonitor({ data, index }) {
  const { color, percent } = statusOptions[data.status.toLowerCase()];
  let series = [percent];
  let options = {
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: zero(data.status),
          strokeWidth: "100%",
          opacity: 1,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "0px",
          },
        },
      },
    },

    colors: [color],
  };
  return (
    <div id={index} className="card-monitoring">
      <div className="card-header-monitoring">
        <span>{data.name}</span>
      </div>
      <div className="card-body-monitoring">
        <Chart
          options={options}
          series={series}
          width="100%"
          type="radialBar"
        />
        {icon(data.status)}
      </div>
      <div className="card-footer-monitoring">
        <span>
          Status: <span className={classLabel(data.status)}>{data.status}</span>
        </span>
      </div>
    </div>
  );
}
