import { config } from "../utils.js";

export const dashMenus = [
  { key: "bolsaoDashboard", label: "Bolsão", route: "/app/dashboard/bolsao" },
  { key: "sendDashboard", label: "Envios", route: "/app/dashboard" },
  {
    key: "wordCloudDashboard",
    label: "Nuvem de palavras",
    route: "/app/dashboard/tagcloud",
  },
  {
    key: "botWorkFlowDashboard",
    label: "Fluxo do Bot",
    route: "/app/dashboard/milestone",
  },
  {
    key: "interactionDashboard",
    label: "Conversas",
    route: "/app/dashboard/interactions",
  },
  { key: "uraDashboard", label: "Ura", route: "/app/dashboard/ura" },
];

export const HibernationDashboard = [
  {
    key: "hibernationDashboard_graph",
    label: "Atendidas",
    route: "/app/dashboard/hibernation-interactions",
  },
  {
    key: "hibernationDashboard_graph",
    label: "Status",
    route: "/app/dashboard/hibernation-status",
  },
  {
    key: "hibernationDashboard_graph",
    label: "Mapa",
    route: "/app/dashboard/hibernation-map",
  },
  {
    key: "hibernationDashboard_graph",
    label: "Números únicos",
    route: "/app/dashboard/unique-number",
  },
  {
    key: "hibernationDashboard_graph",
    label: "Ranking",
    route: "/app/dashboard/unique-number-ranking",
  },
];

export const hasHibernationDashboardItems = HibernationDashboard.some(
  (item) => config.application.menus?.[item.key]
);

export const hasDashboardItems = dashMenus.some(
  (item) => config.application.menus?.[item.key]
);
