import React, { useState } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import CustomizedDialogs from "./modalOpen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ChatIcon from "@mui/icons-material/Chat";
import RegisterMessageHSM from "../../pages/messages/registerMessage";
import ModalCall from "./modalOpen";
import LargeModal from "../UI/Modals/Large";
import { dellCell } from "../../services/utils";
import IconButton from "@mui/material/IconButton";
import { config } from "../../services/utils";

// ---- Logos ----//
import twilioLogo from "../../images/twilio.png";
import interaxaAltLogo from "../../images/interaxaAlt.png";
import zenviaLogo from "../../images/Zenvia.png";
import oktorLogo from "../../images/oktor.png";
import blipLogo from "../../images/blip.png";
import vonageLogo from "../../images/vonage.png"
import dialogLogo from "../../images/dialog.png"


export default function MessagesListTable({ messages }) {
  const [cdMessage, setCDMessage] = useState({});

  function brokerImage(broker){
    switch (broker) {
      case 'interaxa': return <img src={interaxaAltLogo} alt="" className="brokerSizer"/>
      case 'twilio': return <img src={twilioLogo} alt="" className="brokerSizer"/>
      case 'zenvia': return <img src={zenviaLogo} alt="" className="brokerSizer"/>
      case 'blip': return <img src={blipLogo} alt="" className="brokerSizer"/>
      case 'take': return <img src={blipLogo} alt="" className="brokerSizer"/>
      case 'vonage': return <img src={vonageLogo} alt="" className="brokerSizer"/>
      case 'oktor': return <img src={oktorLogo} alt="" className="brokerSizer"/>
      case 'dialog': return <img src={dialogLogo} alt="" className="brokerSizer"/>
      default:
        <span>{broker}</span>;
    }
  }

  const columns = [
    { field: "codemessage", headerName: "ID", flex: 2 },
    { field: "createDate", headerName: "Data",type: "dateTime", flex: 2, hide: true },

    {
      field: "broker",
      headerName: "Broker",
      flex: 1,
      hide: (!config.application.modules.templates.broker),
      renderCell: (params) => brokerImage(params.value),
    },

    { field: "type", headerName: "Tipo", flex: 1 },
    {
      field: "parametrosId",
      headerName: "Parâmetros",
      flex: 1,
      headerAlign: "left",
      valueGetter: (params) => {
        return JSON.stringify(params.getValue(params.id, "parametros"));
      },
    },
    {
      field: "body",
      headerName: "Mensagem",
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <strong>
        <CustomizedDialogs
          modalSize={{ height: 40, width: 30 }}
          buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
          boxName="Mensagem"
          styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
          iconButton={true}
        >
          {params.value}
        </CustomizedDialogs>
      </strong>
      ),
    },
    {
      field: "alteration",
      headerName: "Alterações",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="inline_Alt">
          <div>
            <LargeModal
              modalSize={{height:80,width:35}}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Mensagem"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <RegisterMessageHSM codemessage={params.value} />
            </LargeModal>
          </div>
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c"}} onClick={() => {dellCell(params.value);}}> 
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < messages.length; i++) {
    rows.push({
      id: i + 1,
      alteration: messages[i].codemessage,
      ...messages[i],
    });
  }

  return (
    <div style={{ height: '110%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createDate", sort: "desc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
