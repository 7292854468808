import React from 'react';
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import './WrapupTable.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import api from '../../services/api';
import { toast } from 'react-toastify';
import ModalCall from '../../components/tables/modalOpen';
import WrapupQueue from './WrapupQueue';
import IconButton from "@mui/material/IconButton";

export default function WrapupTable({ wrapups }) {
  // const [wrapups, setWrapup] = useState({});

  const columns = [
    { field: 'queue', headerName: 'Fila', flex: 2 },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 1,
    },
    {
      field: 'alteration',
      headerName: 'Alterações',
      flex: 0.4,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div className="inline_Alt">
          <div onClick={() => {}}>
            <ModalCall
              modalSize={{ height: 80, width: 85 }}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Tabulações"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <WrapupQueue selectedQueue={params.value} />
            </ModalCall>
          </div>
          <div onClick={() => {}}>
            <ModalCall
              modalSize={{ height: 80, width: 85 }}
              buttonString={<ContentCopyIcon style={{ color: "#1abc9c" }}/>}
              iconButton={true}
              boxName="Clonar Tabulações"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <WrapupQueue selectedQueue={{ queue: '', descricao: '', child: [...params.value.child] }} />
            </ModalCall>
          </div>
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c"}}
            onClick={() => {
              if (window.confirm('Deseja mesmo Excluir ?')) {
                api
                  .post(`api/espresso/tabulacao/delete/${params.value._id}`, {}, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                  })
                  .then((res) => {
                    toast.success('Deletando Tabulação Aguarde!');
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);

                    return;
                  })
                  .catch((err) => {
                    toast.error('Erro #003: Falha ao acessar o banco de dados');
                  });
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < wrapups.length; i++) {
    rows.push({
      id: i + 1,
      alteration: wrapups[i],
      ...wrapups[i],
    });
  }

  return (
    <div style={{ height: '110%', width: '98%' }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'createDate', sort: 'desc' }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
