import summarizeStyle from "./summarize.module.css";
import React, { useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import TableCall from "../../components/tables/summarizeSendsTable";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function SmsList() {
  const defaultSMSSearch = {
    from_dateTime: "",
    to_dateTime: "",
    listName: "Selecione...",
    columns: {},
  };

  const [SMSSearch, setSMSSearch] = useState({ ...defaultSMSSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);

  const handleChange = (type, value) => {
    let _SMSSearch = { ...SMSSearch };
    _SMSSearch[type] = value;
    setSMSSearch({ ..._SMSSearch });
  };

  const loadTables = async () => {
    let smsReq = {};

    if (SMSSearch.from_dateTime && SMSSearch.to_dateTime) {
      SMSSearch.from_dateTime = new Date(SMSSearch.from_dateTime);
      SMSSearch.to_dateTime = new Date(SMSSearch.to_dateTime);

      smsReq = {
        from_dateTime: SMSSearch.from_dateTime,
        to_dateTime: SMSSearch.to_dateTime,
      };
    }

    await api
      .post("api/espresso/sentSMSList", smsReq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha na pesquisa");
      });
    return;
  };

  const cleanSearch = () => {
    setSMSSearch({ ...defaultSMSSearch });
    setRetornoAPI({ ...[] });
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-4">
        <p className="titlePage">SMS por Lista</p>
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center ${summarizeStyle.containerSelectHeader} me-3`}
          >
            <label className="defaultLabel me-2">Lista:</label>
            <select
              className="select-bootstrap"
              value={SMSSearch.listName}
              onChange={(e) => handleChange("listName", e.target.value)}
            >
              <option defaultValue> Selecione ...</option>
            </select>
          </div>
          <div
            className={`d-flex align-items-center ${summarizeStyle.containerTimeHeader} me-3`}
          >
            <label className="defaultLabel me-2">De:</label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={SMSSearch.from_dateTime}
              id="searchdatefrom"
              onChange={(e) => handleChange("from_dateTime", e.target.value)}
            />
          </div>
          <div
            className={`d-flex align-items-center ${summarizeStyle.containerTimeHeader} me-3`}
          >
            <label className="defaultLabel me-2">Até:</label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={SMSSearch.to_dateTime}
              id="searchdateto"
              onChange={(e) => handleChange("to_dateTime", e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          <TableCall retornoAPI={retornoAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
