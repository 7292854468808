import React from "react";
import Chart from "react-apexcharts";

export default function Radial(props) {
  const config = {
    // options: {
    //   colors: [props.color], //['#E74C3C', '#1ABC9C'],
    //   labels: [props.series], //labels: ["Notificados","Nao notificados"],
    //   dataLabels: { enabled: false },

    //   plotOptions: {
    //     radialBar: {
    //       offsetY: 0,
    //       startAngle: -90,
    //       endAngle: 90,
    //       hollow: {
    //         margin: 0,
    //         background: "transparent",
    //         image: undefined,
    //       },
    //       dataLabels: {
    //         name: {
    //           show: true,
    //         },
    //         value: {
    //           show: true,
    //           fontSize: "1vw",
    //         },
    //         total: {
    //           show: true,
    //           label: props.title,
    //           fontSize: "1vw",
    //           offsetY: 20,
    //         },
    //       },
    //     },
    //   },
    // },
    options: {
      colors: [props.color],
      series: [76],
      chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    labels: ['Average Results'],
    }
  };

  return props.data.length > 0 ? (
      <Chart
        options={config.options}
        series={props.data}
        type="radialBar"
        width="150%"
      />
  ) : (<div className="lds-default">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>);
}
