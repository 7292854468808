import { config, maxDays } from "../../../services/utils";
import analitcsStyle from "./analitics.module.css";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";

export default function ModalAnaliticsA({ setData, setCall }) {
  const _filter = {
    date: { from: "", to: "" },
    origin: "",
    number: "",
    from: "",
    word: "",
    sentimento: "",
  };

  const [filter, setFilter] = useState(_filter);

  const queryData = async (event) => {
    event.preventDefault();
    setCall(false);
    if (maxDays(filter.date.from, filter.date.to, 10)) {
      const apiFilter = {};
      if (filter.origin !== "") apiFilter.origin = filter.origin;
      if (filter.number !== "") apiFilter.phone = filter.number;
      if (filter.date.from !== "") apiFilter.date_start = filter.date.from;
      if (filter.date.to !== "") apiFilter.date_end = filter.date.to;
      if (filter.word !== "") apiFilter.word = filter.word;
      if (filter.sentimento !== "") apiFilter.sentimento = filter.sentimento;
      if (filter.remetente !== "") apiFilter.remetente = filter.remetente;
      await api
        .get("api/database/report/sentimentoa", {
          params: apiFilter,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Não há relatórios para esta data");
          } else {
            toast.success("Busca realizada com sucesso");
          }
          setCall(true);
          setData(res.data);
          return;
        })
        .catch((err) => {
          setCall(true);
          toast.error("Erro #003: Falha na pesquisa");
        });
    } else {
      setCall(true);
      toast.warn("As datas excedem o valor de 10 dias");
    }
  };

  return (
    <div className={analitcsStyle.analiticsField}>
      <form
        className="d-flex flex-column w-100"
        onSubmit={(e) => {
          queryData(e);
        }}
      >
        <label className="defaultLabel">Origem</label>
        <select
          required
          className="select-bootstrap mt-1 mb-3"
          value={filter.origin}
          onChange={(e) => setFilter({ ...filter, origin: e.target.value })}
        >
          <option value="">Selecione a Origem</option>
          {config.summarize.map((value) => (
            <option value={value.origin}>{value.visualName}</option>
          ))}
        </select>

        <label className="defaultLabel">Número</label>
        <div className={analitcsStyle.inputContainer}>
          <input
            type="text"
            value={filter.number}
            id="number"
            placeholder="Exemplo: +5511912345678"
            onChange={(e) => setFilter({ ...filter, number: e.target.value })}
            className="input-bootstrap mt-1 mb-3"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between mb-3">
          <div
            className={`d-flex align-items-center ${analitcsStyle.timerContainer}`}
          >
            <label className="defaultLabel me-2">De</label>
            <input
              type="datetime-local"
              value={filter.date.from}
              id="searchdatefrom"
              className=" input-bootstrap"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, from: e.target.value },
                })
              }
            />
          </div>
          <div
            className={`d-flex align-items-center ${analitcsStyle.timerContainer}`}
          >
            <label className="defaultLabel me-2">Até</label>
            <input
              type="datetime-local"
              value={filter.date.to}
              id="searchdateto"
              className="input-bootstrap"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, to: e.target.value },
                })
              }
            />
          </div>
        </div>

        <label className="defaultLabel">Remetente</label>
        <div className={analitcsStyle.inputContainer}>
          <input
            className="input-bootstrap mt-1 mb-3"
            type="text"
            value={filter.from}
            id="from"
            onChange={(e) => setFilter({ ...filter, from: e.target.value })}
          />
        </div>

        <label className="defaultLabel">Palavra</label>
        <div className={analitcsStyle.inputContainer}>
          <input
            type="text"
            value={filter.word}
            id="word"
            className="input-bootstrap mt-1 mb-3"
            onChange={(e) => setFilter({ ...filter, word: e.target.value })}
          />
        </div>

        <label className="defaultLabel">Sentimento</label>
        <select
          className="select-bootstrap mt-1 mb-4"
          value={filter.sentimento}
          onChange={(e) => setFilter({ ...filter, sentimento: e.target.value })}
        >
          <option value="" defaultValue>
            Todos
          </option>
          <option value="1">Positivo</option>
          <option value="0">Neutro</option>
          <option value="-1">Negativo</option>
        </select>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary containerBtn-4" type="submit">
            Pesquisar
          </button>
        </div>
      </form>
    </div>
  );
}
