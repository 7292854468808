import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";

// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// const columns = [
//   {
//     field: "OrigemBolsao",
//     headerName: "Origem Bolsao",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//   },
//   // {
//   //   field: "Tabulacao",
//   //   headerName: "Tabulacao",
//   //   flex: 1,
//   //   align: "left",
//   //   headerAlign: "Left",
//   //   renderCell: (params) => (
//   //     <div>{params.value === null ? "-" : params.value}</div>
//   //   ),
//   // },
//   {
//     field: "Telefone",
//     headerName: "Telefone",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "FilaOrigem",
//     headerName: "Fila Origem",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//   },
//   {
//     field: "DtEntrada",
//     headerName: "Data de Entrada",
//     flex: 1,
//     type: "dateTime",
//     align: "center",
//     headerAlign: "center",
//     renderCell: (params) => (
//       <div>
//       <Tooltip title={new Date(params.value).toLocaleString()}>
//         <span className="table-cell-trucate">{new Date(params.value).toLocaleString()}</span>
//       </Tooltip>
//     </div>
//     ),
//   },
//   {
//     field: "DtNotificado",
//     headerName: "Data Notificado",
//     type: "dateTime",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//     renderCell: (params) => (
//       <div>
//         {params.value ? (
//           <div>
//             <Tooltip title={new Date(params.value).toLocaleString()}>
//               <span className="table-cell-trucate">
//                 {new Date(params.value).toLocaleString()}
//               </span>
//             </Tooltip>
//           </div>
//         ) : (
//           "-"
//         )}
//       </div>
//     ),
//   },
//   {
//     field: "Notificado",
//     headerName: "Notificado ",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//     renderCell: (params) => (
//       <div>{params.value === true ? <CheckCircleIcon sx={{color: "#1abc9c"}}/> : <RemoveCircleIcon sx={{color: "#e74c3c"}}/>} </div>
//     ),
//   },
//   {
//     field: "Opcao",
//     headerName: "Opcao",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//     renderCell: (params) => (
//       <div>{params.value === null ? "-" : params.value}</div>
//     ),
//   },
//   {
//     field: "DtOpcao",
//     headerName: "Data da Opcao",
//     type: "dateTime",
//     flex: 1,
//     align: "center",
//     headerAlign: "center",
//     renderCell: (params) => (
//       <div>
//         {params.value === null ? (
//           "-"
//         ) : (
//           <div>
//             <Tooltip title={new Date(params.value).toLocaleString()}>
//               <span className="table-cell-trucate">
//                 {new Date(params.value).toLocaleString()}
//               </span>
//             </Tooltip>
//           </div>
//         )}
//       </div>
//     ),
//   },
// ];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

export default function genericTable({ data, columns }) {
  var rows = [];

  if (data.length !== 0) {
    for (let i = 0; i < data.length; i++) {
      rows.push({ id: i + 1, ...data[i] });
    }
  }

  return (
    <div style={{ height: "110%" }}>
      <DataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
