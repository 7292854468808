import React, { useEffect, useState } from "react";
import dashStyles from "./dashboard.module.css";
import RadialChart from "../../components/charts/radialTest";
import api from "../../services/api";
import Table from "../../components/tables/realTimeTable1";
import Table2 from "../../components/tables/realTimeTable2";
import { StyledEngineProvider } from "@mui/material/styles";

export default function Dashboard() {
  const [apiRTL1, setApiRTL1] = useState([]);
  const [apiRTL2, setApiRTL2] = useState([]);
  const [bolsaoSize, setBolsaoSize] = useState(0);
  const [notify, setNotify] = useState(0);
  const [perNotify, setPerNotify] = useState(0);
  const [perBolsao, setPerBolsao] = useState(0);
  const [exec, setExec] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadTables() {
      const bolsaoRT1 = await api.get("api/inbound/report/getBolsaoRealTime", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const bolsaoRT2 = await api.get(
        "api/inbound/report/getBolsaoRealTimeByQueue",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setApiRTL1(bolsaoRT1.data);
      setApiRTL2(bolsaoRT2.data);
      setBolsaoSize(bolsaoRT1.data.length);
      setNotify(
        bolsaoRT1.data.filter((element) => element.Notificado === true).length
      );

      if (bolsaoSize === 0 || bolsaoSize === null) {
        setPerNotify([0]);
        setPerBolsao([0]);
      }

      if (notify === 0 && bolsaoSize >= 1) {
        setPerNotify([0]);
        setPerBolsao([100]);
      }

      if (notify >= 1 && bolsaoSize === 0) {
        setPerNotify([100]);
        setPerBolsao([0]);
      }

      if (notify >= 1 && bolsaoSize >= 1) {
        let presetNotify = Math.round((notify * 100) / bolsaoRT1.data.length);
        let presetBolsao = Math.round(
          ((bolsaoRT1.data.length - notify) * 100) / bolsaoRT1.data.length
        );
        setPerNotify([isFinite(presetNotify) ? presetNotify : 0]);
        setPerBolsao([isFinite(presetBolsao) ? presetBolsao : 0]);
      }
      setLoaded(true);
    }
    loadTables();
    setTimeout(() => {
      setExec(!exec);
    }, 15000);
  }, [exec]);

  return (
    <div className={dashStyles.dash}>
      <div className={`titlePage ${dashStyles.textCenter}`}>Dashboard</div>
      <div className={dashStyles.widgetMiddleBoxes}>
        <div className={dashStyles.rightMiddleBoxes}>
          <div className={dashStyles.underBoxes}>
            <div className={dashStyles.statusBox}>
              <div className={dashStyles.statusTitle}>
                <p className={dashStyles.titleUnderBoxes}>Clientes no Bolsão</p>
              </div>
              <div className={dashStyles.statusNumber}>
                {bolsaoSize ? bolsaoSize : <div>0</div>}
              </div>
            </div>
            <div className={dashStyles.statusBox}>
              <div className={dashStyles.statusTitle}>
                <p className={dashStyles.titleUnderBoxes}>Notificados</p>
              </div>
              <div className={dashStyles.statusNumber}>
                {notify ? notify : <div>0</div>}
              </div>
            </div>
          </div>
          <div className={dashStyles.leftMiddleBox}>
            {perNotify.length > 0 ? (
              <div className={dashStyles.graphicTitle}>Status do Bolsão</div>
            ) : null}
            <div className={dashStyles.graphAlign}>
              <div className={dashStyles.graph}>
                {perNotify.length > 0 ? (
                  <span className={dashStyles.miniTitle}>
                    {" "}
                    Não Notificados{" "}
                  </span>
                ) : null}
                <RadialChart
                  data={perBolsao ? perBolsao : 0}
                  series="test"
                  color="#007bff"
                  qtd={notify}
                />
              </div>
              <div className={dashStyles.graph}>
                {perNotify.length > 0 ? (
                  <span className={dashStyles.miniTitle}> Notificados </span>
                ) : null}
                <RadialChart
                  data={perNotify ? perNotify : 0}
                  series="test"
                  color="#007bff"
                  qtd={notify}
                />
              </div>
            </div>
            <div className={dashStyles.footer}></div>
          </div>
        </div>
        <div className={dashStyles.midTermBox}>
          <div className={dashStyles.longBox}>
            <StyledEngineProvider>
              <Table tableInfo={apiRTL1} loaded={loaded} />
            </StyledEngineProvider>
          </div>
          <div className={dashStyles.longBox}>
            <StyledEngineProvider>
              <Table2 tableInfo={apiRTL2} loaded={loaded} />
            </StyledEngineProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
