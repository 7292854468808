import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
// import search from "../../images/search.svg";
import HibernationStatusTable from "../../components/tables/hibernationNumbers";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function ReportHibernation() {
  const [table, setTable] = useState([]);
  const [exec, setExec] = useState([]);

  useEffect(() => {
    loadTablesAPI();
  }, [exec]);

  async function loadTablesAPI() {
    await api
      .get("api/espresso/hibernation/reportAll", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setTable(res.data);
        setTimeout(() => {
          setExec(!exec);
        }, 12000);
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
        setTimeout(() => {
          setExec(!exec);
        }, 12000);
      });
  }

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex">
        <div className="titlePage">Relatório Hibernação</div>
        <div className="searchFunction">
          {/* <strong className="sendButton">
            <img src={search} alt="search"/> RECARREGAR
          </strong> */}
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          <HibernationStatusTable hibernationStatus={table} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
