import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import uploadStyles from "./upload.module.css";
import messageStyles from "../pages/messages/messages.module.css";

const UploadList = ({ config }) => {
  return (
    <div className="previewList">
      {config.upfiles.map((upfile, index) => {
        const PreviewCSS = {
          backgroundImage: `url(${upfile.URL})`,
        };
        return (
          <div className={uploadStyles.containerUpload}>
            <div className="midiaImage">
              {upfile.type.indexOf("image") > -1 && (
                <div style={{ color: "red" }} title={upfile.name}>
                  <InsertPhotoIcon style={{ fontSize: "40px" }} />
                </div>
              )}
              {upfile.type.indexOf("audio") > -1 && (
                <div>
                  <AudioFileIcon style={{ fontSize: "40px" }} />
                </div>
              )}
              {upfile.type.indexOf("video") > -1 && (
                <div>
                  <VideoFileIcon style={{ fontSize: "40px" }} />
                </div>
              )}
              {upfile.type.indexOf("pdf") > -1 && (
                <div>
                  <PictureAsPdfIcon style={{ fontSize: "40px" }} />
                </div>
              )}
            </div>
            <div className={uploadStyles.midiaName}>
              <p
                className={`${messageStyles.midia__upload__name} ps-2`}
                title={upfile.name}
                style={{ color: "black" }}
              >
                <span>{upfile.name.substr(0, 10) + " ..."}</span>
              </p>
              <p className={messageStyles.midia__upload__size}>
                {upfile.readableSize}
              </p>
            </div>
            <div className={uploadStyles.iconFeedbacks}>
              {upfile.error && config.loading && (
                <ErrorIcon style={{ fontSize: "40px", color: "#FF0000" }} />
              )}
              {!upfile.error && !config.loading && (
                <CheckCircleIcon
                  style={{ fontSize: "40px", color: "#00FF00" }}
                />
              )}
              {/* {!upfile.error && config.loading && !config.sended && (
                  <ClipLoader/>
                )} */}
              {!upfile.error && config.loading && config.sended && (
                <CheckCircleIcon
                  style={{ fontSize: "40px", color: "#2ecc71" }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UploadList;
