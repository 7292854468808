import React from "react";
import Chart from "react-apexcharts";

export default function PieComeback(props) {
  const config = {
    options: {
      colors: ["#2ecc71", "#e67e22", "#f1c40f"],
      labels: ["Retornos", "Time-Out", "Continua Hibernado"],
      dataLabels: { enabled: false },

      responsive: [
        {
          breakpoint: 1401,
          options: {
            chart: { width: "200%" },
            legend: { show: false },
          },
        },
      ],

      legend: {
        show: false
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
  };

  return props.data.length > 0 ? (
    <div className="donut">
      <Chart
        options={config.options}
        series={props.data}
        type="pie"
        width={"780%" + Math.floor(Math.random() * 2) + 1}
      />
    </div>
  ) : (
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
