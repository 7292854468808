import React, { useState } from "react";
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import "../../genesys.css";

export default function EmojiSelector() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
        //sendMSG
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="string"
        onClick={handleClick}
        sx={{
          bgcolor: "#2980B9",
          color: "white",
          fontSize: "calc(6px + 0.7vw)",
          borderRadius: 2,
          minWidth:"calc(6px + 1vw)",
          borderSize: 0,
          ":hover": { bgcolor: "#60bfff",  },
        }}
      >
        {open ? <span> 😉 </span> : <span> 🙂 </span>}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
          <Picker
            pickerStyle={{ margin: 0 }}
            onEmojiClick={onEmojiClick}
            skinTone={SKIN_TONE_MEDIUM_DARK}
          />
      </Popover>
    </div>
  );
}
