import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "DataHoraEnvio",
    headerName: "Data e Hora",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-truncate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Telefone",
    headerName: "Telefone",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "NomeArquivo",
    headerName: "Nome do arquivo",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "NomeTemplate",
    headerName: "Nome do Template",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "StatusEnvio",
    headerName: "Status",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },

  // {
  //   field: "ConversationID",
  //   headerName: "Data e Hora",
  //   flex: 1,
  // },
  // { field: "Telefone", headerName: "Cliente", flex: 1 },
  // { field: "Origen", headerName: "Telefone", flex: 1 },
  // {
  //   field: "Fila",
  //   headerName: "Mensagem Enviada",
  //   flex: 1,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "Inicio",
  //   headerName: "Mensagem Lida",
  //   type: "dateTime",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   renderCell: (params) => (
  //     <div>
  //       {params.value === null ? (
  //         "-"
  //       ) : (
  //         <div>
  //           <Tooltip title={new Date(params.value).toLocaleString()}>
  //             <span className="table-cell-trucate">
  //               {new Date(params.value).toLocaleString()}
  //             </span>
  //           </Tooltip>
  //         </div>
  //       )}
  //     </div>
  //   ),
  // },
  // {
  //   field: "Fim",
  //   headerName: "Mensagem Respondida",
  //   type: "dateTime",
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   renderCell: (params) => (
  //     <div>
  //       {params.value === null ? (
  //         "-"
  //       ) : (
  //         <div>
  //           <Tooltip title={new Date(params.value).toLocaleString()}>
  //             <span className="table-cell-trucate">
  //               {new Date(params.value).toLocaleString()}
  //             </span>
  //           </Tooltip>
  //         </div>
  //       )}
  //     </div>
  //   ),
  // },
  // {
  //   field: "Usuario",
  //   headerName: "Data e Hora (Resposta)",
  //   flex: 1,
  //   align: "center",
  //   headerAlign: "center",
  // },
  // {
  //   field: "Status",
  //   headerName: "Resultado",
  //   flex: 1,
  // },
];

function generateDynamicId(length = 15) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    id += characters.charAt(randomIndex);
  }

  return id;
}

function addIdsToObjects(array) {
  for (let i = 0; i < array.length; i++) {
    array[i].id = generateDynamicId();
  }

  return array;
}

export default function ServiceFlow(data) {
  const items = addIdsToObjects(data.data);
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={items}
          getRowId={(row) => {
            return row?.id;
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
