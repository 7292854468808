import LineChartHibernation from "../../../components/charts/dashboardHibernation/lineChartHibernation";
import PieChartHibernation from "../../../components/charts/dashboardHibernation/pieChartHibernation";
import CircularLoading from "../../../components/loadModules/circularLoading";
import dashStyles from "../dashboardHibernation.module.css";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";

const weekDays = {
  1: "Domingo",
  2: "Segunda-feira",
  3: "Terça-feira",
  4: "Quarta-feira",
  5: "Quinta-feira",
  6: "Sexta-feira",
  7: "Sabado",
};

export default function DashboardHibernationStatus() {
  const [loading, setLoading] = useState(false);
  const [dataPieFlag, setDataPieFlag] = useState([]);
  const [dataPieStatus, setDataPieStatus] = useState([]);
  const [dataLineChart, setDataLineChart] = useState([]);
  const [params, setParams] = useState([]);

  function formatLineChart(data) {
    data.sort((a, b) => a.DiaSemana - b.DiaSemana);
    data.forEach((item) => {
      item.day = weekDays[item.DiaSemana];
      delete item.DiaSemana;
    });
    return data;
  }

  function handleParams(value) {
    let _params = value.split("-");
    setParams(_params);
    if (_params[0] < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    }
  }

  function formatData(data, status) {
    let _data = [];
    if (!status) {
      for (let i in data) {
        let _prov = { name: "", data: "" };
        _prov.name = data[i].Origem;
        _prov.data = data[i].Hibernacoes;
        _data.push(_prov);
      }
    } else {
      for (let i in data) {
        let _prov = { name: "", data: "" };
        _prov.name = translateData(data[i].Status);
        _prov.data = data[i].Hibernacoes;
        _data.push(_prov);
      }
    }

    return _data;
  }

  function translateData(_data) {
    switch (_data) {
      case "DISCONNECTED":
        return "Desconectado";
      case "RETURNED_AGENT":
        return "Retorno para agente";
      case "RETURNED_QUEUE":
        return "Retorno para fila";
      default:
        return "";
    }
  }
  useEffect(() => {
    if (params.length) {
      fetchData();
    }
  }, [params]);
  async function fetchData() {
    setLoading(true);
    try {
      const [pieFlagRes, pieStatusRes, lineChartRes] = await Promise.all([
        api.get(
          `/api/database/report/dashOrigin?year=${params[0]}&month=${params[1]}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ),
        api.get(
          `/api/database/report/dashStatus?year=${params[0]}&month=${params[1]}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ),
        api.get(
          `/api/database/report/dashWeekday?year=${params[0]}&month=${params[1]}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ),
      ]);

      if (pieFlagRes.data.length) {
        const formattedPieFlagData = formatData(pieFlagRes.data);
        setDataPieFlag(formattedPieFlagData);
      } else {
        setDataPieFlag([]);
      }

      if (pieStatusRes.data?.length) {
        const formattedPieStatusData = formatData(pieStatusRes.data, true);
        setDataPieStatus(formattedPieStatusData);
      } else {
        setDataPieStatus([]);
      }

      if (lineChartRes.data.length) {
        const formattedLine = formatLineChart(lineChartRes.data);
        setDataLineChart(formattedLine);
      } else {
        setDataLineChart([]);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Erro ao buscar dados da API");
      console.error(error);
      setLoading(false);
    }
  }
  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center pe-5 me-3">
        <div className="titlePage">Hibernação - Status</div>
        <div className={dashStyles.containerMonth}>
          <input
            onChange={(e) => handleParams(e.target.value)}
            className="input-bootstrap"
            type="month"
          />
        </div>
      </div>
      {loading ? (
        <div style={{ height: "88vh" }}>
          <CircularLoading />
        </div>
      ) : (
        <>
          {dataLineChart.length ||
          dataPieFlag.length ||
          dataPieStatus.length ? (
            <div>
              <div className=" d-flex justify-content-between my-4">
                <div className={dashStyles.pieContainer}>
                  <p className="text-center">Casos de hibernação - Bandeira</p>
                  {dataPieFlag.length ? (
                    <PieChartHibernation data={dataPieFlag} status={false} />
                  ) : (
                    <>
                      <p className="text-center mt-5">
                        Não há dados para esse gráfico :(
                      </p>
                    </>
                  )}
                </div>
                <div className={`me-5 ${dashStyles.pieContainer}`}>
                  <p className="text-center">Status - Definição por tipo</p>
                  {dataPieStatus.length ? (
                    <PieChartHibernation
                      id="pieStatus"
                      data={dataPieStatus}
                      status={false}
                    />
                  ) : (
                    <>
                      <p className="text-center mt-5">
                        Não há dados para esse gráfico :(
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className={`pe-4 ${dashStyles.lineContainer}`}>
                <p className="text-center fw-bold">Status - Dia da semana</p>
                {dataLineChart.length ? (
                  <LineChartHibernation data={dataLineChart} />
                ) : (
                  <>
                    <p className="text-center mt-5">
                      Não há dados para esse gráfico :(
                    </p>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-5">
              {!params.length ? (
                <em>Preencha os dados para a pesquisa</em>
              ) : (
                <em>Não há dados para os gráficos</em>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
