import milestoneStyle from "./Milestone.module.css";
import React, { useRef, useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import { config } from "../../services/utils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

function processaNode(node, x, y, total) {
  const t = `translate(${x},${y})`;
  const c = [];
  let maxOffset = 0;
  if (node.childs)
    for (const n in node.childs) {
      const { offset, nodes } = processaNode(
        node.childs[n],
        150,
        maxOffset,
        node.qtd
      );
      c.push(
        <g key={n}>
          {nodes}
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "0.5px",
            }}
            d={`M 120,5 C 135,5 135,${maxOffset + 5} 150,${maxOffset + 5}`}
            id="path4555"
          />
        </g>
      );
      maxOffset += offset;
    }
  if (maxOffset < 30) maxOffset = 30;
  const w = 119.2 * (1 - node.qtd / total);
  return {
    offset: maxOffset,
    nodes: (
      <g id="node" transform={t} key={crypto.randomUUID()}>
        <rect
          style={{
            fill: "#0d2f5a",
          }}
          id="fundo"
          width="120"
          height="10"
          x="0"
          y="0"
        />
        <rect
          y="0.4"
          x="0.4"
          height="9.2"
          width="119.2"
          id="base"
          style={{
            fill: "#0083ae",
          }}
        />
        <text
          id="info"
          y="18"
          x="2"
          style={{
            fontSize: "10px",
            fontFamily: "roboto",
            fill: "#0d2f5a",
          }}
        >
          {node.title} : {node.qtd}
        </text>
        <rect
          y="0.4"
          x={119.6 - w} //"80"
          height="9.2"
          width={w} //"39.6"
          id="restante"
          style={{
            fill: "#65bbe8",
          }}
        />
        {c}
      </g>
    ),
  };
}

export default function Milestone() {
  // States
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [milestone, setMilestone] = useState([]);
  const [origin, setOrigin] = useState("");
  const [reload, setReload] = useState(false);

  // End State

  //OnPage Load
  useEffect(() => {
    api
      .get("api/database/report/milestone", {
        params: {
          origin: origin,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        setMilestone(res.data.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  }, [origin]);

  useEffect(() => {
    setHeight(ref.current.parentElement.offsetHeight);
    setWidth(ref.current.parentElement.offsetWidth - 200);

    const updt = setInterval(() => setReload((r) => !r), 10000);
    return () => clearInterval(updt);
  }, [reload]);

  useEffect(() => {
    var svg = document.getElementById("ura");
    var bbox = svg.getBBox();
    svg.setAttribute("width", bbox.x + bbox.width + bbox.x + 30);
    svg.setAttribute("height", bbox.y + bbox.height + bbox.y + 30);
  }, [milestone]);

  // End Page Load//

  const node = [];
  let maxOffset = 0;
  for (const mil of milestone) {
    const { offset, nodes } = processaNode(mil, 0, maxOffset, mil.qtd);
    node.push(nodes);
    maxOffset += offset;
  }
  return (
    <div className="container pt-4">
      <div className="d-flex justify-content-between align-items-center">
        <p className="titlePage">Fluxo do Bot</p>
        <div className=" d-flex align-items-center pe-4">
          <label className="defaultLabel pe-3">Origem:</label>
          <select
            className="select-bootstrap"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option value=""> Selecione a Origem </option>
            {config.summarize.map((value) => (
              <option value={value.origin}>{value.visualName}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={milestoneStyle.workFlowTitle}>
        <AccountTreeIcon className="me-2" style={{ color: "white" }} />
        <span>Fluxo Completo do Bot </span>
      </div>
      <div className={milestoneStyle.blackBoard}>
        <div className={milestoneStyle.workFlowBoard}>
          <div className={milestoneStyle.workFlow} ref={ref}>
            <StyledEngineProvider injectFirst>
              <svg width={width} height={height} id="ura">
                <g if="zoom" transform="matrix(2,0,0,2,0,0)">
                  {node}
                  {/* <g id="node">
                <rect
                  style={{
                    fill: '#0d2f5a',
                  }}
                  id="fundo"
                  width="120"
                  height="10"
                  x="0"
                  y="0"
                />
                <rect
                  y="0.4"
                  x="0.4"
                  height="9.2"
                  width="119.2"
                  id="base"
                  style={{
                    fill: '#0083ae',
                  }}
                />
                <text
                  id="info"
                  y="18"
                  x="2"
                  style={{
                    fontSize: '10px',
                    fontFamily: 'roboto',
                    fill: '#0d2f5a',
                  }}
                >
                  0
                </text>
                <rect
                  y="0.4"
                  x="80"
                  height="9.2"
                  width="39.6"
                  id="restante"
                  style={{
                    fill: '#65bbe8',
                  }}
                />
              </g>
              <path
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: '0.5px',
                }}
                d="M 0,300 C 25,300 0,275 25,275"
                id="path4555"
              /> */}
                </g>
              </svg>
            </StyledEngineProvider>
          </div>
        </div>
      </div>
      <div className={milestoneStyle.workFlowFooter} />
    </div>
  );
}
