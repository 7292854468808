import React from "react";
import NewSidebar from "../pages/newSideBar/newSidebar";

export default function defaultTemplate({ children }) {
  return (
    <div>
      <NewSidebar />
      {children}
    </div>
  );
}
