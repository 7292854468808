import { UserManager } from 'oidc-client-ts';
import settings from '../config/settings';

const userManager = new UserManager(settings);

let oldCallback = null;
export function onTokenLoaded(callback) {
  if (oldCallback) {
    userManager.events.removeUserLoaded(oldCallback);
  }
  oldCallback = callback;
  if (typeof callback == 'function') userManager.events.addUserLoaded(callback);
}

class _AuthService {
  constructor() {
    this.userManager = userManager;
  }

  async getUser() {
    let data = await this.userManager.getUser();
    if (data?.expired) {
      try {
        data = await this.userManager.signinSilent();
      } catch {
        data = null;
      }
    }
    return data;
  }

  async login() {
    console.log('AuthServce Login');
    return await this.userManager.signinRedirect();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  async getAccessToken() {
    let data = await this.getUser();
    return data?.access_token;
  }
}

export const AuthService = new _AuthService();
