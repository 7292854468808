import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, onOpen, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[1000],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: "35vh",
    minWidth: "30vw",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    color: "black",
  },
  "& .MuiPaper-elevation": {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "37vh",
    minWidth: "32vw",
    overflow: "hidden",
  },
}));

export default function SmallModal({
  children,
  buttonString,
  boxName,
  icon,
  styleButton,
  className,
  modalSize,
  onClose,
  iconButton,
  onOpen
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if(onOpen)
      onOpen();
    setOpen(true);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setOpen(false);
  };

  return (
    <div>
      {iconButton ? (
        <IconButton sx={styleButton} onClick={handleClickOpen}>{buttonString}</IconButton>
      ) : (
        <Button variant="string" onClick={handleClickOpen} sx={styleButton}>
          {icon ? <img className={className} src={icon} alt="" /> : null}
          {buttonString}
        </Button>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {boxName ? (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {boxName}
          </BootstrapDialogTitle>
        ) : null}

        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
