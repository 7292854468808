import React, { useState, useEffect } from "react";
import rdStationStyle from "./rdStationConfig.module.css";
import RdStationTable from "../../components/tables/rdStationTable";
import { StyledEngineProvider } from "@mui/material";
import ModalCloseOpen from "../../components/UI/Modals/modalCloseOpen";
import RdConfigRegister from "./rdConfigRegister/rdConfigRegister";
import addCircle from "../../images/addCircle.svg";
import api from "../../services/api";
import { toast } from "react-toastify";
import CircularLoading from "../../components/loadModules/circularLoading.js";

export default function RdStationConfig() {
  const [configList, setConfigList] = useState([]);
  const [item, setItem] = useState({});
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getConfigurationList();
  }, []);

  function handleModal() {
    if (isUpdate) {
      setIsUpdate(false);
      setItem({});
    }
    setOpen(!open);
  }

  function handleSubmit() {
    handleModal();
    getConfigurationList();
  }
  async function getConfigurationList() {
    setLoading(true);
    await api
      .get("/api/database/configuration", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res?.data?.length) {
          setConfigList(res.data);
        } else {
          toast.warning("Não há configurações cadastradas!");
        }
      })
      .catch((e) => {
        toast.error("Houve um erro ao realizar a operação!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function editCell(row) {
    await setItem(row);
    setIsUpdate(true);
    handleModal();
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between w-100 pt-2">
        <div className="titlePage">RD Station Configuração</div>
        <div className="pe-4">
          <ModalCloseOpen
            buttonString="Adicionar"
            boxName="Criar Configuração"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
            open={open}
            onClose={handleModal}
            onOpen={handleModal}
          >
            <RdConfigRegister
              isUpdate={isUpdate}
              onSubmit={handleSubmit}
              data={item}
              configList={configList}
            />
          </ModalCloseOpen>
        </div>
      </div>
      <div className={rdStationStyle.tableHeight}>
        {!loading ? (
          <StyledEngineProvider injectFirst>
            <RdStationTable data={configList} onEditClick={editCell} />
          </StyledEngineProvider>
        ) : (
          <CircularLoading />
        )}
      </div>
    </div>
  );
}
