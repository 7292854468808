import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  icon: {
    color: " rgb(238, 0, 0)",
    fontSize: "72px",
  },
  text: {
    fontSize: "17px",
    textAlign: "center",
    fontFamily: "Open sans",
  },
}));

const SimpleModal = ({ open, handleClose, handleConfirm, message }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <WarningIcon className={classes.icon} />
          <p className={`my-4 ${classes.text}`}>
            Esta ação é irreversível.
            <br /> Você tem certeza de que deseja{" "}
            {message && message.length ? message : "continuar"}?
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center ">
          <button
            onClick={() => handleClose()}
            className="btn btn-danger px-5 me-4"
          >
            Cancelar
          </button>
          <button
            onClick={() => handleConfirm()}
            className="btn btn-primary px-5 me-4"
          >
            Confirmar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SimpleModal;
