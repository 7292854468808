import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "ConversationID",
    headerName: "Id",
    flex: 1 / 2,
  },
  { field: "Telefone", headerName: "Telefone", flex: 1 },
  { field: "Origem", headerName: "Origem", flex: 1 },
  {
    field: "Fila",
    headerName: "Nome Da Fila",
    renderCell: (params) => {
      if (params.value === null) {
        return "System.Empty";
      }
      return params.value;
    },
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Inicio",
    headerName: "Inicio da Hibernação",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Fim",
    headerName: "Fim da Hibernação",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Usuario",
    headerName: "Agente",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value ? (
          <div>{humanizeStatus(params.value)}</div>
        ) : (
          <div>-</div>
        )}
      </div>
    ),
  },

  {
    field: "Tentativas",
    HeaderName: "Tentativas",
    flex: 1 / 2,
    headerAlign: "centar",
    align: "center",
    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
  {
    field: "Tempo",
    headerName: "Tempo",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        {params.value ? (
          <div>{formatSecondsToHMS(params.value)}</div>
        ) : (
          <div>-</div>
        )}
      </div>
    ),
  },
];
function transformObjectToArrayWithId(obj) {
  if (!obj || !obj.data || !Array.isArray(obj.data)) {
    throw new Error('O objeto deve conter um campo "data" que seja um array.');
  }

  const dataArrayWithId = obj.data.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  return dataArrayWithId;
}

function formatSecondsToHMS(seconds) {
  if (typeof seconds !== "number" || isNaN(seconds)) {
    throw new Error("O valor fornecido não é um número válido de segundos.");
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function humanizeStatus(status) {
  const dic = {
    returned_agent: "Retorno Agente",
    disconnected: "Desconectado",
    hibernate: "Hibernado",
    returned_queue: "Retorno Fila",
  };
  const st = status.toLowerCase();

  return dic[st];
}
export default function HibernationNumbers2(data) {
  const rows = transformObjectToArrayWithId(data);
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          getRowId={(row) => {
            return row?.id;
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
