import MessagesListTable from "./blacklistTable.jsx";
import RegisterMessageHSM from "./registerNumber";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

import addCircle from "../../images/addCircle.svg";
import SmallModal from "../../components/UI/Modals/Small.jsx";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function Blacklist() {
  const [blacklistAPI, setBlacklistAPI] = useState({});

  // OnPage Load //
  useEffect(() => {
    async function load() {
      await api
        .get("api/espresso/blacklist/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.warn("Erro #004: Não há resultado na busca");
          }
          setBlacklistAPI(res.data);
          return;
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }

    load();
  }, []);
  // End Page Load//

  return (
    <div className="container">
      <div className="d-flex w-100 align-items-center justify-content-between">
        <div className="titlePage">Blocklist:</div>
        <div className="me-4">
          <SmallModal
            buttonString="Adicionar"
            boxName="Registrar Número a Blocklist"
            icon={addCircle}
            styleButton={{
              bgcolor: "#0d6efd",
              color: "white",
              padding: 1.1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 3,
              boxShadow: 3,
              ":hover": { bgcolor: "#0347ad" },
            }}
          >
            <RegisterMessageHSM />
          </SmallModal>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <MessagesListTable blacklist={blacklistAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
