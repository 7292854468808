import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { config } from "../../../services/utils";
import rdStationConfigStyle from "../rdStationConfig.module.css";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

function getOrigins() {
  let origins = [];
  const brokers = Object.keys(config.fakeConfig.brokers);
  for (let i = 0; i < brokers.length; i++) {
    for (
      let j = 0;
      j < config.fakeConfig.brokers[brokers[i]].senders.length;
      j++
    ) {
      origins.push(config.fakeConfig.brokers[brokers[i]].senders[j].origin);
    }
  }
  return origins;
}

function verifyList(item, list) {
  if (list.find((c) => c.origin == item)) {
    return true;
  } else {
    return false;
  }
}

export default function RdConfigRegister({
  isUpdate,
  data,
  onSubmit,
  configList,
}) {
  const defaultPayload = {
    origin: "",
    workSchedule: null,
    holidays: null,
    integrations: {
      rdstationmkt: {
        publicToken: "",
        privateToken: "",
        HSMRules: [
          {
            template: "",
            rule: "",
            queue: "",
            skills: [],
          },
        ],
      },
    },
  };

  const defaultHSMRules = {
    template: "",
    rule: "",
    queue: "",
    skills: [],
  };

  const [messageList, setMessageList] = useState([]);
  const [payload, setPayload] = useState(defaultPayload);
  const [isDisabled, setIsDisabled] = useState(true);

  const originsOptions = getOrigins();

  async function getTemplateList() {
    await api
      .get("api/espresso/mensagens/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMessageList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addParams(data, index) {
    let fromTo = { ...data.fromTo };
    let _payload = { ...payload };

    fromTo = { ...fromTo, [""]: "" };

    _payload.integrations.rdstationmkt.HSMRules[index].fromTo = fromTo;
    setPayload(_payload);
  }

  function handleChangeFromTo(oldKey, key, value, index) {
    let _payload = { ...payload };

    if (oldKey != key) {
      delete _payload.integrations.rdstationmkt.HSMRules[index].fromTo[oldKey];
      _payload.integrations.rdstationmkt.HSMRules[index].fromTo[value] = "";
    } else {
      _payload.integrations.rdstationmkt.HSMRules[index].fromTo[key] = value;
    }
    setPayload(_payload);
    setIsDisabled(false);
  }
  function addHSMRules() {
    let _payload = { ...payload };
    _payload.integrations.rdstationmkt.HSMRules.push(defaultHSMRules);
    setPayload(_payload);
    setIsDisabled(false);
  }

  function excludeHSMRules(index) {
    let _payload = { ...payload };
    _payload.integrations.rdstationmkt.HSMRules.splice(index, 1);
    setPayload(_payload);
    setIsDisabled(false);
  }

  function handleEvent(value, field, index) {
    let _payload = { ...payload };
    if (field) {
      if (field == "privateToken" || field == "publicToken") {
        _payload.integrations.rdstationmkt[field] = value;
      }
      if (
        field == "skills" ||
        field == "template" ||
        field == "rule" ||
        field == "queue"
      ) {
        if (field != "skills") {
          _payload.integrations.rdstationmkt.HSMRules[index][field] = value;
        } else {
          _payload.integrations.rdstationmkt.HSMRules[index][field] =
            transformToArray(value);
        }
      }
      if (field == "origin") {
        _payload.origin = value;
      }
      setPayload(_payload);
      if (isDisabled) {
        setIsDisabled(false);
      }
    }
  }

  function transformToString(value) {
    if (value) {
      const newV = value.join(", ");
      return newV;
    } else {
      return "";
    }
  }

  function transformToArray(value) {
    return value.split(", ");
  }

  function removeFromTo(key, index) {
    let _payload = { ...payload };
    delete _payload.integrations.rdstationmkt.HSMRules[index].fromTo[key];
    setPayload(_payload);
    setIsDisabled(false);
  }

  async function handleSubmit() {
    await api
      .post("/api/database/configuration", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Dados salvos com sucesso!");
          onSubmit();
        } else {
          toast.error("Houve um erro ao salvar.");
        }
      })
      .catch((e) => {
        toast.error("Houve um erro ao salvar.");
      });
  }

  useEffect(() => {
    getTemplateList();
    if (Object.keys(data).length > 0) {
      setPayload(data);
    }
  }, []);
  return (
    <>
      <div className={rdStationConfigStyle.modalContainer}>
        <div className="mb-3">
          <label htmlFor="origin" className="defaultLabel">
            Origin:
          </label>
          <select
            disabled={isUpdate}
            required
            value={payload?.origin}
            onChange={(e) => handleEvent(e.target.value, "origin")}
            id="origin"
            className="select-bootstrap mt-1"
          >
            <option value={""}>Selecione...</option>
            {originsOptions.map((c) => (
              <option disabled={verifyList(c, configList)} value={c} key={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
        <div className="pe-3 mb-3">
          <label htmlFor="publicKey" className="defaultLabel">
            Chave pública:
          </label>
          <input
            value={payload?.integrations?.rdstationmkt?.publicToken}
            onChange={(e) => handleEvent(e.target.value, "publicToken")}
            className="input-bootstrap mt-1"
            name="publicKey"
            id="publicKey"
            placeholder="5a4a6b72-1c27-4c0d-a5f3-86b9c4f72419"
          />
        </div>
        <div className="pe-3 mb-3">
          <label htmlFor="privateKey" className="defaultLabel">
            Chave privada:
          </label>
          <input
            value={payload?.integrations?.rdstationmkt?.privateToken}
            onChange={(e) => handleEvent(e.target.value, "privateToken")}
            className="input-bootstrap mt-1"
            name="privateKey"
            id="privateKey"
            placeholder="92d8a46e-984d-4820-aa24-2d5f3c1c0c77"
          />
        </div>

        {payload.integrations.rdstationmkt.HSMRules.map((c, index) => {
          return (
            <div key={index}>
              <div className="d-flex justify-content-end me-2">
                <button
                  onClick={() => excludeHSMRules(index)}
                  className={rdStationConfigStyle.deleteButtonHSM}
                >
                  <DeleteIcon style={{ color: "red", fontSize: "16px" }} />
                </button>
              </div>
              <div className={`${rdStationConfigStyle.HSMRules} mb-4`}>
                <div className="mb-3">
                  <label htmlFor="template" className="defaultLabel">
                    Template:{" "}
                  </label>
                  <select
                    onChange={(e) =>
                      handleEvent(e.target.value, "template", index)
                    }
                    value={c.template}
                    id="template"
                    className="select-bootstrap mt-1"
                    required
                  >
                    <option value={""}>Selecione...</option>
                    {messageList.map((c) => (
                      <option value={c.codemessage} key={c.codemessage}>
                        {c.codemessage}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="pe-3 mb-3">
                  <label htmlFor="queue" className="defaultLabel">
                    Fila:
                  </label>
                  <input
                    value={c.queue}
                    onChange={(e) =>
                      handleEvent(e.target.value, "queue", index)
                    }
                    placeholder="Atendimento"
                    className="input-bootstrap mt-1"
                    name="queue"
                    id="queue"
                  />
                </div>
                <div className="pe-3 mb-3">
                  <label htmlFor="skill" className="defaultLabel">
                    Habilidades:
                  </label>
                  <input
                    value={transformToString(c.skills)}
                    onChange={(e) =>
                      handleEvent(e.target.value, "skills", index)
                    }
                    placeholder="Português, inglês"
                    className="input-bootstrap mt-1"
                    name="skill"
                    id="skill"
                  />
                </div>
                <div className="pe-3 mb-3">
                  <label htmlFor="rule" className="defaultLabel">
                    Regra:
                  </label>
                  <input
                    value={c.rule}
                    placeholder="user.city == 'São Paulo'"
                    className="input-bootstrap mt-1"
                    name="rule"
                    id="rule"
                    onChange={(e) => handleEvent(e.target.value, "rule", index)}
                    required
                  />
                </div>
                <div className="mb-4 pe-3">
                  {c.fromTo &&
                    Object.entries(c.fromTo).map(([key, value], indexxxx) => {
                      return (
                        <div key={indexxxx}>
                          <div
                            className={`d-flex justify-content-end mt-4 ${rdStationConfigStyle.mbN5}`}
                          >
                            <button
                              onClick={() => removeFromTo(key, index)}
                              className={rdStationConfigStyle.deleteButton}
                            >
                              <DeleteIcon
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </button>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="me-4">
                              <label htmlFor="nome" className="defaultLabel">
                                De
                              </label>
                              <input
                                className="input-bootstrap mt-1"
                                name="nome"
                                id={key}
                                required
                                onChange={(e) =>
                                  handleChangeFromTo(
                                    key,
                                    e.target.value,
                                    e.target.value,
                                    index
                                  )
                                }
                                value={key}
                              />
                            </div>
                            <div>
                              <label className="defaultLabel" htmlFor="fluxo">
                                Para
                              </label>
                              <input
                                value={value}
                                className="input-bootstrap mt-1"
                                id={value}
                                name="fluxo"
                                onChange={(e) =>
                                  handleChangeFromTo(
                                    key,
                                    key,
                                    e.target.value,
                                    index
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="d-flex flex-column align-items-center mt-4">
                    <label className="defaultLabel">
                      Adicionar Parâmetros?
                    </label>
                    <button
                      onClick={() => addParams(c, index)}
                      className={rdStationConfigStyle.addIconButton}
                    >
                      <AddIcon style={{ color: "#fff" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="d-flex flex-column align-items-center my-4">
          <label className="defaultLabel">Adicionar nova regra?</label>
          <button
            onClick={addHSMRules}
            className={rdStationConfigStyle.addIconButton}
          >
            <AddIcon style={{ color: "#fff" }} />
          </button>
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button
            onClick={handleSubmit}
            disabled={
              isDisabled ||
              !(
                payload.integrations.rdstationmkt.HSMRules[0].template
                  ?.length &&
                payload.integrations.rdstationmkt.HSMRules[0].rule.length &&
                payload.origin.length
              )
            }
            className="btn btn-primary px-5"
          >
            Enviar
          </button>
        </div>
      </div>
    </>
  );
}
