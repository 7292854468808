import addCircle from "../../../images/addCircle.svg";
import { StyledEngineProvider } from "@mui/material";
import RegisterPageModal from "../others/modal";
import HolidayTable from "../others/table";
import { useState } from "react";
import HolidayRegister from "../others/modalRegister";
import { useEffect } from "react";
import api from "../../../services/api";

export default function YearAdapter() {
  useEffect(() => {
    async function loadScreen() {
      await api
        .get("api/espresso/holidays", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setApiData(res.data);
        });
    }
    loadScreen();
  }, []);

  const [apiData, setApiData] = useState({});

  return (
    <div className="container">
      <div className="d-flex justify-content-between w-100">
        <div className="titlePage">Feriados</div>
        <div className="pe-4">
          <RegisterPageModal
            buttonString="Adicionar"
            boxName="Horario Funcionamento"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1,
              fontSize: 14,
              fontFamily: "Open Sans",
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
          >
            <HolidayRegister />
          </RegisterPageModal>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <HolidayTable apiData={apiData} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
