import React, { useState } from "react";
import unblockStyle from "./unblock.module.css";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { toast } from "react-toastify";
import { config } from "../../../services/utils";
import api from "../../../services/api";

export default function Unblock() {
  const [number, setNumber] = useState("");
  const [origin, setOrigin] = useState("");

  async function unblockPhone(event) {
    event.preventDefault();
    event.persist();
    let body = {
      origin: origin,
      phone: `${number}`,
    };
    await api
      .post("api/espresso/reset", body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((res) => {
        if (res.status === 200)
          toast.success(`Número ${number} desbloqueado com sucesso`);
      })
      .catch((err) => {
        if (err.response.data === "Not Found")
          toast.warn(`Número ${number} não encontrado`);
        else toast.error(`Erro Interno do Servidor`);
      });
  }

  return (
    <div className={unblockStyle.unblockContainer}>
      <form
        className={unblockStyle.unblockForm}
        onSubmit={(e) => {
          unblockPhone(e);
        }}
      >
        <div className={unblockStyle.titleBlockBox}>
          <PhoneDisabledIcon style={{ color: "white" }} />
          Desbloqueador
        </div>
        <div className={unblockStyle.blockBox}>
          <div className={unblockStyle.divLabelTools}>
            <label>Número: </label>
          </div>
          <input
            value={number}
            required
            maxLength="14"
            placeholder="exemplo: +5511912345678"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <div className={unblockStyle.divLabelTools}>
            <label>Origem: </label>
          </div>
          <select
          className={unblockStyle.selectBlock}
            required
            value={origin}
            onChange={(e) => {
              setOrigin(e.target.value);
            }}
          >
            <option value=""> Selecione... </option>
            {config.summarize.map((value, idx) => (
              <option key={idx} value={value.origin}>
                {value.visualName}
              </option>
            ))}
          </select>

         <div className="containerBtn-4 mb-4">
         <button className="btn btn-primary btn-size" type="submit">
            {" "}
            Desbloquear{" "}
          </button>
         </div>
        </div>
      </form>
    </div>
  );
}
