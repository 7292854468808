import cdrStyles from "./cdrPage.module.css";
import { Button } from "@mui/material";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function DownloadByTime() {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [test, setTest] = useState();

  function finding() {
    let timeStamp = new Date(from).getTime();
    let toStamp = new Date(to).getTime();
    let maxing = timeStamp + 10 * 24 * 60 * 60 * 1000;

    if (toStamp <= maxing) {
      const req = {
        from_dateTime: from,
        to_dateTime: to,
        download: true,
      };
      setLoading(true);
      generate(req);
    } else {
      toast.warn("Data maior que 10 dias");
    }
  }

  const dateConversion = (dateToConvert) => {
    return new Date(
      new Date(dateToConvert).getTime() - 3 * 60 * 60 * 1000
    ).toISOString();
  };

  async function generate(req) {
    await api
      .get(
        `api/database/report/cdr_log_iteracoes?from_dateTime=${dateConversion(
          req.from_dateTime
        )}&to_dateTime=${dateConversion(req.to_dateTime)}&download=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setTest(res.data);
        return;
      })
      .catch(() => {
        toast.error("Erro #003: Falha na pesquisa");
      });

    setTimeout(() => {
      setLoading(false);
    }, 3000);
    setFlag(true);
  }

  function clearDownload() {
    setTest();
    setFlag(false);
    setLoading(false);
    setFrom("");
    setTo("");
  }

  return (
    <div className={cdrStyles.containerModal}>
      <div className="d-flex flex-column">
        <label className="defaultLabel">Data Inicial</label>
        <input
          className="input-bootstrap mt-1 mb-4"
          type="dateTime-Local"
          value={from}
          onChange={(e) => {
            setFrom(e.target.value);
          }}
        />
      </div>

      <div className="d-flex flex-column">
        <label className="defaultLabel">Data Final</label>
        <input
          className="input-bootstrap mt-1 mb-4"
          type="dateTime-Local"
          value={to}
          onChange={(e) => {
            setTo(e.target.value);
          }}
          id="maxDate"
        />
      </div>

      <div className="d-flex justify-content-center">
        {loading ? (
          <CircularProgress />
        ) : flag && test ? (
          <a href={test} className="linking">
            <Button
              variant="contained"
              sx={{ bgcolor: "#007bff;", ":hover": { bgcolor: "#005bff;" } }}
              disabled={from && to ? false : true}
              onClick={() => clearDownload()}
            >
              Download
            </Button>
          </a>
        ) : (
          <Button
            variant="contained"
            sx={{ bgcolor: "#007bff;", ":hover": { bgcolor: "#005bff;" } }}
            disabled={from && to ? false : true}
            onClick={() => {
              finding();
            }}
          >
            Pesquisar
          </Button>
        )}
      </div>
    </div>
  );
}
