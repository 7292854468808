import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import tagsEdtiStyle from './TagsEdit.module.css';
import api from '../../services/api';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const { v4: uuidv4 } = require('uuid');


export default function TagsEdit(props) {
  // ----------------- States -------------------------- //
  // const [selected, setSelected] = useState({});
  const [key, setKey] = useState('');
  const [word, setWord] = useState('');
  const [sentimento, setSentimento] = useState(0);
  const [includes, setIncludes] = useState(true);
  // --------------------------------------------------- //

  useEffect(() => {
    // setSelected({ ...props.selected });
    setKey(props.selected._id);
    setWord(props.selected.word);
    setSentimento(props.selected.sentimento);
    setIncludes(props.selected.includes);
  }, [props.selected]);

  // ----------------- Functions ------------------------ //
  function reload() {
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  function submitInformation() {
    let url;
    let dados = {
      word,
      sentimento,
      includes,
      _id: key,
    };
    if (dados._id) {
      url = `api/database/tag/${dados._id}`;
      api
        .put(url, dados, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          toast.success('Cadastrado, atualizando lista');
          reload();
        })
        .catch((err) =>
          toast.error('Erro #005: Erro Fatal! Acionar o suporte')
        );
    } else {
      url = 'api/database/tag';
      api
        .post(url, dados, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          toast.success('Cadastrado, atualizando lista');
          reload();
        })
        .catch((err) =>
          toast.error('Erro #005: Erro Fatal! Acionar o suporte')
        );
    }
  }
  // --------------------------------------------------- //

  return (
    <div className={tagsEdtiStyle.wordbox}>
      <form onSubmit={(e) => submitInformation(e)}>
        <div className="d-flex flex-column mb-4 pe-4">
         <div className='containerLabel mb-2'>
         <label className='defaultLabel'>Palavra</label>
         </div>
          <input
          className='input-bootstrap'
            type="text"
            required
            onChange={(e) => setWord(e.target.value)}
            value={word}
            placeholder='ex: Amei'
          />
        </div>

        <div className={tagsEdtiStyle.feeling}>
          <label className='mb-2'>Sentimento</label>
          <div className={tagsEdtiStyle.alignBoxes}>
            <FormControlLabel
              control={
                <CheckBox
                  onChange={(e) => setSentimento(-1)}
                  checked={sentimento === -1}
                />
              }
              label="Negativo"
            />
            <FormControlLabel
              color="success"
              control={
                <CheckBox
                  onChange={(e) => setSentimento(0)}
                  checked={sentimento === 0}
                />
              }
              label="Neutro"
            />
            <FormControlLabel
              control={
                <CheckBox
                  onChange={(e) => setSentimento(1)}
                  checked={sentimento === 1}
                />
              }
              label="Positivo"
            />
          </div>
        </div>
        <div className={tagsEdtiStyle.includes}>
          <label>Visível</label>
          <div className={tagsEdtiStyle.alignBoxes}>
            <FormControlLabel
              control={
                <CheckBox
                  onChange={(e) => setIncludes(e.target.checked)}
                  checked={includes}
                />
              }
              label={includes ? 'Sim' : 'Não'}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center my-3'>
          <button type='submit' className='btn btn-primary containerBtn-4' >
            Confirmar
          </button>
        </div>
      </form>
    </div>
  );

  // function renderTab(parent, idx = 0) {
  //   const child = parent.child ?? [];
  //   return (
  //     <ul>
  //       {child.map(function (tab) {
  //         // console.log(selectedTab, tab, selectedTab == tab);
  //         let btn;
  //         if (idx < 4) {
  //           btn = (
  //             <AddIcon
  //               style={{color: "white"}}
  //               className="addIconStyle2"
  //               fontSize="medium"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 addNew(tab.key);
  //               }}
  //             />
  //           );
  //         }
  //         let childs;
  //         if (tab.child?.length > 0) childs = renderTab(tab, idx + 1);
  //         return (
  //           <li key={tab.key}>
  //             <div className="alinhamento">
  //               <span
  //                 className={selectedTab?.key === tab.key ? 'active' : 'select'}
  //                 onClick={(e) => {
  //                   setSelectedTab(tab);
  //                   e.stopPropagation();
  //                 }}
  //               >
  //                 {tab.codigo} - {tab.descricao}
  //               </span>
  //               {btn}
  //               <DeleteIcon
  //                 style={{color: "white"}}
  //                 className="delIconStyle"
  //                 fontSize="medium"
  //                 onClick={(e) => {
  //                   e.stopPropagation();
  //                   deleteTab(tab.key);
  //                 }}
  //               />
  //             </div>
  //             {childs}
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   );
  // }

  // function addNew(parentKey) {
  //   function addIntoChild(pKey, self, newTab) {
  //     if (!self.child) self.child = [];
  //     if (self.key == pKey) {
  //       self.child.push(newTab);
  //       return true;
  //     } else {
  //       for (const ch of self.child) {
  //         if (addIntoChild(pKey, ch, newTab)) return true;
  //       }
  //     }
  //     return false;
  //   }

  //   let nTab = {
  //     key: uuidv4(),
  //     descricao: 'descrição',
  //     codigo: 'código',
  //   };
  //   const q = selectedQueue;
  //   addIntoChild(parentKey, q, nTab);
  //   setSelectedQueue({ ...q });
  //   setSelectedTab(nTab);
  //   setChild(q.child);
  // }

  // function updateTab(tab) {
  //   function updateTabChild(self, nTab) {
  //     if (!self.child) self.child = [];
  //     for (const ch in self.child) {
  //       if (self.child[ch].key == nTab.key) {
  //         self.child[ch] = nTab;
  //         return true;
  //       }
  //       if (updateTabChild(self.child[ch], nTab)) return true;
  //     }
  //     return false;
  //   }
  //   const q = selectedQueue;
  //   updateTabChild(q, tab);
  //   setSelectedQueue({ ...q });
  //   setSelectedTab(null);
  //   setChild(q.child);
  // }

  // function deleteTab(key) {
  //   function deleteTabChild(self, tabKey) {
  //     if (!self.child) self.child = [];
  //     for (const ch in self.child) {
  //       if (self.child[ch].key == tabKey) {
  //         self.child.splice(ch, 1);
  //         return true;
  //       }
  //       if (deleteTabChild(self.child[ch], tabKey)) return true;
  //     }
  //     return false;
  //   }
  //   if (
  //     window.confirm(
  //       'Deseja excluir essa tabulação e todas as suas tabulações filhas?'
  //     )
  //   ) {
  //     const q = selectedQueue;
  //     deleteTabChild(q, key);
  //     setSelectedQueue({ ...q });
  //     setSelectedTab(null);
  //     setChild(q.child);
  //   }
  // }
}
