import summarizeStyle from "./summarize.module.css";
import React, { useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import TableCall from "../../components/tables/summarizeSendsTable";
import api from "../../services/api";
import { toast } from "react-toastify";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";

export default function Hsm() {
  const defaultHSMSearch = {
    from_dateTime: "",
    to_dateTime: "",
    columns: {},
  };

  const [HSMSearch, setHSMSearch] = useState({ ...defaultHSMSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);

  const handleChange = (type, value) => {
    let _HSMSearch = { ...HSMSearch };
    _HSMSearch[type] = value;
    setHSMSearch({ ..._HSMSearch });
  };

  const loadTables = async () => {
    let hsmReq = {};

    if (HSMSearch.from_dateTime && HSMSearch.to_dateTime) {
      HSMSearch.from_dateTime = new Date(HSMSearch.from_dateTime);
      HSMSearch.to_dateTime = new Date(HSMSearch.to_dateTime);

      hsmReq = {
        from_dateTime: HSMSearch.from_dateTime,
        to_dateTime: HSMSearch.to_dateTime,
      };
    }

    await api
      .post("api/espresso/sentHSM", hsmReq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha na pesquisa");
      });
    return;
  };

  const cleanSearch = () => {
    setHSMSearch({ ...defaultHSMSearch });
    setRetornoAPI({ ...[] });
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-4">
        <p className="titlePage">Envios de HSM</p>
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
          >
            <label className="defaultLabel me-2">De: </label>

            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={HSMSearch.from_dateTime}
              id="searchdatefrom"
              onChange={(e) => handleChange("from_dateTime", e.target.value)}
            />
          </div>

          <div
            className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
          >
            <label className="defaultLabel me-2">Até: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={HSMSearch.to_dateTime}
              id="searchdateto"
              onChange={(e) => handleChange("to_dateTime", e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          <TableCall
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: "black", color: "white" }}
          />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
