import messageStyle from "./messages.module.css";
import MessagesListTable from "../../components/tables/messagesTable.js";
import RegisterMessageHSM from "./registerMessage";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import addCircle from "../../images/addCircle.svg";
import ModalCall from "../../components/tables/modalOpen";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function Messages() {
  // States

  const [messageAPI, setMessagesAPI] = useState({});
  const [search, setSearch] = useState(false);

  // End State

  const fetchMessages = async () => {
    try {
      const response = await api.get("api/espresso/mensagens/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length === 0) {
        toast.error("Erro #004: Sem resultado de busca");
      } else {
        setMessagesAPI(response.data);
      }
    } catch (error) {
      toast.error("Erro #003: Falha ao acessar o banco de dados");
    }
  };

  //OnPage Load
  useEffect(() => {
    // console.log("problema é aqui");
    if (!search) {
      fetchMessages(); // Chama a função assíncrona imediatamente
    }

    // Função de limpeza (opcional)
    return () => {
      setSearch(true);
    };
  }, []);
  // End Page Load//

  return (
    <div className={messageStyle.messages}>
      <div className={messageStyle.msgToolBar}>
        <div className="titlePage">Templates</div>
        <div>
          <ModalCall
            modalSize={{ height: 80, width: 35 }}
            buttonString="Adicionar"
            boxName="Adicionar Mensagem"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007BFF",
              color: "white",
              padding: 1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005BFF" },
            }}
          >
            <RegisterMessageHSM />
          </ModalCall>
        </div>
      </div>
      <div className={messageStyle.slBottomBox}>
        <StyledEngineProvider injectFirst>
          <MessagesListTable messages={messageAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
