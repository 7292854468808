import Chart from "react-apexcharts";

function sizeBar(y) {
  if (y.length == 1) {
    return "20%";
  }
  if (y.length < 3) {
    return "25%";
  }
  if (y.length < 5) {
    return "30%";
  }
  if (y.length < 7) {
    return "40%";
  } else {
    return "80%";
  }
}

export default function UraChart({ dados }) {
  let series = [];
  const dadosTotal = {};
  console.log(dados);

  dados.map((d) => {
    dadosTotal[d.Hora] = d.Total ?? 0;
  });

  for (let i = 0; i < dados.length; i++) {
    series.push({ x: dados[i].Hora?.toString() ?? 0, y: dados[i].Total ?? 0 });
  }

  let config = {
    options: {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          columnWidth: sizeBar(dados),
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#007BFF", "#26979E", "#30ABBC", "#456099"],

      tooltip: {
        y: {
          formatter: undefined,
          title: {
            formatter: () => "Conversações nesta hora: ",
          },
        },
      },

      legend: {
        show: false,
      },

      xaxis: {
        type: "category",
      },

      chart: {
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
    },
  };

  return (
    <Chart
      options={config.options}
      series={[{ data: series }]}
      type="bar"
      height={`100%`}
    />
  );
}
