import { config, maxDays } from "../../../services/utils";
import analitcsStyle from "./analitics.module.css";
import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";

export default function ModalAnalitics({ setCall, setData, onCloseRequired }) {
  const _filter = {
    date: { from: "", to: "" },
    origin_ura: "",
    number: "",
    tag: "",
  };
  const [filter, setFilter] = useState(_filter);

  const queryData = async (event) => {
    event.preventDefault();
    setCall(false);
    const apiFilter = {};
    if (filter.origin_ura !== "") apiFilter.origin_ura = filter.origin_ura;
    if (filter.number !== "") apiFilter.phone = filter.number;
    if (filter.date.from !== "") apiFilter.date_start = filter.date.from;
    if (filter.date.to !== "") apiFilter.date_end = filter.date.to;
    if (maxDays(filter.date.from, filter.date.to, 10)) {
      if (onCloseRequired) onCloseRequired();
      await api
        .get("api/database/report/historya", {
          params: apiFilter,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Não há relatórios para esta data");
          } else {
            toast.success("Busca realizada com sucesso");
          }
          setCall(true);
          setData(res.data);
          return;
        })
        .catch((err) => {
          setCall(true);
          toast.error("Erro #003: Falha na pesquisa");
        });
    } else {
      setCall(true);
      toast.warn("Datas excedem o limite de 10 dias");
    }
  };

  return (
    <div className={`${analitcsStyle.analiticsField}`}>
      <form className="d-flex flex-column w-100" onSubmit={(e) => queryData(e)}>
        <label className="defaultLabel">Origem</label>
        <select
          className="select-bootstrap mt-1 mb-3"
          value={filter.origin_ura}
          onChange={(e) => setFilter({ ...filter, origin_ura: e.target.value })}
        >
          <option value="">Todas</option>
          {config.summarize.map((value) => (
            <option value={value.origin_ura}>{value.visualName}</option>
          ))}
        </select>

        <div className={analitcsStyle.inputContainer}>
          <label className="defaultLabel">Tag</label>
          <input
            className="input-bootstrap mt-1 mb-3"
            placeholder="Tag"
            type="text"
            value={filter.tag}
            id="tag"
            onChange={(e) => setFilter({ ...filter, tag: e.target.value })}
          />
        </div>

        <div className={analitcsStyle.inputContainer}>
          <label className="defaultLabel">Número</label>
          <input
            className="input-bootstrap mt-1 mb-3"
            type="text"
            placeholder="+5511912345678"
            value={filter.number}
            id="number"
            onChange={(e) => setFilter({ ...filter, number: e.target.value })}
          />
        </div>

        <div className="d-flex justify-content-between pe-5 mb-4">
          <div className={`${analitcsStyle.timerContainer} d-flex flex-column`}>
            <label className="defaultLabel">De</label>
            <input
              className="input-bootstrap mt-1"
              type="datetime-local"
              value={filter.date.from}
              id="searchdatefrom"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, from: e.target.value },
                })
              }
            />
          </div>

          <div className={`${analitcsStyle.timerContainer} d-flex flex-column`}>
            <label className="defaultLabel">Até</label>
            <input
              className="input-bootstrap mt-1"
              type="datetime-local"
              value={filter.date.to}
              id="searchdateto"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, to: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary containerBtn-4">
            Pesquisar
          </button>
        </div>
      </form>
    </div>
  );
}
