import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
  icon: {
    color: " rgb(238, 0, 0)",
    fontSize: "72px",
  },
  text: {
    fontSize: "24px",
    textAlign: "center",
    fontFamily: "Open sans",
    margin: "0px",
  },
}));

export default function HibernationModal({
  open,
  handleClose,
  handleSelect,
  options,
}) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className="d-flex justify-content-end">
          <button onClick={() => handleClose()} className="btn">
            <CloseIcon />
          </button>
        </div>
        <div className="text-center mt-4">
          <h2 className={classes.text}>Deseja adiar a hibernação?</h2>
          <p className="mt-3 mb-4">
            Selecione a quantidade de minutos que deseja adiar:
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center ">
          {options.map((op) => (
            <button
              key={op}
              onClick={() => handleSelect(op)}
              className="btn btn-primary d-flex mx-4"
              type="button"
            >
              <span className="pe-1">{op}</span> <span>min.</span>
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}
