import summarizeStyle from "./summarize.module.css";
// import classes from "./summarize.module.css"; adding specific module
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import TableCall from "../../components/tables/summarizeSendsTable";
import api from "../../services/api";
import { toast } from "react-toastify";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import SearchingSelect from "../../components/UI/searchSelect";

export default function HsmList() {
  const defaultHSMSearch = {
    // from_date: "",
    // to_date: "",
    // from_time: "",
    // to_time: "",
    from_dateTime: "",
    to_dateTime: "",
    listName: "Selecione...",
    columns: {},
  };

  const [HSMSearch, setHSMSearch] = useState({ ...defaultHSMSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [namemailings, setNameMailings] = useState([]);
  const [init, setInit] = useState("");
  const [end, setEnd] = useState("");
  const [disable, setDisabled] = useState(true);

  async function loadMsgs(init, end) {
    await api
      .get(
        `api/espresso/mailingsList?from_datetime=${init}&to_datetime=${end} `,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        let uniqueNames = [
          ...new Set(res.data.map((value) => value.namemailing)),
        ];
        setNameMailings(uniqueNames);
        setDisabled(false);
      })
      .catch((err) => {
        toast.error("Erro #003: Falha no acesso ao banco de dados");
      });
  }

  const handleChange = (type, value) => {
    if (type == "from_dateTime") {
      setInit(value);
    }
    if (type == "to_dateTime") {
      setEnd(value);
    }
    let _HSMSearch = { ...HSMSearch };
    _HSMSearch[type] = value;
    setHSMSearch({ ..._HSMSearch });
  };

  const loadTables = async () => {
    let hsmReq = {};

    if (HSMSearch.from_dateTime && HSMSearch.to_dateTime) {
      HSMSearch.from_dateTime = new Date(HSMSearch.from_dateTime).toISOString();
      HSMSearch.to_dateTime = new Date(HSMSearch.to_dateTime).toISOString();

      hsmReq = {
        from_dateTime: HSMSearch.from_dateTime,
        to_dateTime: HSMSearch.to_dateTime,
        listName: HSMSearch.listName,
      };
    }

    await api
      .post("api/espresso/sentHSMList", hsmReq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha na pesquisa");
      });
    return;
  };

  const cleanSearch = () => {
    setHSMSearch({ ...defaultHSMSearch });
    setInit("");
    setEnd("");
    setDisabled(true);
    setRetornoAPI({ ...[] });
  };

  function selectList() {
    return (
      // <SearchingSelect opts={namemailings} fn={handleChange} />
      <select
        disabled={disable}
        className="select-bootstrap"
        value={HSMSearch.listName}
        onChange={(e) => handleChange("listName", e.target.value)}
      >
        <option defaultValue="" hidden>
          {" "}
          Selecione ...
        </option>
        {namemailings.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  }

  useEffect(() => {
    if (HSMSearch.from_dateTime && HSMSearch.to_dateTime) {
      let date_init = new Date(HSMSearch.from_dateTime).toISOString();
      let date_end = new Date(HSMSearch.to_dateTime).toISOString();
      loadMsgs(date_init, date_end);
    }
  }, [HSMSearch.from_dateTime, HSMSearch.to_dateTime]);

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-2">
        <p className="titlePage">Envios de HSM por Lista</p>
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
          >
            <label className="defaultLabel me-2">De: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={init}
              id="searchdatefrom"
              onChange={(e) => handleChange("from_dateTime", e.target.value)}
            />
          </div>
          {/* <input
            type="time"
            value={HSMSearch.from_time}
            id="searchtimefrom"
            onChange={(e) => handleChange("from_time", e.target.value)}
          /> */}
          <div
            className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
          >
            <label className="defaultLabel me-2">Até: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={end}
              id="searchdateto"
              onChange={(e) => handleChange("to_dateTime", e.target.value)}
            />
          </div>
          {/* <input
            type="time"
            value={HSMSearch.to_time}
            id="searchtimefrom"
            onChange={(e) => handleChange("to_time", e.target.value)}
          /> */}
          <div
            className={`d-flex align-items-center ${summarizeStyle.containerSelectHeader} me-3`}
          >
            <label className="defaultLabel me-2">Lista: </label>
            {selectList()}
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          <TableCall retornoAPI={retornoAPI} nameList={false} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
