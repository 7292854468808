/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { forTheKing, uploadMediatoBucket } from "../../../../services/utils";
import { toast } from "react-toastify";
import audioStyles from "./audioRecorder.module.css";
import CircularProgress from "@mui/material/CircularProgress";

// ---- Icons --- //
import MicIcon from "@mui/icons-material/Mic";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
// -------------- //

// Voice Recorder Imports //
// import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import ReactAudioPlayer from "react-audio-player";
// ----------------- //

export default function AudioRecorder() {
  //const [recordState, setRecordState] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [onRec, setOnRec] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rec, setRec] = useState(null);
  const [urlAudio, setUrlAudio] = useState(null);

  // Voice Recorder Handles //
  useEffect(() => {
    if (rec) {
      rec.ondataavailable = function (typedArray) {
        var dataBlob = new Blob([typedArray], { type: "audio/ogg" });
        // var fileName = new Date().toISOString() + ".opus";
        setUrlAudio(URL.createObjectURL(dataBlob));
        setAudioData(dataBlob);
      };
    }
  }, [rec]);

  useEffect(() => {
    if (rec) {
      if (onRec) rec.start();
      else rec.stop();
    }
  }, [onRec, rec]);
  const start = () => {
    setRec(
      new Recorder({ encoderPath: "/vendor/opus-recorder/encoderWorker.js" })
    );
    setOnRec(true);
  };

  const stop = () => {
    // setRecordState(RecordState.STOP);
    // rec.stop();
    setOnRec(false);
  };

  function clearAudio() {
    setAudioData(null);
    setUrlAudio(null);
    setAudioData(null);
  }

  // const onStop = (audioDataStorage) => {
  //   setAudioData(audioDataStorage);
  // };

  async function sendingTo(upfile) {
    setLoading(true);

    upfile = Object.assign(upfile, {
      name: `audio.${upfile.type.split("/")[1]}`,
    });

    await uploadMediatoBucket(upfile)
      .then((bucketReturn) => {
        const valueURL = window.location.pathname.split("/");
        const swordHandle = {
          number: valueURL[3],
          origin: valueURL[4],
          interactionId: valueURL[5],
          agentJwt: localStorage.getItem("pcToken"),
          location: bucketReturn.Location,
          name: bucketReturn.key.split("/")[
            bucketReturn.key.split("/").length - 1
          ],
        };

        forTheKing(swordHandle).then((res) => {
          toast.success("Arquivo enviado com sucesso");
          setInterval(() => {
            setLoading(false);
            window.close();
          }, 1000);
        });
      })
      .catch((err) => {
        toast.error("Falha ao enviar Arquivo");
        setLoading(false);
      });
  }

  // ----------------- //

  return (
    <div className={`${audioStyles.container} pt-2`}>
      {/* <div className="recordDivision">
         <AudioReactRecorder
          state={recordState}
          onStop={onStop}
          type="audio/opus"
        />
      </div> */}
      <div className={audioStyles.buttonBoxRecord}>
        <div className={audioStyles.recordBtns}>
          {/* {onRec === false ? ( */}
          <button
            className={
              onRec
                ? `${audioStyles.buttonDesignOnRec} ${audioStyles.btnIcons}`
                : `${audioStyles.buttonDesign} ${audioStyles.btnIcons}`
            }
            onClick={start}
            disabled={onRec}
          >
            <MicIcon className={audioStyles.icon} />
          </button>
          {/* ) : (
            <strong className="buttonDesignOnRec" disabled>
              <MicIcon className="icon" />
            </strong>
          )} */}

          {/* {onRec === false ? (
            <strong className="buttonDisabled" disabled>
              <StopCircleIcon className="icon" />
            </strong>
          ) : ( */}
          <button
            className={
              onRec
                ? `${audioStyles.buttonDesign} ${audioStyles.btnIcons}`
                : `${audioStyles.buttonDisabled} ${audioStyles.btnIcons}`
            }
            onClick={stop}
            disabled={!onRec}
          >
            <StopCircleIcon className={audioStyles.icon} />
          </button>
          {/* )} */}

          {audioData === null /*&& recordState === null*/ ? (
            <button
              className={(audioStyles.buttonDisabled, audioStyles.btnIcons)}
              disabled
            >
              <CloudUploadIcon className={audioStyles.icon} />
            </button>
          ) : loading === true ? (
            <CircularProgress />
          ) : (
            <button
              className={(audioStyles.buttonDesign, audioStyles.btnIcons)}
              onClick={() => sendingTo(audioData)}
            >
              <CloudUploadIcon className={audioStyles.icon} />
            </button>
          )}

          <button
            onClick={clearAudio}
            disabled={!audioData}
            className={audioStyles.btnIcons}
          >
            <DeleteIcon className={audioStyles.textDanger} />
          </button>
        </div>
      </div>

      <div className={audioStyles.recordPreview}>
        {urlAudio ? (
          <ReactAudioPlayer key={urlAudio} src={urlAudio} controls />
        ) : (
          <ReactAudioPlayer controls />
        )}
      </div>
    </div>
  );
}
