import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import "../genesys.css";
import api from "../../../services/api";
import { config, PURECLOUD_URL } from "../../../services/utils";


export default function AddTab() {
  const [options, setOptions] = useState([]);
  const [nivel, setNivel] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [niveisArray, setNiveisArray] = useState([]);
  const [tabState, setTabState] = useState([]);

  useEffect(async () => {
    await loadTabs();
  }, []);
  // ----------------------------------------------- //
  async function loadTabs() {
    const outcomeRequest = await api
      .get(
        `${PURECLOUD_URL}/flows/datatables/${config.application.client.dataTableID}/rows?pageNumber=1&pageSize=500&showbrief=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
          },
        }
      )
      .then((res) => {
        let formated = [
          ...res.data.entities.sort(function (a, b) {
            var p1 = a.descricao;
            var p2 = b.descricao;
            if (p1 < p2) return -1;
            if (p1 > p2) return 1;
            return 0;
          }),
        ];
        setTabs(formated);
        return formated;
      })
      .catch((err) => {
        toast.error(err.message || "Internal server error :" + err);
      });
    let _nivel;
    for (let tab of outcomeRequest) {
      if (tab.nivel > nivel) {
        _nivel = tab.nivel;
        setNivel(tab.nivel);
      }
    }
    let _niveisArray = [];

    for (let i = 0; i < _nivel; i++) {
      _niveisArray[i] = [];
      for (let tab2 of outcomeRequest) {
        if (tab2.nivel === i + 1) {
          _niveisArray[i].push(tab2);
        }
      }
    }
    setNiveisArray(_niveisArray);
  }

  function dropDown() {
    return niveisArray.map((nivelArray, idx) =>
      options.length >= idx ? (
        <div>
          <select
            required
            className="tabSelect"
            disabled={tabState[idx]}
            onChange={(event) => {
              handleSelectedOption(event.target.value, idx);
            }}
          >
            <option selected defaultValue={[]}>
              Selecionar Nível {idx + 1}
            </option>
            {nivelArray.map((row) =>
              checkParent(row.codigoPai) ? (
                <option value={row.codigo}>{row.descricao}</option>
              ) : null
            )}
          </select>
        </div>
      ) : null
    );
  }

  async function handleSubmit(event) {
    event.persist();
    event.preventDefault();

    let wrap = options.join("-");
    clearFields();

    const head = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
    };
    const headLw = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const valueURL = window.location.pathname.split("/");

    const body = {
      wrap,
      head,
      number: valueURL[3],
      origin: valueURL[4],
      interactionId: valueURL[5],
      baseUrl: PURECLOUD_URL,
    };

    let url = `/api/inbound/${
      localStorage.getItem("call") ? "calloutcome" : "outcome"
    }`; //revisar
    await api
      .post(url, body, { headers: headLw })
      .then((res) => {
        toast.success("Enviado com sucesso!");
      })
      .catch((err) =>
        toast.error(
          (err.response && err.response.data.errmsg) || "Internal server error"
        )
      );
    return false;
  }

  function handleSelectedOption(value, idx) {
    const nivelArrayChecked = niveisArray.map((nivelArray) => {
      return nivelArray.codigo === value ? { ...nivelArray, checked: true } : nivelArray;
    });
    setNiveisArray(nivelArrayChecked);

    let _opt = options;
    _opt.push(value);
    let _newArr = tabState;
    _newArr[idx] = true;
    setTabState(_newArr);
    setOptions(_opt);
  }

  function checkParent(parentCode) {
    if (parentCode === "") {
      return true;
    }
    for (let i = 0; i < options.length; i++) {
      if (options[i] === parentCode) {
        return true;
      }
    }
    return false;
  }

  function clearFields() {
    for (let i = 0; i < tabState.length; i++) {
      let newArr = tabState;
      newArr[i] = false;
      const nivelArrayChecked = niveisArray.map((nivelArray) => {
        return nivelArray.checked === true ? { ...nivelArray, checked: false } : nivelArray;
      });
      setNiveisArray(nivelArrayChecked);
      setTabState(newArr);
    }
    setOptions([]);
  }

  // ----------------------------------------------- //
  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      {dropDown()}
      <div>
        <div className="tabDivision">
          <button className="tabSend" type="submit">
            Enviar
          </button>
          <button className="tabClean" type="reset" onClick={clearFields}>
            Limpar
          </button>
        </div>
      </div>
    </form>
  );
}
