import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../../components/tables/table.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../services/api"
import { toast } from "react-toastify";
import MediumModal from "../../../components/UI/Modals/Medium";
import EditIcon from "@mui/icons-material/Edit";
import RegisterPageModal from "./modal";
import ScheduleRegister from "./weeklySchedule"


export default function ScheduleTable({ apiData }) {

  const columns = [
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "afterHoursCooldown", headerName: "Tempo de Espera", flex: 1, align: "center", headerAlign: "center",
      renderCell: (params) => (
        toHour(params.value)
      ),
    },
    {
      field: "afterHoursMessage", headerName: "Mensagem de Aviso", flex: 1, align: "center", headerAlign: "center",
      renderCell: (params) => (
        <MediumModal
          modalSize={{ height: 40, width: 30 }}
          iconButton={true}
          buttonString={<VisibilityIcon style={{ color: "#1abc9c" }} />}
          boxName="Mensagem de Aviso"
          styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
        >
          {params.value}
        </MediumModal>
      )
    },
    {
      field: "afterHoursEndingMessage", headerName: "Mensagem de Reabertura", flex: 1, align: "center", headerAlign: "center",
      renderCell: (params) =>
      (
        <div>
          {params.value?.length ?
            (
              <MediumModal
                modalSize={{ height: 40, width: 30 }}
                iconButton={true}
                buttonString={<VisibilityIcon style={{ color: "#1abc9c" }} />}
                boxName="Mensagem de Reabertura"
                styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
              >
                {params.value}
              </MediumModal>
            ) :
            ('Não há mensagem cadastrada')
          }
        </div>
      )
    },
    {
      field: "_id",
      headerName: "Alterações",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="inline_Alt">
          <div onClick={() => { }}>
            <RegisterPageModal
              modalSize={{ height: 60, width: 35 }}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Schedule"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <ScheduleRegister _id={params.value} />
            </RegisterPageModal>
          </div>
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c" }} onClick={() => { dellSchedule(params.value); }}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  function toHour(time) {
    if (time >= 60 && time % 60 != 0) {
      return ("0" + (time - 30) / 60).slice(-2) + ":30";
    }
    if (time >= 60 && time % 60 == 0) {
      return ("0" + time / 60).slice(-2) + ":00";
    }
    return "00:" + ("0" + time).slice(-2);
  }

  async function dellSchedule(_id) {
    if (window.confirm("Deseja mesmo Excluir ?")) {
      await api
        .delete(
          `api/espresso/workschedule?id=${_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Deletando Schedule Aguarde!");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          toast.error(
            (err.response && err.response.data.msg) || "Ocorreu um erro"
          );
        });
    }
  }

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < apiData.length; i++) {
    rows.push({
      id: i + 1,
      alteration: apiData[i].codemessage,
      ...apiData[i],
    });
  }

  return (
    <div style={{ height: '110%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createDate", sort: "desc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
