import wrapStyle from "./Wrapup.module.css";
import WrapupTable from "./WrapupTable.js";
import WrapupQueue from "./WrapupQueue.js";

import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

import addCircle from "../../images/addCircle.svg";
import ModalCall from "../../components/tables/modalOpen";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function Wrapup() {
  // States

  const [Wrapup, setWrapup] = useState([]);
  const [newTab, setNewTab] = useState({ queue: "", descricao: "", child: [] });

  // End State

  //OnPage Load
  useEffect(() => {
    api
      .get("api/espresso/tabulacao/findAll", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        setWrapup(res.data.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  }, []);
  // End Page Load//

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between me-4">
        <p className="titlePage">Tabulação</p>
        <div className="rightToolbar">
          <ModalCall
            modalSize={{ height: 50, width: 50 }}
            buttonString="Adicionar"
            boxName="Adicionar Tabulações"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1,
              fontSize: 15,
              fontWeight: 700,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
            onClose={(e) => {
              setNewTab({ queue: "", descricao: "", child: [] });
            }}
          >
            <WrapupQueue selectedQueue={newTab} />
          </ModalCall>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <WrapupTable wrapups={Wrapup} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
