import React, { useEffect, useState } from "react";
import styleConfig from "./configTabs.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { Tooltip } from "@mui/material";
import api from "../../../services/api";
import { toast } from "react-toastify";
import CircularLoading from "../../../components/loadModules/circularLoading";

export default function ConfigTabs() {
  const [pane, setPane] = useState(false);
  const [midia, setMidia] = useState("");
  const [queues, setQueues] = useState("");
  const [exception, setException] = useState("");
  const [loading, setLoading] = useState(false);
  const dynamicClass = pane
    ? styleConfig.paneChecked
    : styleConfig.paneUnchecked;
  useEffect(() => {
    api
      .get("api/inbound/urcfg/espressoInbound", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMidia(res.data["Bloqueio de mídia"].toString());
        setQueues(res.data["Filas com exceções"].toString());
        setException(res.data["Tipo de arquivo permitido"].toString());
        setPane(res.data.pane.active);
        setLoading(true);
      });
  }, []);

  function submit() {
    let obj = {
      "Bloqueio de mídia": dataAdapter(midia),
      pane: { active: pane },
      "Tipo de arquivo permitido": dataAdapter(exception),
      "Filas com exceções": dataAdapter(queues),
      id: localStorage.getItem("userName"),
    };

    console.log(obj.pane.active);

    api
      .post("api/inbound/urcfg/espressoInbound", obj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        toast.success("Configurações alteradas com sucesso");
      })
      .catch(() => {
        toast.error("Ouve um erro ao alterar suas configurações");
      });
  }

  function dataAdapter(value) {
    if (value.length > 0) return value.split(",");
    else return "";
  }

  return (
    <div className={styleConfig.configTabs}>
      <div className={styleConfig.configBox}>
        <div className={styleConfig.boxTitle}>
          <SettingsIcon style={{ color: "white" }} />
          <span>Configurações</span>
        </div>

        <div className={styleConfig.contentBox}>
          <div className={styleConfig.paneMsg}>
            <input
              type="checkbox"
              id="pane"
              onChange={(e) => setPane(e.target.checked)}
              className="checkerHidder"
              hidden
            />
            <div className="containerLabel">
              <label className="defaultLabel"> Mensagem de Pane: </label>
            </div>
            <label
              htmlFor="pane"
              className={`${styleConfig.pane} ${dynamicClass}`}
            >
              {pane ? "Ativado" : "Desativado"}{" "}
            </label>
          </div>

          <div className={styleConfig.midiaBlock}>
            <div className="containerLabel">
              <label className="defaultLabel">Bloqueio De Mídias:</label>
            </div>
            <Tooltip
              title="filas que nenhum tipo de mídia é permitido. Por Favor separar por vírgula"
              arrow
            >
              <textarea
                value={midia}
                onChange={(e) => setMidia(e.target.value)}
              />
            </Tooltip>
          </div>

          <div className={styleConfig.midiaBlock}>
            <div className="containerLabel">
              <label className="defaultLabel">Filas com exceções:</label>
            </div>
            <Tooltip
              title="filas que terão liberados os tipos de mídia abaixo. Por favor separar por vírgula"
              arrow
            >
              <textarea
                value={queues}
                onChange={(e) => setQueues(e.target.value)}
              />
            </Tooltip>
          </div>

          <div className={styleConfig.midiaBlock}>
            <div className="containerLabel">
              <label className="defaultLabel">Tipos de Midia Permitido:</label>
            </div>
            <Tooltip
              title="Tipos de arquivos que serão permitidos. Por favor separar por vírgula"
              arrow
            >
              <textarea
                value={exception}
                onChange={(e) => setException(e.target.value)}
              />
            </Tooltip>
          </div>

         <div className="containerBtn-4">
         <button className="btn btn-primary btn-size" onClick={() => submit()}>
            Salvar
          </button>
         </div>
        </div>
      </div>
    </div>
  );
}
