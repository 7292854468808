import React, { useRef, useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import { config } from "../../services/utils";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import milestoneStyle from "../milestone/Milestone.module.css";

function processaNode(node, x, y, total) {
  const t = `translate(${x},${y})`;
  const c = [];
  let maxOffset = 0;
  if (node.childs)
    for (const n in node.childs) {
      const { offset, nodes } = processaNode(
        node.childs[n],
        150,
        maxOffset,
        node.qtd
      );
      c.push(
        <g key={n}>
          {nodes}
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: "0.5px",
            }}
            d={`M 120,5 C 135,5 135,${maxOffset + 5} 150,${maxOffset + 5}`}
            id="path4555"
          />
        </g>
      );
      maxOffset += offset;
    }
  if (maxOffset < 30) maxOffset = 30;
  const w = 119.2 * (1 - node.qtd / total);
  return {
    offset: maxOffset,
    nodes: (
      <g id="node" transform={t} key={crypto.randomUUID()}>
        <rect
          style={{
            fill: "#0d2f5a",
          }}
          id="fundo"
          width="120"
          height="10"
          x="0"
          y="0"
        />
        <rect
          y="0.4"
          x="0.4"
          height="9.2"
          width="119.2"
          id="base"
          style={{
            fill: "#0083ae",
          }}
        />
        <text
          id="info"
          y="18"
          x="2"
          style={{
            fontSize: "10px",
            fontFamily: "roboto",
            fill: "#0d2f5a",
          }}
        >
          {node.title} : {node.qtd}
        </text>
        <rect
          y="0.4"
          x={119.6 - w} //"80"
          height="9.2"
          width={w} //"39.6"
          id="restante"
          style={{
            fill: "#65bbe8",
          }}
        />
        {c}
      </g>
    ),
  };
}

export default function MilestonePeriodo() {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [milestone, setMilestone] = useState([]);
  const [origin, setOrigin] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const queryData = async () => {
    api
      .get("api/database/report/milestoneperiodo", {
        params: {
          origin: origin,
          fromDate: fromDate,
          toDate: toDate,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data.length === 0) {
          console.log("entrou");
          toast.error("Erro #004: Sem resultado de busca");
        }
        setMilestone(res.data.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  };
  const clearData = () => {
    setOrigin("");
    setFromDate("");
    setToDate("");
    setMilestone([]);
    toast.success("Os Campos foram limpos");
  };

  //OnPage Load
  useEffect(() => {
    setHeight(ref.current.parentElement.offsetHeight);
    setWidth(ref.current.parentElement.offsetWidth - 200);
  }, []);
  useEffect(() => {
    var svg = document.getElementById("ura");
    var bbox = svg.getBBox();
    svg.setAttribute("width", bbox.x + bbox.width + bbox.x + 30);
    svg.setAttribute("height", bbox.y + bbox.height + bbox.y + 30);
  }, [milestone]);
  // End Page Load//

  const node = [];
  let maxOffset = 0;
  for (const mil of milestone) {
    const { offset, nodes } = processaNode(mil, 0, maxOffset, mil.qtd);
    node.push(nodes);
    maxOffset += offset;
  }
  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <p className="titlePage">Fluxo do Bot</p>
        <div className="d-flex align-items-center pe-4">
          <div
            className={`d-flex align-items-center ${milestoneStyle.containerSelect}`}
          >
            <label className="defaultLabel pe-2">Origem:</label>
            <select
              className="select-bootstrap"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            >
              <option defaultValue value={""}>
                Selecione a Origem
              </option>
              {config.summarize.map((value) => (
                <option value={value.origin}>{value.visualName}</option>
              ))}
            </select>
          </div>
          <div
            className={`d-flex align-items-center ${milestoneStyle.containerInput} mx-3`}
          >
            <label className="defaultLabel pe-2">De:</label>
            <input
              className="input-bootstrap"
              type="datetime-local"
              value={fromDate}
              id="searchdatefrom"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div
            className={`d-flex align-items-center ${milestoneStyle.containerInput}`}
          >
            <label className="defaultLabel pe-2">Até:</label>
            <input
              className="input-bootstrap"
              type="datetime-local"
              value={toDate}
              id="searchdateto"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              queryData();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>

      <div className={milestoneStyle.workFlowTitle}>
        <AccountTreeIcon style={{ color: "white" }} />
        <span>Fluxo do Bot Por Período </span>
      </div>

      <div className={milestoneStyle.workFlowBoard}>
        <div className={milestoneStyle.workFlow} ref={ref}>
          <StyledEngineProvider injectFirst>
            <svg width={width} height={height} id="ura">
              <g if="zoom" transform="matrix(2,0,0,2,0,0)">
                {node}
              </g>
            </svg>
          </StyledEngineProvider>
        </div>
      </div>
      <div className={milestoneStyle.workFlowFooter} />
    </div>
  );
}
