import React, { useEffect, useState } from "react";
import api from "../../services/api";
import PieComeback from "../../components/charts/comebackPieChart";
import comebackStyles from "./comeback.module.css";
import { config } from "../../services/utils";

/* --------------- dash imgs -------------------  */
import TimelineIcon from "@mui/icons-material/Timeline";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import ForumIcon from "@mui/icons-material/Forum";
/* ---------------------------------------------- */

export default function DashComeback() {
  const [origin, setOrigin] = useState("");
  const [exec, setExec] = useState(true);
  const [returned, setReturned] = useState(0);
  const [hibernate, setHibernate] = useState(0);
  const [disconnected, setDisconnected] = useState(0);
  const [initDate, setInitDate] = useState();
  const [endDate, setEndDate] = useState();
  const [soma, setSoma] = useState(0);

  //date get on page load just one time

  useEffect(() => {
    let calcDateToday = new Date(Date.now()).toISOString().split("T")[0];
    let calcDateTomorrow = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0];
    setInitDate(calcDateToday);
    setEndDate(calcDateTomorrow);
  }, []);

  useEffect(() => {
    async function firstCall() {
      await api
        .get(
          `api/espresso/dashboards/hibernatedPeople?${
            origin.length > 0 ? `origin=${origin}&` : ""
          }initDate=${initDate}T00:00:00&endDate=${endDate}T00:00:00`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.length !== 0) {
            setReturned(
              res.data.find((sts) => sts.status === "RETURNED").total
            );
            setHibernate(
              res.data.find((sts) => sts.status === "HIBERNATE").total
            );
            setDisconnected(
              res.data.find((sts) => sts.status === "DISCONNECTED").total
            );
            setSoma(
              res.data.find((sts) => sts.status === "RETURNED").total +
                res.data.find((sts) => sts.status === "HIBERNATE").total +
                res.data.find((sts) => sts.status === "DISCONNECTED").total
            );
          } else {
            setReturned(0);
            setHibernate(0);
            setDisconnected(0);
            setSoma(0);
          }
        });
    }

    firstCall();

    setTimeout(() => {
      setExec(!exec);
    }, 15000);
  }, [exec, origin]);

  function boxMaker(creating) {
    return (
      <div className={comebackStyles.cmBoxes}>
        <div className={comebackStyles.cmBoxesHeader}>
          {creating.icon}
          <p className={comebackStyles.cmBoxesTitle}>{creating.name}</p>
        </div>
        <div className={comebackStyles.cmBoxesBody}>
          <p>{creating.value >= 0 ? creating.value : 0}</p>
        </div>
        <div className={comebackStyles.cmBoxesFooter}>
          <p>{creating.desc}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center">
        <p className="titlePage">Hibernação - Retornos</p>

        <div className="d-flex align-items-center me-4">
          <label className="defaultLabel me-2">Origem:</label>
          <select
            className="select-bootstrap"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option value=""> Todos </option>
            {config.summarize.map((value) => (
              <option value={value.origin}>{value.visualName}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="fullBox">
        <div className={comebackStyles.values}>
          {boxMaker({
            name: "Hibernado (dia)",
            desc: "Quantidade Hibernado Neste Dia",
            value: soma,
            icon: <NotificationsPausedIcon className="statusBoxIcons" />,
          })}

          {boxMaker({
            name: "Retorno",
            desc: "Quantidade de Retornos",
            value: returned,
            icon: <ForumIcon className="me-2" style={{ color: "#39ff8b" }} />,
          })}

          {boxMaker({
            name: "Time Out",
            desc: "Quantidade de Time-outs realizados",
            value: disconnected,
            icon: (
              <TimerOffIcon className="me-2" style={{ color: "#ffc65d" }} />
            ),
          })}

          {boxMaker({
            name: "Total Hibernado",
            desc: "Quantidade que permanece hibernando",
            value: hibernate,
            icon: <BedtimeIcon className="me-2" style={{ color: "#e2f10f" }} />,
          })}
        </div>

        <div className={comebackStyles.bgGraph}>
          <div className={comebackStyles.graphicTitleSend}>
            <TimelineIcon className="pe-2" style={{ color: "white" }} />
            <span>Status de Retornos</span>
          </div>

          <div className={comebackStyles.centralGraphic}>
            <PieComeback data={[returned, disconnected, hibernate]} />
          </div>
        </div>
      </div>
    </div>
  );
}
