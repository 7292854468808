import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles({
  header: {
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  },
  lateralHeader: {
    color: "black",
    fontSize: "14px",
  },
  lateralCell: {
    color: "black",
    fontSize: "14px",
  },
  totalCell: {
    color: "black",
    fontWeight: "bold",
    fontSize: "14px",
    background: "#e6e6e6",
  },
  noRowsOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "18px",
    color: "black",
  },
});

const columns = [
  {
    field: "status",
    headerName: "",
    width: 300,
    renderHeader: () => <div></div>,
  },
  { field: "disconnected", headerName: "Desconectado", width: 250 },
  { field: "returned_agent", headerName: "Retorno para agente", width: 250 },
  { field: "returned_queue", headerName: "Retorno para fila", width: 250 },
];
function NoRowsOverlay() {
  const classes = useStyles();
  return (
    <Box className={classes.noRowsOverlay}>
      <Typography variant="h6">Nenhum dado disponível</Typography>
    </Box>
  );
}

function UniqueNumber({ data }) {
  const classes = useStyles();

  return (
    <div style={{ height: "auto", minHeight: 350, width: "97.5%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        hideFooter
        getRowClassName={(params) => {
          if (params.row.status === "Nº Único Geral") {
            return classes.totalCell;
          }
          if (
            params.row.status === "Nº Único por Status" ||
            params.row.status === "% do Geral"
          ) {
            return classes.lateralCell;
          }
          return "";
        }}
        components={{
          ColumnHeaders: () => (
            <div
              className={classes.totalCell}
              style={{ display: "flex", width: "100%" }}
            >
              <div style={{ width: 200 }}></div>
              <div style={{ width: 150, textAlign: "center" }}>
                Disconnected
              </div>
              <div style={{ width: 150, textAlign: "center" }}>
                Returned Agent
              </div>
              <div style={{ width: 150, textAlign: "center" }}>
                Returned Queue
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
}

export default UniqueNumber;
