import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LargeModal from "../UI/Modals/Large";
import RegisterHibernation from "../../pages/hibernation/RegisterHibernation";
import { dellHibernation } from "../../services/utils";
import IconButton from "@mui/material/IconButton";

const columns = [
  { field: "queueName", headerName: "Nome Da Fila", flex: 1 },
  {
    field: "startHibernationTime",
    headerName: "Tempo Para Hibernar",
    flex: 1,
    renderCell: (params) => {
      const time = params.value;
      if (time >= 60 && time % 60 != 0) {
        return (time - 30) / 60 + "H30min";
      }
      if (time >= 60 && time % 60 == 0) {
        return time / 60 + "H";
      }
      return time + "min";
    },
  },
  {
    field: "hibernationStartMessage",
    headerName: "Mensagem de Hibernação",
    flex: 1,
  },
  {
    field: "totalHibernationTime",
    headerName: "Tempo de Hibernação",
    flex: 1,
    renderCell: (params) => {
      const time = params.value;
      if (time >= 60 && time % 60 != 0) {
        return (time - 30) / 60 + "H30min";
      }
      if (time >= 60 && time % 60 == 0) {
        return time / 60 + "H";
      }
      return time + "min";
    },
  },
  {
    field: "hibernationEndingMessage",
    headerName: "Msg. de Encerramento",
    flex: 1,
  },

  {
    field: "alteration",
    headerName: "Alterações",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div className="inline_Alt">
        <div onClick={() => {}}>
        <LargeModal
              modalSize={{height:60,width:35}}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Hibernação"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
            <RegisterHibernation _id={params.value} />
          </LargeModal>
        </div>
        <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c"}} onClick={() => {dellHibernation(params.value);}}> 
          <DeleteIcon />
        </IconButton>
      </div>
    ),
  },
];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }}/>
  </GridToolbarContainer>
);

export default function messagesListTable({ hibernation }) {
  var rows = [];

  for (let i = 0; i < hibernation.length; i++) {
    rows.push({
      id: i + 1,
      alteration: hibernation[i]._id,
      ...hibernation[i],
    });
  }

  return (
    <div style={{ height: "110%", width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
