import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState, useRef } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import GenericTable from "../../components/tables/genericTable.js";
import api from "../../services/api";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CircularLoading from "../../components/loadModules/circularLoading";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ModalCall from "../../components/tables/modalOpen";
import ModalAnalitics from "./modals/flowAnalitics";
import DownloadModal from "../../components/UI/downloadModal";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadByTime from "../cdr/downloadByTime";
import AnaliticsHistoryDownload from "./modals/AHistoryDownload";

export default function SummarizeFlowAnalitics() {
  const [data, setData] = useState([]);
  const [call, setCall] = useState(true);
  const modalRef = useRef();
  const styleButton = {
    bgcolor: "#007bff;",
    color: "white",
    padding: 1.1,
    fontSize: 16,
    fontWeight: 700,
    borderRadius: 2,
    boxShadow: 3,
    marginTop: 0,
    marginBottom: 0.2,
    marginLeft: 1,
    ":hover": { bgcolor: "#005bff;" },
  };

  const closeModal = () => {
    modalRef.current.close();
  };

  const clearData = () => {
    setData([]);
    toast.success("Os Campos foram limpos");
  };

  const dateConversion = (dateToConvert) => {
    return new Date(
      new Date(dateToConvert).getTime() + 3 * 60 * 60 * 1000
    ).toISOString();
  };

  return (
    <div className={`${summarizeStyle.summarize}`}>
      <div className="d-flex justify-content-between pt-2 pb-2">
        <p className="titlePage">Histórico - Analítico</p>
        <div className={`${summarizeStyle.searchFunction} pe-3`}>
          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <ModalCall
            modalSize={{ height: 50, width: 35 }}
            buttonString={<SearchIcon />}
            boxName="Pesquisa Histórico Analítico"
            className="send"
            styleButton={styleButton}
            ref={modalRef}
          >
            <div className="center">
              <ModalAnalitics
                setData={setData}
                setCall={setCall}
                onCloseRequired={closeModal}
              />
            </div>
          </ModalCall>

          {/* {config.application.modules?.logs.download === true ? */}
          <DownloadModal
            modalSize={{ height: 40, width: 30 }}
            buttonString={<DownloadIcon />}
            boxName="Download de Período de Dados"
            iconButton={false}
            styleButton={styleButton}
          >
            <div className="center">
              <AnaliticsHistoryDownload />
            </div>
          </DownloadModal>
          {/* : null} */}
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          {call ? (
            <GenericTable
              data={data}
              columns={[
                {
                  field: "crmId",
                  headerName: "ConversationID",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <Tooltip>
                      <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                  ),
                },
                {
                  field: "date",
                  headerName: "Data",
                  flex: 1,
                  type: "dateTime",
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <div>
                      <Tooltip title={new Date(params.value).toLocaleString()}>
                        <span className="table-cell-trucate">
                          {new Date(
                            dateConversion(params.value)
                          ).toLocaleString()}
                        </span>
                      </Tooltip>
                    </div>
                  ),
                },
                {
                  field: "phone",
                  headerName: "Telefone",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "origin",
                  headerName: "Origem",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "cliente",
                  headerName: "Tag",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "nickName",
                  headerName: "NickName",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "checkpoint",
                  headerName: "Checkpoint",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "message",
                  headerName: "Mensagem",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "rechamada",
                  headerName: "Rechamada (24h)",
                  flex: 0.5,
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <div>
                      <Tooltip title={params.value ? "Rechamada" : "Novo"}>
                        <span className="table-cell-trucate">
                          {params.value ? (
                            <QuestionAnswerIcon style={{ color: "#e74c3c" }} />
                          ) : (
                            <ChatBubbleIcon style={{ color: "#1abc9c" }} />
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  ),
                },
                {
                  field: "transbordo",
                  headerName: "Transbordo",
                  flex: 0.5,
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <div>
                      <Tooltip
                        title={params.value ? "Transbordo" : "Finalizada"}
                      >
                        <span className="table-cell-trucate">
                          {params.value ? (
                            <SupportAgentIcon style={{ color: "#1abc9c" }} />
                          ) : (
                            <SmartToyIcon style={{ color: "#e74c3c" }} />
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div style={{ width: "96%", height: "110%" }}>
              <CircularLoading />
            </div>
          )}
          {/* <HistoryTable
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: 'black', color: 'white' }}
          ></HistoryTable> */}
        </StyledEngineProvider>
      </div>
    </div>
  );
}
