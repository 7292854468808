import dashboardHibernationStyles from "./dashboardHibernation.module.css";
import BarChartHibernation from "../../components/charts/dashboardHibernation/barChartHibernation";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import CircularLoading from "../../components/loadModules/circularLoading";
import { Switch } from "@material-ui/core";

const months = {
  "01": "Jan",
  "02": "Fev",
  "03": "Mar",
  "04": "Abr",
  "05": "Mai",
  "06": "Jun",
  "07": "Jul",
  "08": "Ago",
  "09": "Set",
  10: "Out",
  11: "Nov",
  12: "Dez",
};

export default function DashboardHibernation() {
  const [typeGraph, setTypeGraph] = useState("week");
  const [data, setData] = useState([]);
  const [params, setParams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const [showTotal, setShowTotal] = useState(false);

  useEffect(() => {
    if (params.length) {
      callApi();
    }
  }, [params, typeGraph]);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, [showLabels]);

  function calculatePercent(data) {
    data.sort((a, b) => a.Semana - b.Semana);
    data.forEach((item) => {
      if (item.Atendidas != 0) {
        const percent = (item.Hibernacoes / item.Atendidas) * 100;
        item.percent = percent.toFixed(2);
      }
    });
    return data;
  }

  function formatData(data) {
    data.sort((a, b) => a.Dia - b.Dia);
    data.forEach((item) => {
      item.Dia = `${item.Dia} ${months[params[1]]}`;
    });
    data = calculatePercent(data);
    return data;
  }

  async function callApi() {
    setLoading(true);

    if (typeGraph == "week") {
      await api
        .get(
          `/api/database/report/dashWeek?year=${params[0]}&month=${params[1]}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.length) {
            let _data = calculatePercent(res.data);
            setData(_data);
          } else {
            toast.warning("Não há dados para esse período ");
            setData([]);
          }
        })
        .catch(() => {
          toast.error("Houve um erro ao processar a requisição");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await api
        .get(
          `/api/database/report/dashDaily?year=${params[0]}&month=${params[1]}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.length) {
            let _data = formatData(res.data);
            setData(_data);
          } else {
            toast.warning("Não há dados para esse período ");
            setData([]);
          }
        })
        .catch(() => {
          toast.error("Houve um erro ao processar a requisição");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleGraph(type) {
    setTypeGraph(type);
    setShowLabels(false);
    setShowTotal(false);
  }

  function handleParams(value) {
    let _params = value.split("-");
    setParams(_params);
    if (_params[0] < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    }
  }

  return (
    <div className="container pe-4">
      <div className="d-flex align-items-center justify-content-between">
        <div className="titlePage">Dashboard - Hibernação x Atendidas</div>
        <div className={dashboardHibernationStyles.containerSelectHeader}>
          <input
            onChange={(e) => handleParams(e.target.value)}
            type="month"
            className="input-bootstrap me-4"
          />

          <div className={dashboardHibernationStyles.containerSelect}>
            <select
              onChange={(e) => handleGraph(e.target.value)}
              className="select-bootstrap"
            >
              <option value={"week"}>Semana</option>
              <option value={"month"}>Dia</option>
            </select>
          </div>
        </div>
      </div>
      <div style={{ height: "90vh" }}>
        {loading ? (
          <CircularLoading />
        ) : (
          <div className={`${dashboardHibernationStyles.barContainer} mt-5`}>
            {data.length ? (
              <>
                <div className="d-flex align-items-center">
                  <div className="ps-4 d-flex align-items-center">
                    <p>Mostrar numeros?</p>
                    <Switch
                      checked={showLabels}
                      color="primary"
                      onChange={() => setShowLabels(!showLabels)}
                    />
                  </div>
                  {typeGraph == "week" ? (
                    <div className="ps-5 d-flex align-items-center">
                      <p>Exibir total?</p>
                      <Switch
                        checked={showTotal}
                        color="primary"
                        onChange={() => setShowTotal(!showTotal)}
                      />
                    </div>
                  ) : null}
                </div>
                <BarChartHibernation
                  data={data}
                  type={typeGraph}
                  showLabels={showLabels}
                  showTotal={showTotal}
                />
              </>
            ) : params.length ? (
              <em>Selecione os parâmetros para realizar a busca</em>
            ) : (
              <em>Não há dados para essa data</em>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
