import React, { useEffect, useState } from "react";
import api from "../../services/api";
import yearStyles from "./dashYear.module.css";

/* --------------- dash imgs -------------------  */
import TimelineIcon from "@mui/icons-material/Timeline";
import UpBarChart from "../../components/charts/upBarChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import { Link } from "react-router-dom";
import ActionBtn from "../../components/UI/actionbtn";
/* ---------------------------------------------- */

export default function DashYear() {
  const [exec, setExec] = useState(true);
  const [loadData, setLoadData] = useState([]);

  useEffect(() => {
    async function firstCall() {
      await api
        .get("api/database/report/interactions", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadData(res.data);
        });
    }
    firstCall();
    setTimeout(() => {
      setExec(!exec);
    }, 15000);
  }, [exec]);

  function actualMonth(dados) {
    const dadosTotal = {
      janeiro: 0,
      fevereiro: 0,
      março: 0,
      abril: 0,
      maio: 0,
      junho: 0,
      julho: 0,
      agosto: 0,
      setembro: 0,
      outubro: 0,
      novembro: 0,
      dezembro: 0,
    };

    dados.map((d) => {
      dadosTotal.janeiro += d.months.jan ?? 0;
      dadosTotal.fevereiro += d.months.fev ?? 0;
      dadosTotal.março += d.months.mar ?? 0;
      dadosTotal.abril += d.months.abr ?? 0;
      dadosTotal.maio += d.months.mai ?? 0;
      dadosTotal.junho += d.months.jun ?? 0;
      dadosTotal.julho += d.months.jul ?? 0;
      dadosTotal.agosto += d.months.ago ?? 0;
      dadosTotal.setembro += d.months.set ?? 0;
      dadosTotal.outubro += d.months.out ?? 0;
      dadosTotal.novembro += d.months.nov ?? 0;
      dadosTotal.dezembro += d.months.dez ?? 0;
    });

    var month = new Date(Date.now()).getMonth();
    return Object.values(dadosTotal)[month];
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between me-4 mb-4">
        <p className="titlePage">Dashboard - Conversas Anuais</p>
        <Link to="/app/dashboard/interactions">
          <ActionBtn color="green" icon={<TableChartIcon />} />
        </Link>
      </div>
      <div className={yearStyles.fullBox}>
        <div className="internalBox">
          <div className={yearStyles.graphicTitle}>
            <TimelineIcon className="me-2" style={{ color: "white" }} />
            <span>Status de Conversas no Ano</span>
          </div>
          <div className={yearStyles.graphicZone}>
            <UpBarChart dados={loadData} />
          </div>
        </div>

        <div className={yearStyles.internalFooter}>
          <div className="totalRow">
            <span className="me-2">Mês Atual:</span>
          </div>
          <div className="totalValue">
            <span> {actualMonth(loadData)} </span>
          </div>
        </div>
      </div>
    </div>
  );
}
