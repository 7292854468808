import React, { useState } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../components/tables/table.css";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomizedDialogs from "../../components/tables/modalOpen";
import ChatIcon from "@mui/icons-material/Chat";

export default function TimedHSMTable({ timedHSM }) {
  const columns = [
    { field: "phonecontact", headerName: "Telefone", flex: 2 },
    { field: "cdmessage", headerName: "Código da Mensagem", flex: 2 },
    { field: "queueName", headerName: "Fila", flex: 2 },
    { field: "senduser", headerName: "Usuário", flex: 2 },
    {
      field: "scheduleDate",
      headerName: "Data do Agendamento",
      type: "dateTime",
      flex: 2,
      renderCell: (params) => (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-trucate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "createdate",
      headerName: "Data Criada",
      type: "dateTime",
      flex: 2,
      renderCell: (params) => (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-trucate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "mensagemreq",
      headerName: "Mensagem",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={"Exibir Mensagem"}>
          <strong>
            <CustomizedDialogs
              modalSize={{ height: 40, width: 30 }}
              buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
              boxName="Mensagem"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
              iconButton={true}
            >
              {params.value}
            </CustomizedDialogs>
          </strong>
        </Tooltip>
      ),
    },
    {
      field: "codeSchedule",
      headerName: "Código",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={"Exibir Código"}>
          <strong>
            <CustomizedDialogs
              modalSize={{ height: 5, width: 20 }}
              buttonString={<VisibilityIcon sx={{ color: "#1abc9c" }} />}
              boxName="Código de Identificação"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
              iconButton={true}
            >
              <div className="centerID">
                {params.value}
              </div>
            </CustomizedDialogs>
          </strong>
        </Tooltip>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < timedHSM.length; i++) {
    rows.push({
      id: i + 1,
      test2: timedHSM[i].senduser,
      ...timedHSM[i],
    });
  }

  return (
    <div style={{ height: "110%", width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "scheduleDate", sort: "asc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
