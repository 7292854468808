import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";
import SmallModal from "../UI/Modals/Small";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "DataHoraEnvio",
    headerName: "Data e Hora",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-truncate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      );
    },
    flex: 0.7,
  },
  {
    field: "Usuario",
    headerName: "Usuario",
    headerAlign: "center",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate ">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        "-"
      );
    },
  },
  {
    field: "Telefone",
    headerName: "Telefone",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate ">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "NomeArquivo",
    headerName: "Nome do Arquivo",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate contentTableCell">
              {params.value}
            </span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "NomeTemplate",
    headerName: "Nome do template",
    flex: 1 / 2,
    align: "left",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate contentTableCell">
              {params.value}
            </span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Origem",
    headerName: "Origem",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        "-"
      );
    },
  },
  {
    field: "Nome",
    headerName: "Nome",
    align: "center",
    headerAlign: "center",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        "-"
      );
    },
  },

  {
    field: "StatusEnvio",
    headerName: "Status do Envio",
    flex: 1 / 2,
    align: "center",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },

  {
    field: "DetalheEnvio",
    headerName: "Detalhe do Envio",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        <div className="text-center">-</div>
      );
    },
  },
  // {
  //   field: "IdEnvio",
  //   headerName: "Id do envio",
  //   flex: 1 / 2,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <Tooltip title={params.value}>
  //           <span className="table-cell-truncate">{params.value}</span>
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: "IdLista",
  //   headerName: "Id da lista",
  //   flex: 1 / 3,
  //   renderCell: (params) => {
  //     return (
  //       <div>
  //         <Tooltip title={params.value}>
  //           <span className="table-cell-truncate contentTableCell">
  //             {params.value}
  //           </span>
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  // },

  {
    field: "Tentativas",
    headerName: "Tentativas",
    align: "center",
    flex: 1 / 4,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        "-"
      );
    },
  },
  {
    field: "CheckPoint",
    headerName: "Checkpoint",
    flex: 1 / 3,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <SmallModal
            iconButton={true}
            buttonString={<ListIcon style={{ color: "#1abc9c" }} />}
            boxName="CheckPoints"
            styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
          >
            <ul className="list-service-flow">
              {params.value.map((item, index) => (
                <li className="list-service-flow" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </SmallModal>
        </div>
      ) : (
        <div className="text-center">-</div>
      );
    },
  },
  {
    field: "Mensagem",
    headerName: "Mensagem",
    flex: 1 / 3,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <strong>
        <SmallModal
          iconButton={true}
          buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
          boxName="Mensagem"
          styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
        >
          <div className="msgAdjust">{params.value.replace(/\\n/g, "\n")}</div>
        </SmallModal>
      </strong>
    ),
  },
  // {
  //   field: "codes",
  //   headerName: "Códigos",
  //   flex: 1 / 4,
  //   headerAlign: "center",
  //   align: "center",
  //   valueFormatter: (value) => `${value.value.lista}`,
  //   renderCell: (params) => (
  //     <strong>
  //       <SmallModal
  //         iconButton={true}
  //         buttonString={<ViewListIcon style={{ color: "#1abc9c" }} />}
  //         boxName="Códigos (ID's)"
  //         styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
  //       >
  //         <div className="codeAlign">
  //           <div className="codeStyle">
  //             <label>ID do Broker</label>
  //             <input readOnly value={params.value.broker || ""} />
  //           </div>

  //           <div className="codeStyle">
  //             <label>ID do Envio</label>
  //             <input readOnly value={params.value.envio || ""} />
  //           </div>

  //           <div className="codeStyle">
  //             <label>ID da Lista</label>
  //             <input readOnly value={params.value.lista || ""} />
  //           </div>
  //         </div>
  //       </SmallModal>
  //     </strong>
  //   ),
  // },
];

function transformObjects(data) {
  let arr = [];
  for (let i in data) {
    arr.push({
      ...data[i],
      // codes: {
      //   broker: data[i]["IdBroker"],
      //   envio: data[i]["IdEnvio"],
      //   lista: data[i]["IdLista"],
      // },
    });
  }
  return arr;
}
function generateDynamicId(length = 15) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    id += characters.charAt(randomIndex);
  }

  return id;
}

export default function HSMListCRM(data) {
  const rows = transformObjects(data.data);
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          getRowId={(row) => {
            return generateDynamicId(26);
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
