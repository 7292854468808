import * as React from "react";
import { useState } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
  // GridToolbarQuickFilter
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import CustomizedDialogs from "./modalOpen";
import ChatIcon from '@mui/icons-material/Chat';
import CircularLoading from "../loadModules/circularLoading";
import { width } from "@mui/system";


export default function CdrLogTable({ retornoAPI, loading }) {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  // ====================================================================================================== //
  var rows = [];
  const columns = [
    {
      field: "DateTime",
      headerName: "Data",
      type: "dateTime",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <Tooltip title={hours(new Date(params.value))}>
            <span className="table-cell-trucate">{hours(new Date(params.value))}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "Phone",
      headerName: "Telefone",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NickName",
      headerName: "Participante",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Source",
      headerName: "Origem",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Message",
      headerName: "Mensagem",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <CustomizedDialogs
          modalSize={{ height: 40, width: 30 }}
          iconButton={true}
          buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
          boxName="Mensagem"
          styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
        >
          {params.value}
        </CustomizedDialogs>
      ),
    },
  ];

  // ====================================================================================================== //

  function hours(date) {
    var placeholder = new Date(date.getTime() + (date.getTimezoneOffset() * 60000)).toLocaleString()
    return placeholder
  }

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      <GridToolbarExport
        csvOptions={{ delimiter: ";" }}
        printOptions={{ disableToolbarButton: true }}
      />
      {/* <GridToolbarQuickFilter /> */}
    </GridToolbarContainer>
  );

  if (retornoAPI.length !== 0) {
    for (let i = 0; i < retornoAPI.length; i++) {
      // retornoAPI[i].DateTime = new Date(retornoAPI[i].DateTime)
      rows.push({ id: i + 1, ...retornoAPI[i] });
    }
  }

  return loading ? (
    <div style={{ height: "110%" }}>
      <DataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
      />
    </div>) : (<div style={{ width: '96%', height: '110%' }}><CircularLoading /></div>)
}
