// @ts-nocheck
import React from "react";
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";

const geoUrl =
  "https://raw.githubusercontent.com/codeforamerica/click_that_hood/master/public/data/brazil-states.geojson";

const statesWithAnotation = {
  DF: {
    annotation: { x: -10, y: -15 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  RN: {
    annotation: { x: 28, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  PB: {
    annotation: { x: 48, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  PE: {
    annotation: { x: 60, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  AL: {
    annotation: { x: 40, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  SE: {
    annotation: { x: 35, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  ES: {
    annotation: { x: 30, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
  RJ: {
    annotation: { x: 35, y: 0 },
    tag: { fontSize: 14, x: 4, y: 0 },
  },
};

export default function MapChart({ data }) {
  const renderGraph = () => {
    return (
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map((geo) => {
              return (
                <Geography
                  geography={geo}
                  fill="#8fb8d4"
                  stroke="#686868"
                  key={geo.rsmKey + "-Geography"}
                  style={{
                    default: { outline: "none" },
                    hover: {
                      outline: "none",
                      fill: "#3498db",
                      stroke: "#000",
                      transition: "1s",
                    },
                    pressed: { outline: "none" },
                  }}
                />
              );
            })}
            {geographies.map((geo) => {
              const centroid = geoCentroid(geo);
              const annotationOffset =
                statesWithAnotation[geo.properties.sigla];
              return (
                <g key={geo.rsmKey + "-marker"}>
                  {annotationOffset ? (
                    <Annotation
                      connectorProps={{
                        stroke: "rgb(0, 181, 253)",
                      }}
                      subject={centroid}
                      dx={annotationOffset.annotation.x}
                      dy={annotationOffset.annotation.y}
                    >
                      <text
                        x={2}
                        y={0}
                        textAnchor="middle"
                        fontSize={11}
                        fontFamily="Open Sans"
                        fontWeight={700}
                      >
                        {" "}
                        {geo.properties.sigla}
                        {data.map((d) => {
                          if (geo.properties.sigla == d.state) {
                            return " - " + d.value;
                          }
                        })}
                      </text>
                    </Annotation>
                  ) : (
                    <Marker coordinates={centroid}>
                      <text
                        fontSize={11}
                        fontFamily="Open Sans"
                        fontWeight={700}
                        x={2}
                        y={0}
                        textAnchor="middle"
                      >
                        {geo.properties.sigla}
                        {data.map((d) => {
                          if (geo.properties.sigla == d.state) {
                            return (
                              <tspan x="0" dy="1.2em" key={d.state}>
                                {d.value}
                              </tspan>
                            );
                          }
                        })}
                      </text>
                    </Marker>
                  )}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    );
  };
  return (
    <div
      style={{
        marginTop: "-160px",
      }}
    >
      <ComposableMap
        projectionConfig={{
          center: [-55, -10],
          scale: 750,
        }}
        width={1200}
        height={720}
        style={{ width: "100%", height: "100%" }}
        projection="geoMercator"
      >
        {renderGraph()}
      </ComposableMap>
    </div>
  );
}
