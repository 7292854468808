import hibernationStyle from "./hibernation.module.css";
import HibernationListTable from "../../components/tables/hibernationTable";
import RegisterHibernation from "./RegisterHibernation";

import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

import addCircle from "../../images/addCircle.svg";
import { toast } from "react-toastify";
import api from "../../services/api";
import LargeModal from "../../components/UI/Modals/Large";

export default function Hibernation() {
  const [hibernationAPI, setHibernationAPI] = useState({});

  useEffect(() => {
    async function apicall() {
      await api
        .get("api/espresso/hibernation/findAll", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Erro #004: Sem resultado de busca");
          }
          if (!!res.data.data) {
            setHibernationAPI(res.data.data);
          }
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }
    apicall();
  }, []);

  return (
    <div className={hibernationStyle.hibernation}>
      <div className={hibernationStyle.msgToolBar}>
        <div className="titlePage">Hibernação</div>
        <div className={hibernationStyle.rightToolbar}>
          <LargeModal
            buttonString="Incluir"
            boxName="Adicionar Fila Para Hibernação"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1.1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,

              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
          >
            {/* Adding Modal chamar aqui o arquivo novo*/}

            <RegisterHibernation />
          </LargeModal>
        </div>
      </div>
      <div className={hibernationStyle.slBottomBox}>
        <StyledEngineProvider injectFirst>
          <HibernationListTable hibernation={hibernationAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
