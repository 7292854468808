import messagesStyle from "./messages.module.css";
import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import api from "../../services/api";
import addCircle from "../../images/addCircle.svg";
import ModalCall from "../../components/tables/modalOpen";
import { editCell, config, PURECLOUD_URL } from "../../services/utils";
import VerticalTabs from "../scheduleCreate/others/week";

// ---- Logos ----//
import twilioLogo from "../../images/twilio.png";
import interaxaLogo from "../../images/interaxa.png";

//----- MUI Icons Library ----//
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { uploadMediatoBucket } from "../../services/utils";

function HsmSender({ codemessage }) {
  // -------------------------- States --------------------------- //
  const [mensagem, setMensagem] = useState({ body: {} });
  const [namespace, setNameSpace] = useState("");
  const [elementname, setElementName] = useState("");
  const [paramList, SetParamList] = useState([]);
  const [selected, setSelected] = useState("");
  const [ordem, setOrdem] = useState(1);
  const [upfile, setupfile] = useState([]);
  const { type, upload, broker, timezone } =
    config.application.modules.templates;
  const [attempts, setAttempts] = useState(1);
  const [intervalBetweenAttempts, setIntervalBetweenAttempts] = useState(30);
  const [search, setSearch] = useState(false);
  const [schedule, setSchedule] = useState({
    sunday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
    monday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
    tuesday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
    wednesday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
    thursday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
    friday: {
      startingHour: null,
      startingMinute: null,
      endingHour: null,
      endingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      closed: false,
    },
    saturday: {
      startingHour: null,
      startingMinute: null,
      labelStartingTime: null,
      labelEndingTime: null,
      endingHour: null,
      endingMinute: null,
      closed: false,
    },
  });
  const [genesysOptions, setGenesysOptions] = useState([]);
  // ------------------------------------------------------------ //
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
  const videoExtensions = [".mp4", ".webm", ".ogg"];
  const attemptsOptions = [1, 2, 3, 4, 5];
  const attemptsIntervalOptions = [
    5, 10, 15, 30, 45, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660,
    720,
  ];
  const timeZoneOptions = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Ciudad_Juarez",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port_of_Spain",
    "America/Port-au-Prince",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "CET",
    "CST6CDT",
    "EET",
    "EST",
    "EST5EDT",
    "Etc/GMT",
    "Etc/GMT+1",
    "Etc/GMT+10",
    "Etc/GMT+11",
    "Etc/GMT+12",
    "Etc/GMT+2",
    "Etc/GMT+3",
    "Etc/GMT+4",
    "Etc/GMT+5",
    "Etc/GMT+6",
    "Etc/GMT+7",
    "Etc/GMT+8",
    "Etc/GMT+9",
    "Etc/GMT-1",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/UTC",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kirov",
    "Europe/Kyiv",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zurich",
    "HST",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "MET",
    "MST",
    "MST7MDT",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kanton",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
    "PST8PDT",
    "UTC",
    "WET",
  ];

  const checkFileType = (url) => {
    const lowerCaseURL = url.toLowerCase();
    if (imageExtensions.some((ext) => lowerCaseURL.endsWith(ext))) {
      return "image";
    }
    if (videoExtensions.some((ext) => lowerCaseURL.endsWith(ext))) {
      return "video";
    }
    return null;
  };

  const callAPI = useCallback(async () => {
    if (codemessage) {
      const editValues = await editCell(codemessage);
      let qtd = 0;
      let parametros = [];
      for (let key in editValues.parametros) {
        parametros.push(editValues.parametros[key]);
      }
      // setColor(editValues.broker)
      SetParamList(parametros);
      // setNameSpace(editValues.namespace)
      // setElementName(editValues.elementname)
      if (editValues.attempts) {
        setAttempts(editValues.attempts);
      }
      if (editValues.intervalBetweenAttempts) {
        setIntervalBetweenAttempts(editValues.intervalBetweenAttempts);
      }
      setOrdem(parametros.length);
      await setMensagem({ body: editValues });
      setSearch(true);
      scheduleSet(editValues);
    }
  }, [codemessage]);

  useEffect(() => {
    getTimezone();
    if (localStorage.getItem("pcToken")) {
      getGenesysContactList();
    }
    if (!search) {
      callAPI();
    }
    return () => {
      setSearch(true);
    };
  }, [callAPI]);

  function getTimezone() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    handleChange("defaultTimeZone", timeZone);
  }

  async function getGenesysContactList() {
    let pageNumber = 1;
    let paginationSize = 1;
    let data = [];
    do {
      await api
        .get(`${PURECLOUD_URL}/outbound/contactlists`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
          },
        })
        .then((res) => {
          data = [...data, ...res.data.entities];
          pageNumber++;
          paginationSize = res.data.pageCount;
        });
    } while (pageNumber <= paginationSize);
    setGenesysOptions(data);
  }

  // ------------------------ Functions ------------------------- //

  function scheduleSet(value) {
    let updatedSchedule = { ...schedule }; // Faça uma cópia do objeto original
    let date = 0;

    for (const day in updatedSchedule) {
      if (
        value.allowedTime[date] &&
        value.allowedTime[date].timeStart &&
        value.allowedTime[date].timeEnd
      ) {
        const hourStart = value.allowedTime[date].timeStart.split(":");
        const hourEnd = value.allowedTime[date].timeEnd.split(":");
        updatedSchedule[day] = {
          ...updatedSchedule[day],
          labelStartingTime: value.allowedTime[date].timeStart,
          labelEndingTime: value.allowedTime[date].timeEnd,
          startingHour: hourStart[0],
          startingMinute: hourStart[1],
          endingHour: hourEnd[0],
          endingMinute: hourEnd[1],
          closed: value.allowedTime[date].closed,
        };
      } else {
        updatedSchedule[day] = {
          ...updatedSchedule[day],
          closed: value.allowedTime[date].closed,
        };
      }
      date++;
    }
    setSchedule(updatedSchedule);
  }

  function convertMinutesToHours(minutes) {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${remainingMinutes
        .toString()
        .padStart(2, "0")}`;
    } else {
      return `00:${minutes.toString().padStart(2, "0")}`;
    }
  }

  function reload() {
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  }

  const handleChange = (type, value) => {
    let _mensagem = { ...mensagem };
    _mensagem.body[type] = value;
    if (type == "attempts") {
      setAttempts(value);
    }
    if (type == "intervalBetweenAttempts") {
      setIntervalBetweenAttempts(value);
    }
    setMensagem({ ..._mensagem });
  };

  const handleSelect = (name) => {
    setSelected(name);
  };

  const handleParameter = async () => {
    if (selected) {
      let _paramList = [...paramList];
      _paramList.push(selected);
      SetParamList([..._paramList]);
      setOrdem(ordem + 1);
      await setSelected("");
    } else {
      toast.error("Insira um parâmetro!");
    }
  };

  const setParameterDown = (index) => {
    let _paramList = paramList;
    let first = _paramList[index];
    let next = _paramList[index + 1];

    _paramList[index] = next;
    _paramList[index + 1] = first;

    SetParamList([..._paramList]);
  };

  const setParameterUp = (index) => {
    let _paramList = paramList;
    let first = _paramList[index];
    let previous = _paramList[index - 1];

    _paramList[index] = previous;
    _paramList[index - 1] = first;

    SetParamList([..._paramList]);
  };

  const deleteParameter = (index) => {
    let _paramList = paramList;
    if (index > -1) {
      _paramList.splice(index, 1);
    }

    SetParamList([..._paramList]);
    setOrdem(ordem - 1);
  };

  function createAllowedTime() {
    let obj = {};
    let date = 0;
    for (const day in schedule) {
      if (
        !schedule[day].startingHour ||
        !schedule[day].startingMinute ||
        !schedule[day].endingHour ||
        !schedule[day].endingMinute
      ) {
        obj[date] = {
          timeStart: null,
          timeEnd: null,
          closed: schedule[day].closed,
        };
      } else {
        obj[date] = {
          timeStart: `${schedule[day].startingHour}:${schedule[day].startingMinute}`,
          timeEnd: `${schedule[day].endingHour}:${schedule[day].endingMinute}`,
          closed: schedule[day].closed,
        };
      }
      date++;
    }
    return { ...obj };
  }

  function verifySchedule() {
    for (const day in schedule) {
      if (schedule.hasOwnProperty(day)) {
        const { startingHour, startingMinute, endingHour, endingMinute } =
          schedule[day];

        const isStartValid = startingHour !== null && startingMinute !== null;
        const isEndValid = endingHour !== null && endingMinute !== null;

        if ((isStartValid && isEndValid) || (!isStartValid && !isEndValid)) {
          continue;
        } else {
          return false;
        }
      }
    }

    return true;
  }

  const handleSubmit = async (event) => {
    event.persist();
    event.preventDefault();

    if (attempts == 1 || intervalBetweenAttempts == 30) {
      let ev = { ...mensagem };
      ev.body.attempts = attempts;
      ev.body.intervalBetweenAttempts = intervalBetweenAttempts;
      setMensagem(ev);
    }
    if (!verifySchedule()) {
      toast.error("Por favor preencha os campos de schedule corretamente!");
      return;
    }

    let _ev = { ...mensagem };
    _ev.body.allowedTime = await createAllowedTime();
    await setMensagem(_ev);

    mensagem.body.parametros = {};

    if (!type) {
      mensagem.body.type = "HSM";
    }

    for (let index = 0; index < paramList.length; index++) {
      mensagem.body.parametros[`{${index + 1}}`] = paramList[index];
    }

    if (upfile.length > 0) {
      const mediaURL = await uploadMediatoBucket(upfile[0]);
      mensagem.body.mediaURL = mediaURL.Location;
    }

    if (config.fakeConfig.defaultBroker !== "disable") {
      mensagem.body.broker = config.fakeConfig.defaultBroker;
    }
    if (!codemessage) {
      await api
        .post("api/espresso/mensagens", mensagem.body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          toast.success("Cadastrado, atualizando lista");
          reload();
        })
        .catch((err) =>
          toast.error("Erro #005: Erro Fatal! Acionar o suporte")
        );
    } else {
      api
        .put(`api/espresso/mensagens/${codemessage}`, mensagem.body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          toast.success("Editado, atualizando lista");
          reload();
        })
        .catch((err) =>
          toast.error("Erro #005: Erro Fatal! Acionar o suporte")
        );
    }
  };

  const handleMediaHSM = (filetoawait, reject) => {
    if (reject.length) {
      return toast.error("Arquivo não suportado!");
    }
    if (filetoawait.length > 1) {
      return toast.error("Selecione apenas um arquivo!");
    }
    setupfile(filetoawait);
    toast.success("Arquivo carregado!");
  };

  const cleanFile = () => {
    setupfile([]);
    let _mensagem = { ...mensagem };
    _mensagem.body.mediaURL = "";
    setMensagem({ ..._mensagem });
    toast.success("Arquivo deletado");
  };

  // ------------------------------------------------------------------ //

  return (
    <div className="modalCall ps-4 pe-4">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={`${messagesStyle.containerInput} mb-3`}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Código da Mensagem:</label>
          </div>
          <input
            className="input-bootstrap"
            type="text"
            required
            onChange={(e) => handleChange("codemessage", e.target.value)}
            value={codemessage}
            placeholder="ra_rs_Cx"
          />
        </div>

        <div className={`${messagesStyle.containerInput} mb-3`}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Namespace:</label>
          </div>
          <input
            className="input-bootstrap"
            type="text"
            required
            onChange={(e) => handleChange("namespace", e.target.value)}
            value={mensagem.body.namespace}
            placeholder="Nome da operação"
          />
        </div>

        <div className={`${messagesStyle.containerInput} mb-3`}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Element Name:</label>
          </div>
          <input
            className="input-bootstrap"
            type="text"
            required
            onChange={(e) => handleChange("elementname", e.target.value)}
            value={mensagem.body.elementname}
            placeholder="ra_ex_nps"
          />
        </div>

        {config.fakeConfig.defaultBroker !== "disable" ? null : (
          <div className={`mb-3`}>
            <div className="containerLabel mb-2">
              <label className="dafaultLabel">Broker:</label>
            </div>
            <select
              className="select-bootstrap"
              required
              value={mensagem.body.broker}
              onChange={(e) => {
                handleChange("broker", e.target.value);
              }}
            >
              <option key="default" defaultValue="" hidden>
                {" "}
                Selecione um broker ...{" "}
              </option>
              {config.fakeConfig.enabled.map((item, idx) => (
                <option value={item} key={idx}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* {true ? (
      <div className="titleCentering"> 
        Broker
        <div className="brokerChoose">
          <label>
            <div
              className={`brokerBox${mensagem.body.broker === "twilio"}`}
              htmlFor="broker"
            >
              <img src={twilioLogo} alt="twilio" />
              <input
                type="radio"
                name="broker"
                checked={mensagem.broker === "twilio"}
                onChange={(e) => handleChange("broker", "twilio")}
              />
            </div>
          </label>
          <label>
            <div
              className={`brokerBox${mensagem.body.broker === "interaxa"}`}
              htmlFor="broker"
            >
              <img src={interaxaLogo} alt="interaxa" />
              <input
                type="radio"
                name="broker"
                checked={mensagem.broker === "interaxa"}
                onChange={(e) => handleChange("broker", "interaxa")}
              />
            </div>
          </label>
        </div>
      </div>
        ) : null } */}

        {type ? (
          <div className="mb-4">
            <div className="labelContainer mb-2">
              <label className="defaultLabel">Tipo</label>
            </div>
            <select
              value={mensagem.body.type}
              onChange={(e) => {
                handleChange("type", e.target.value);
              }}
              className="select-bootstrap"
            >
              <option value={""}> Selecione</option>
              <option value={"HSM"}>HSM</option>
              <option value={"SMS"}>SMS</option>
            </select>
          </div>
        ) : // <div className={messagesStyle.titleCentering}>
        //   Tipo
        //   <div className={messagesStyle.typeChoose}>
        //     <label>
        //       <div
        //         className={`typeBox${mensagem.body.type === "HSM"}`}
        //         htmlFor="type"
        //       >
        //         <strong className={messagesStyle.typeMessage}> HSM </strong>
        //         <input
        //           type="radio"
        //           name="type"
        //           onChange={(e) => handleChange("type", "HSM")}
        //         />
        //       </div>
        //     </label>
        //     <label>
        //       <div
        //         className={`${messagesStyle.typeBox}${
        //           mensagem.body.type === "SMS"
        //         }`}
        //         htmlFor="type"
        //       >
        //         <strong className={messagesStyle.typeMessage}> SMS </strong>
        //         <input
        //           type="radio"
        //           name="type"
        //           onChange={(e) => handleChange("type", "SMS")}
        //         />
        //       </div>
        //     </label>
        //   </div>
        // </div>
        null}

        <div className={`${messagesStyle.containerInput} mb-3`}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel" htmlFor="bodymessage">
              Corpo Da Mensagem
            </label>
          </div>
          <textarea
            id="bodymessage"
            className="text-area-bootstrap w-100"
            required
            rows={4}
            onChange={(e) => handleChange("body", e.target.value)}
            value={mensagem.body.body}
          />
        </div>

        <div className={`${messagesStyle.containerInput} mb-3`}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Parâmetros:</label>
          </div>
          <div className="d-flex flex-row">
            <input
              className="input-bootstrap"
              value={selected}
              onChange={(e) => handleSelect(e.target.value.toLowerCase())}
            />
            <strong
              className={messagesStyle.paramAddBtn}
              onClick={handleParameter}
            >
              <AddCircleRoundedIcon style={{ color: "white" }} />
            </strong>
          </div>
        </div>

        {paramList.length > 0 ? (
          <div className={messagesStyle.paramTable}>
            <p>Parâmetros selecionados</p>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h7">Ordem</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h7">Parâmetro</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h7">Alterações</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paramList.map((value, idx) => (
                  <TableRow key={idx}>
                    <TableCell align="center">{idx + 1}</TableCell>
                    <TableCell align="center">{value}</TableCell>
                    <TableCell align="center">
                      <div className={messagesStyle.paramAlign}>
                        {idx === 0 ? (
                          <div className={messagesStyle.btnDisable}>
                            <ArrowUpwardIcon style={{ color: "white" }} />
                          </div>
                        ) : (
                          <div
                            className={messagesStyle.btnEnable}
                            onClick={() => setParameterUp(idx)}
                          >
                            <ArrowUpwardIcon style={{ color: "white" }} />
                          </div>
                        )}

                        {idx === paramList.length - 1 ? (
                          <div className={messagesStyle.btnDisable}>
                            <ArrowDownwardIcon style={{ color: "white" }} />
                          </div>
                        ) : (
                          <div
                            className={messagesStyle.btnEnable}
                            onClick={() => setParameterDown(idx)}
                          >
                            <ArrowDownwardIcon style={{ color: "white" }} />
                          </div>
                        )}
                        <div
                          className={messagesStyle.btnDel}
                          onClick={() => deleteParameter(idx)}
                        >
                          <DeleteIcon style={{ color: "white" }} />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}

        {upload ? (
          <div className={messagesStyle.titleCentering}>
            Upload de Midia
            {mensagem?.body?.mediaURL ? (
              <div className={messagesStyle.mediaContainer}>
                {checkFileType(mensagem.body.mediaURL) == "video" ? (
                  <video
                    width={250}
                    height="auto"
                    src={mensagem.body.mediaURL}
                    controls
                    crossOrigin="anonymous"
                  ></video>
                ) : (
                  <img
                    src={mensagem.body.mediaURL}
                    alt="media"
                    width={250}
                    height="auto"
                    crossOrigin="anonymous"
                  />
                )}
              </div>
            ) : null}
            <div className={messagesStyle.midiaZone}>
              <div className={messagesStyle.inputMidiaAlign}>
                <ModalCall
                  modalSize={{ height: 40, width: 30 }}
                  buttonString={"Midia"}
                  boxName="Adicionar Midia"
                  icon={addCircle}
                  className={`${messagesStyle.send}`}
                  styleButton={{
                    bgcolor: "#007bff",
                    color: "white",
                    padding: "0.7vh",
                    mt: "3vh",
                    ml: "24px",
                    borderRadius: 1,
                    fontSize: "1vw",
                    ":hover": { bgcolor: "#005bff" },
                  }}
                >
                  <div className={messagesStyle.midia__upload}>
                    <h3 className={messagesStyle.inner__title}>
                      Envio de arquivos
                    </h3>
                    <div className={messagesStyle.midia__upload__wrapper}>
                      <Dropzone
                        min-height="0px"
                        accept="image/*, video/*"
                        onDrop={(acceptedFiles, rejectedFiles, event) => {
                          handleMediaHSM(acceptedFiles, rejectedFiles);
                        }}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragReject,
                        }) => (
                          <div
                            className={
                              messagesStyle.midia__upload__message__dropzone
                            }
                            {...getRootProps()}
                            isdragactive={isDragActive.toString()}
                            isdragreject={isDragReject.toString()}
                          >
                            <input {...getInputProps()} />
                            {isDragActive && !isDragReject && (
                              <p>Solte aqui!</p>
                            )}
                            {isDragActive && isDragReject && (
                              <p>Arquivo(s) não suportado(s)!</p>
                            )}
                            {!isDragActive && !isDragReject && (
                              <p>Arraste os arquivos ou clique aqui</p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </ModalCall>

                <input
                  className="uploadInput"
                  value={
                    upfile[0]?.name
                      ? upfile[0]?.name
                      : mensagem.body.mediaURL
                      ? mensagem.body.mediaURL.split("/")[
                          mensagem.body.mediaURL.split("/").length - 1
                        ]
                      : ""
                  }
                  disabled
                  hidden
                />
                <strong
                  className="cleanBtn"
                  onClick={() => {
                    cleanFile();
                  }}
                >
                  <DeleteIcon style={{ color: "white" }} />
                </strong>
              </div>
            </div>
          </div>
        ) : null}

        <>
          <div className={` mb-3`}>
            <div className="containerLabel mb-2">
              <label className="defaultLabel" htmlFor="tentativas">
                Número de Tentativas
              </label>
            </div>
            <select
              onChange={(e) => handleChange("attempts", e.target.value)}
              value={attempts}
              className="select-bootstrap"
              id="tentativas"
            >
              {attemptsOptions.map((c, index) => (
                <option key={`attempts-${index}`} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
          <div className={` mb-3`}>
            <div className="containerLabel mb-2">
              <label className="defaultLabel" htmlFor="interval">
                Intervalo entre tentativas
              </label>
            </div>
            <select
              onChange={(e) =>
                handleChange("intervalBetweenAttempts", e.target.value)
              }
              value={intervalBetweenAttempts}
              className="select-bootstrap"
              id="tentativas"
            >
              {attemptsIntervalOptions.map((c, index) => (
                <option value={c} key={`interval-${index}`}>
                  {convertMinutesToHours(c)}
                </option>
              ))}
            </select>
          </div>
        </>

        <>
          <div className={`${messagesStyle.containerTextArea} mb-3`}>
            <div className="containerLabel mb-1">
              <label className="defaultLabel">Mensagem de despedida</label>
            </div>
            <input
              type="text"
              className="input-bootstrap w-100"
              onChange={(e) =>
                handleChange("farewellCodemessage", e.target.value)
              }
              value={mensagem.body.farewellCodemessage}
            />
          </div>

          {timezone ? (
            <>
              <div className={` mb-3`}>
                <div className="containerLabel mb-2">
                  <label htmlFor="contactList" className="defaultLabel pe-1">
                    Lista de Contatos
                  </label>
                  (Genesys Cloud)
                </div>
                {genesysOptions.length ? (
                  <select
                    className="select-bootstrap"
                    value={mensagem.body.farewellContactListName}
                    onChange={(e) =>
                      handleChange("farewellContactListName", e.target.value)
                    }
                  >
                    <option value={""}>Selecione</option>
                    {genesysOptions.map((c) => (
                      <option key={c.id} value={c.name}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className={`${messagesStyle.containerInput}`}>
                    <input
                      type="text"
                      className="input-bootstrap w-100"
                      onChange={(e) =>
                        handleChange("farewellContactListName", e.target.value)
                      }
                      value={mensagem.body.farewellContactListName}
                    />
                  </div>
                )}
              </div>

              <div className={`mb-3`}>
                <div className="containerLabel mb-2">
                  <label
                    htmlFor="defaultTimeZone"
                    className="defaultLabel pe-1"
                  >
                    Time Zone
                  </label>
                  (Genesys Cloud)
                </div>
                <select
                  className="select-bootstrap w-100"
                  name="defaultTimeZone"
                  id="defaultTimeZone"
                  onChange={(e) =>
                    handleChange("defaultTimeZone", e.target.value)
                  }
                  value={mensagem.body.defaultTimeZone}
                >
                  <option value={""}>Selecione</option>
                  {timeZoneOptions.map((c) => {
                    return (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className={`${messagesStyle.containerInput} mb-3`}>
                <div className="containerLabel mb-1">
                  <label htmlFor="timeZoneField " className="defaultLabel">
                    Nome da coluna
                  </label>
                </div>
                <input
                  className="input-bootstrap"
                  type="text"
                  value={mensagem.body.timeZoneField}
                  onChange={(e) =>
                    handleChange("timeZoneField", e.target.value)
                  }
                />
              </div>
              <div className={` ${messagesStyle.containerTextArea} mb-3`}>
                <div className="containerLabel mb-2">
                  <label htmlFor="agentMessage" className="defaultLabel">
                    Corpo da mensagem (body)
                  </label>
                </div>
                <textarea
                  className="text-area-bootstrap w-100"
                  id="agentMessage"
                  rows={4}
                  onChange={(e) =>
                    handleChange("farewellAgentMessage", e.target.value)
                  }
                  value={mensagem.body.farewellAgentMessage}
                />
              </div>
            </>
          ) : null}

          <div className={`${messagesStyle.containerVertical} mb-4`}>
            <VerticalTabs schedule={schedule} setSchedule={setSchedule} />
          </div>

          {/* <div className="d-flex justify-content-between pe-2">
            <div className={`${messagesStyle.containerInput}  mb-3`}>
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Tempo de inicio</label>
              </div>
              <input
                type="time"
                placeholder="00:00"
                className="input-bootstrap"
                value={mensagem.body.allowedTimeStart}
                onChange={(e) =>
                  handleChange("allowedTimeStart", e.target.value)
                }
              />
            </div>

            <div className={`${messagesStyle.containerInput} mb-4`}>
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Tempo do fim</label>
              </div>
              <input
                type="time"
                className="input-bootstrap"
                value={mensagem.body.allowedTimeEnd}
                onChange={(e) => handleChange("allowedTimeEnd", e.target.value)}
              />
            </div>
          </div> */}
        </>

        <div className="d-flex  flex-row justify-content-center mb-3">
          <button className="btn-primary btn containerBtn-4" type="submit">
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}
export default React.memo(HsmSender);
