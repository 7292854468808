import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import dashStyle from "../dashboardHibernation.module.css";
import api from "../../../services/api";
import UniqueNumber from "../../../components/tables/hibernationVia/uniqueNumber";
import CircularLoading from "../../../components/loadModules/circularLoading";

export default function DashboardUniqueNumberGeneral() {
  const [params, setParams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uniqueNumber, setUniqueNumber] = useState([]);

  useEffect(() => {
    if (params.length) {
      callApi();
    }
  }, [params]);

  async function callApi() {
    setLoading(true);
    await api
      .get(
        `/api/database/report/dashUniqueNumber?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          const formatted = formatValue(res.data[0]);
          setUniqueNumber(formatted);
          toast.success("Busca realizada com sucesso!");
        } else {
          toast.warning("Não há dados para essa data.");
          setUniqueNumber([]);
        }
      })
      .catch(() => {
        toast.error("Houve um erro ao consultar o banco de dados!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleChange(value) {
    let _params = value.split("-");
    setParams(_params);
    if (_params[0] < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    }
  }

  function formatValue(value) {
    //mais hardcode impossivel
    const total =
      value.DISCONNECTED + value.RETURNED_AGENT + value.RETURNED_QUEUE;
    const generalNumberTotal = {
      id: 1,
      status: "Nº Único Geral",
      returned_agent: total,
    };
    const uniqueNumberStatus = {
      id: 2,
      status: "Nº Único por Status",
      disconnected: value.DISCONNECTED,
      returned_agent: value.RETURNED_AGENT,
      returned_queue: value.RETURNED_QUEUE,
    };
    const statusPercent = {
      id: 3,
      status: "% do Geral",
      disconnected: `${((value.DISCONNECTED / total) * 100).toFixed(2)}%`,
      returned_agent: `${((value.RETURNED_AGENT / total) * 100).toFixed(2)}%`,
      returned_queue: `${((value.RETURNED_QUEUE / total) * 100).toFixed(2)}%`,
    };
    const data = [generalNumberTotal, uniqueNumberStatus, statusPercent];
    return data;
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between me-5">
        <div className="titlePage">Hibernação - Números únicos</div>
        <div className={dashStyle.containerMonth}>
          <input
            type="month"
            onChange={(e) => handleChange(e.target.value)}
            className="input-bootstrap"
          />
        </div>
      </div>
      {!loading ? (
        <div
          style={{ height: "60vh" }}
          className="d-flex algin-items-center pt-5 mt-4"
        >
          <UniqueNumber data={uniqueNumber} />
        </div>
      ) : (
        <div style={{ height: "90vh" }}>
          <CircularLoading />
        </div>
      )}
    </div>
  );
}
