import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  Typography,
  Hidden,
} from "@mui/material";
import { useState } from "react";
import "./preview.css";

export default function TablePreview({ mailing, page, setPage }) {
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const tableCellStyle = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    fontSize: "12px",
    padding: "4px",
  };

  // const tableHeaderStyle = {
  //   padding: "0",
  //   fontSize: "12px",
  //   lineHeight: "1rem",
  //   widt: "50px",
  //   color: "#fff",
  // };

  return (
    <div className="tables-wrapper" style={{ "background-color": "white" }}>
      {!!mailing.contacts && mailing.contacts.length > 0 ? (
        <div>
          <TableContainer style={{ height: "15.5rem" }}>
            <Table className="tableContainer" aria-label="simple table">
              <TableHead sx={{ background: "#218da8", height: "40px" }}>
                <TableRow>
                  {mailing.columns.map((key, index) => (
                    <TableCell
                      key={key}
                      style={{
                        width: `${
                          (1 / Object.keys(mailing.contacts[0]).length) * 100
                        }%`,
                        "white-space": "pre-wrap",
                        "word-wrap": "break-word",
                        color: "white",
                        padding: 0,
                        "font-size": "12px",
                      }}
                      align="center"
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        {key.toUpperCase()}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {mailing.contacts
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((row, idxR) => (
                    <TableRow key={idxR}>
                      {mailing.columns.map((collumn, idx) => {
                        if (collumn !== "status")
                          return (
                            <TableCell style={tableCellStyle} align="center">
                              <input
                                disabled
                                value={row.mailData[collumn]}
                                name={collumn}
                              />{" "}
                            </TableCell>
                          );
                        else
                          return (
                            <TableCell style={tableCellStyle} align="center">
                              {!!row.mailData[collumn] &&
                              typeof row.mailData[collumn]
                                .toLocaleDateString === "function"
                                ? row.mailData[collumn].toLocaleDateString()
                                : "" + row.mailData[collumn]}
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              height: "40px",
              overflow: "hidden",
              background: "#218da8",
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                color: "white",
              },
              ".MuiTablePagination-displayedRows": {
                color: "white",
              },
              ".MuiSelect-select, .MuiTablePagination-select": {
                color: "white",
              },
              ".MuiSvgIcon-root, .MuiSvgIcon-fontSizeMedium": {
                color: "white",
              },
            }}
            labelRowsPerPage="Linhas por página"
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            component="div"
            count={mailing.contacts.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
}
