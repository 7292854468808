import React, { useEffect, useState } from "react";
import dashStyles from "./dashEnvios.module.css";
import PieChart from "../../components/charts/pieChartSend.js";
import AreaChart from "../../components/charts/areaChart.js";

import api from "../../services/api";

/* --------------- dash imgs -------------------  */
import SendIcon from "@mui/icons-material/Send";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InboxIcon from "@mui/icons-material/Inbox";
import ReplyIcon from "@mui/icons-material/Reply";
import TimelineIcon from "@mui/icons-material/Timeline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
/* ---------------------------------------------- */

export default function Dashboard() {
  const [weeklyGraph, setWeeklyGraph] = useState();
  const [status, setStatus] = useState({
    respondida: 0,
    sent: 0,
    delivered: 0,
    read: 0,
    error: 0,
  });

  const objData = {
    typeStatus: [
      "respondida", // Respondidas
      "sent", //Enviadas
      "delivered", // Entregues
      "read", //visualizados
      "error", // Falha
    ],
  };

  const [exec, setExec] = useState(true);

  useEffect(() => {
    async function loadData() {
      let qtdStatus = [];
      const res = await api.get("api/espresso/contacts", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      for (let i = 0; i < objData.typeStatus.length; i++) {
        qtdStatus = {
          ...qtdStatus,
          ...{
            [objData.typeStatus[i]]: res.data.filter(
              (data) => data.status === objData.typeStatus[i]
            ).length,
          },
        };
      }
      setStatus(qtdStatus);
    }

    setTimeout(() => {
      setExec(!exec);
    }, 12000);

    loadData();
  }, [exec]);

  useEffect(() => {
    async function loadData() {
      let qtdStatus = [];
      const to_date = new Date();
      const from_date = new Date(to_date.getTime() - 7 * 24 * 60 * 60 * 1000);

      const res = await api.post(
        "api/espresso/contacts",
        { to_date, from_date },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      function daySeparator(data) {
        const groupByCategory = data.reduce((group, info, index) => {
          const createdate = info.createdate.split("T")[0];
          group[createdate] = group[createdate] ?? [];
          group[createdate].push(info);
          return group;
        }, {});

        let week = [];
        for (let key in groupByCategory) {
          for (let i = 0; i < objData.typeStatus.length; i++) {
            qtdStatus = {
              ...qtdStatus,
              ...{
                [objData.typeStatus[i]]: groupByCategory[key].filter(
                  (data) => data.status === objData.typeStatus[i]
                ).length,
              },
            };
          }
          let sum =
            qtdStatus.respondida +
            qtdStatus.sent +
            qtdStatus.delivered +
            qtdStatus.read;
          week.push({ y: sum, x: key });
        }
        setWeeklyGraph(week);
      }
      daySeparator(res.data);
    }
    setTimeout(() => {
      setExec(!exec);
    }, 12000);
    loadData();
  }, []);

  return (
    <div className={dashStyles.dashSend}>
      <div className={`titlePage`}>Dashboard</div>
      <div className={dashStyles.messageStatus}>
        <div className={dashStyles.sendBox}>
          <div className="d-flex justify-content-center align-items-center w-100">
            <InboxIcon className={`${dashStyles.statusBoxIcons} me-2`} />
            <p className={dashStyles.status}>Total de Envios</p>
          </div>
          <div className={`w-100`}>
            <p className={dashStyles.quantity}>
              {status.read + status.delivered + status.sent || 0}
            </p>
          </div>
        </div>
        <div className={dashStyles.sendBox}>
          <div className="d-flex justify-content-center align-items-center w-100">
            <SendIcon className={`${dashStyles.statusBoxIcons} me-3`} />
            <p className={dashStyles.titleCard}>Recebidos</p>
          </div>
          <div className="w-100">
            <p className={dashStyles.quantity}>{status.delivered || 0}</p>
          </div>
        </div>
        <div className={dashStyles.sendBox}>
          <div className="d-flex justify-content-center align-items-center w-100">
            <DoneAllIcon className={`${dashStyles.statusBoxIcons} me-3`} />
            <p className={dashStyles.status}>Visualizadas</p>
          </div>
          <div className={`w-100`}>
            <p className={dashStyles.quantity}>{status.read || 0}</p>
          </div>
        </div>
        <div className={dashStyles.sendBox}>
          <div className="d-flex justify-content-center align-items-center w-100">
            <ReplyIcon className={`${dashStyles.statusBoxIcons} me-2`} />
            <p className={dashStyles.status}>Total de Respostas</p>
          </div>
          <div className={`w-100`}>
            <p className={dashStyles.quantity}>{status.respondida || 0}</p>
          </div>
        </div>
      </div>
      <div className={dashStyles.sendWidgetMiddleBoxes}>
        <div className={dashStyles.sendMiddleBoxOne}>
          <div className={dashStyles.graphicTitleSend}>
            <TimelineIcon className="me-2" style={{ color: "white" }} />
            <p className={dashStyles.titleGraphic}>Envios da Semana</p>
          </div>
          <AreaChart
            series={[
              {
                data: weeklyGraph,
              },
            ]}
          />
        </div>
        <div className={dashStyles.sendMiddleBoxTwo}>
          <div className={dashStyles.graphicTitleSend}>
            <DataSaverOffIcon className="me-2" style={{ color: "white" }} />
            <p className={dashStyles.titleGraphic}>Status dos Envios</p>
          </div>
          <PieChart
            series={[
              status.delivered,
              status.read,
              status.respondida,
              status.error,
            ]}
          />
        </div>
      </div>
    </div>
  );
}
