import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ReactPlayer from "react-player";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

function midiaFormat(value) {
  const images = [".jpeg", ".gif", ".png", ".bmp", ".jpg", "webp"];
  const video = [".amr", ".mpeg", ".mp4"];
  const audio = [
    ".basic",
    ".L24",
    ".ogg",
    ".vorbis",
    ".vnd",
    ".wave",
    ".3gpp",
    ".3gpp2",
    ".ac3",
    ".webm",
    ".audio",
    ".audio",
    ".mp3",
    ".mpga",
    ".voice",
    ".oga",
    ".opus",
    ".wav",
  ];
  const arquivo = [
    ".doc",
    ".docx",
    ".pdf",
    ".xlsx",
    ".xls",
    ".csv",
    ".txt",
    ".pptx",
    ".ppt",
  ];

  for (let i = 0; i < images.length; i++) {
    if (value.includes(images[i])) {
      return (
        <div className="autoResize">
          <a href={value} target="_blank">
            <img src={value} alt="" />
          </a>
        </div>
      );
    }
  }
  for (let i = 0; i < video.length; i++) {
    if (value.includes(video[i])) {
      return <ReactPlayer url={value} controls width="30vw" height="30vh" />;
    }
  }
  for (let i = 0; i < audio.length; i++) {
    if (value.includes(audio[i])) {
      return (
        <div>
          <ReactPlayer url={value} controls width="30vw" height="6vh" />
        </div>
      );
    }
  }
  for (let i = 0; i < arquivo.length; i++) {
    if (value.includes(arquivo[i])) {
      return (
        <span className="playBtnInner">
          <PlayCircleFilledWhiteIcon />
          <a href={value} target="_blank">
            Abrir Arquivo
          </a>
        </span>
      );
    }
  }
  return <pre style={{ whiteSpace: "pre-line" }}>{value}</pre>;
}

const columns = [
  {
    field: "data",
    headerName: "Data",
    type: "dateTime",
    flex: 2,
    renderCell: (params) => (
      <div>
        <Tooltip title={new Date(params.value).toLocaleString()}>
          <span className="table-cell-trucate">
            {new Date(params.value).toLocaleString()}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "texto",
    headerName: "Mensagem",
    flex: 9,
    align: "left",
    headerAlign: "center",
    renderCell: (params) => (
      <div className="brokerStyle">{midiaFormat(params.value)}</div>
    ),
  },
  { field: "origem", headerName: "Origem", flex: 2 },
  {
    field: "tipoMedia",
    headerName: "Formato",
    align: "center",
    headerAlign: "center",
    flex: 1,
  },
  { field: "_id", headerName: "ID", flex: 1, headerAlign: "center" },
];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
  </GridToolbarContainer>
);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

export default function historyTable({ history }) {
  var rows = [];

  for (let i = 0; i < history.length; i++) {
    history[i].date = new Date(history[i].date)
    rows.push({ id: i + 1, ...history[i] });
  }

  return (
    <div style={{ display: "flex", height: "110%", width: "98%" }}>
      <StyledDataGrid
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows.reverse()}
        columns={columns}
        disableVirtualization
      />
    </div>
  );
}
