import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import CircularLoading from "../loadModules/circularLoading";

const columns = [
  {
    field: "OrigemBolsao",
    headerName: "Origem",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  // {
  //   field: "Tabulacao",
  //   headerName: "Tabulação",
  //   flex: 1,
  //   align: "left",
  //   headerAlign: "center",
  // },
  {
    field: "Telefone",
    headerName: "Telefone",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "FilaOrigem",
    headerName: "Fila Origem",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "DtEntrada",
    headerName: "Data de Entrada",
    flex: 1,
    align: "center",
    type: "dateTime",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        <Tooltip title={new Date(params.value).toLocaleString()}>
          <span className="table-cell-trucate">
            {new Date(params.value).toLocaleString()}
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    field: "DtNotificado",
    headerName: "Data da Notificação",
    type: "dateTime",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Notificado",
    headerName: "Notificado",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        {params.value ? (
          <CheckCircleIcon sx={{ color: "#1ABC9C" }} />
        ) : (
          <RemoveCircleIcon sx={{ color: "#E74C3C" }} />
        )}
      </div>
    ),
  },
];

export default function messagesListTable({ tableInfo, loaded }) {
  var rows = [];

  if (tableInfo.length !== 0) {
    for (let i = 0; i < tableInfo.length; i++) {
      rows.push({ id: i + 1, ...tableInfo[i] });
    }
  }

  return tableInfo.length === 0 && loaded === false ? (<CircularLoading/>) : (
    <div style={{ height: "100%", width: "106%" }}>
      <DataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        hideFooter={true}
      />
    </div>
  );
}
