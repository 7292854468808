import React, { useEffect, useState } from "react";
import CardMonitor from "../../components/monitoring/CardMonitor";
import monitorStyle from "./monitor.module.css";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function Monitoring() {
  const [message, setMessage] = useState("inativo");
  const [hibernation, setHibernation] = useState("inativo");
  const [data, setData] = useState([
    {
      name: "Banco de dados",
      status: "ativo",
    },
    {
      name: "Mensagens",
      status: "ativo",
    },
    {
      name: "Hibernação",
      status: "ativo",
    },
  ]);
  async function getData() {
    try {
      const response1 = api.get("api/database/report/hibernationWorking", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const response2 = api.get("api/database/report/messageWorking", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const [result1, result2] = await Promise.all([response1, response2]);
      const message = result2.data[0].status.toLowerCase();
      const hibernation = result1.data[0].status.toLowerCase();
      setMessage(message);
      setHibernation(hibernation);
      setData([
        {
          name: "Banco de dados",
          status: "ativo",
        },
        {
          name: "Mensagens",
          status: message,
        },
        {
          name: "Hibernação",
          status: hibernation,
        },
      ]);
      toast.success("Dados Atualizados com sucesso!");
    } catch (e) {
      toast.error("Erro ao se conectar com servidor!");
      console.log(e);
    }
  }
  useEffect(() => {
    getData();
    const interval = setInterval(getData, 40000);

    return () => {
      clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
    };
  }, []);

  return (
    <div className="container">
      <div className={`${monitorStyle.monitorTitle} w-100`}>
        <h1 className={monitorStyle.titleMonitor}>
          Monitores de Banco de Dados, Mensagem e Hibernação:
        </h1>
      </div>
      <div className={`${monitorStyle.monitorContainer} w-100`}>
        {data.map((c, index) => (
          <CardMonitor key={index} data={c} index={index} />
        ))}
      </div>
    </div>
  );
}
