import {config} from  "../utils.js"

export const schedulesMenus = [
    {
        key:"schedules",
        label:"Schedules",
        route:"/app/schedule"
    },
    {
        key:"holidays",
        label:"Feriados",
        route:"/app/holidays"
    },
    {
        key:"association",
        label:"Associação",
        route:"/app/association"
    }
];


export const hasSchedulesItems =  schedulesMenus.some((item)=> config.application.menus?.[item.key]);