import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { config } from "../../services/utils";
import blackListStyle from "./blacklist.module.css";

export default function RegisterNumber() {
  // -------------------------- States --------------------------- //
  const [blackListingNumber, setblackListingNumber] = useState({ body: {} });
  const [phone, setPhone] = useState(false);

  // ------------------------------------------------------------ //

  // ------------------------ Functions ------------------------- //

  const handleChange = (type, value) => {
    let _mensagem = { ...blackListingNumber };
    _mensagem.body[type] = value;
    setblackListingNumber({ ..._mensagem });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    await api
      .post("api/espresso/blacklist/", blackListingNumber.body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        toast.success(
          `Número ${blackListingNumber.body.phone} adicionado na blacklist`
        );

        setTimeout(function () {
          window.location.reload();
        }, 4000);
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  };

  const phoneVerificationPattern = (value, qtd) => {
    const regex = qtd
      ? new RegExp("[+]?[0-9]{2}?[0-9]{2}(9{1})?[0-9]{8}")
      : new RegExp("[+]?[0-9]$");
    return regex.test(value);
  };

  const phoneVerify = (event) => {
    let value = event.target.value;
    let _client = { ...blackListingNumber };
    _client.body.phone = value;
    const isTrueVal =
      !value || !value == "+" || phoneVerificationPattern(value, true);
    setPhone(isTrueVal);
    if (!value || value == "+" || phoneVerificationPattern(value, false))
      setblackListingNumber({ ..._client });
  };
  // ------------------------------------------------------------------ //

  const oneSenderEntry =
    config.fakeConfig.defaultBroker !== "disable"
      ? config.fakeConfig.brokers[config.fakeConfig.defaultBroker].senders
      : [];

  return (
    <div className="blacklisting">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="textScope">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Origem:</label>
          </div>
          <select
            className="select-bootstrap mb-4"
            required
            onChange={(e) => handleChange("origin", e.target.value)}
            value={blackListingNumber.body.origin}
          >
            <option value="">Selecione...</option>
            {config.fakeConfig.defaultBroker !== "disable"
              ? oneSenderEntry.map((value, idx) => (
                  <option key={idx} value={value.origin}>
                    {value.origin}
                  </option>
                ))
              : config.fakeConfig.enabled.map((broker) =>
                  config.fakeConfig.brokers[broker].senders.map(
                    (value, idx) => (
                      <option key={idx} value={value.origin}>
                        {value.origin}
                      </option>
                    )
                  )
                )}
          </select>
        </div>

        <div className={blackListStyle.textScope}>
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Telefone:</label>
          </div>
          <input
            className="input-bootstrap"
            type="text"
            placeholder="Exemplo: +5511900001111"
            required
            maxLength="14"
            onChange={(e) => phoneVerify(e)}
            pattern="[\+]?[0-9]{2}?[0-9]{2}(9{1})?[0-9]{8}"
            value={blackListingNumber.body.phone}
          />
        </div>
        <div className={blackListStyle.containerBtn}>
          <button
            className={`btn btn-primary px-5 ${!phone && "disabled"}`}
            type="submit"
            disabled={!phone || !blackListingNumber?.body?.origin?.length > 0}
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}
