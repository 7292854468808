import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "ConversationID",
    headerName: "Total",
    flex: 1,
  },
  {
    field: "Telefone",
    headerName: "Sucesso",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Origen",
    headerName: "Insucesso",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Fila",
    headerName: "Porcentagem (Sucesso)",
    renderCell: (params) => {
      if (params.value === null) {
        return "System.Empty";
      }
      return params.value;
    },
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];




export default function GeneralConsolidatedTable(data) {
  const rows = data;
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          getRowId={(row) => {
            return row?.id;
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
