import MapChart from "../../../components/charts/dashboardHibernation/mapChart";
import dashboardStyle from "../dashboardHibernation.module.css";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import CircularLoading from "../../../components/loadModules/circularLoading";

export default function DashboardHibernationMap() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState([]);
  const [regions, setRegions] = useState({
    north: "",
    south: "",
    centralWest: "",
    southeast: "",
    northeast: "",
  });
  const statesPortuguese = {
    north: "Norte",
    south: "Sul",
    centralWest: "Centro-Oeste",
    southeast: "Sudeste",
    northeast: "Nordeste",
  };

  useEffect(() => {
    if (params.length) {
      callApi();
    }
  }, [params]);

  function formatContent(dataApi) {
    let _data = [];
    dataApi.forEach((item) => {
      const d = {
        state: item.UF,
        value: item.Hibernacoes,
      };
      _data.push(d);
    });
    groupStatesByRegion(_data);
    return _data;
  }

  async function callApi() {
    setLoading(true);
    await api
      .get(
        `/api/database/report/dashDDD?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length) {
          const formattedData = formatContent(res.data);
          setData(formattedData);
          toast.success("Busca realizada com sucesso!");
        } else {
          setData([]);
          toast.warning("Não há dados para essa data");
        }
      })
      .catch((e) => {
        toast.error("Houve um erro ao consultar os dados!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleParams(value) {
    let _params = value.split("-");

    if (_params[0] < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    } else {
      setParams(_params);
    }
  }

  function groupFunction(_data, stateKeys) {
    const filteredStates = _data.filter((dt) => stateKeys.includes(dt.state));
    let sum = 0;
    for (let st of filteredStates) {
      sum = sum + st.value;
    }
    return sum;
  }
  function sortByValue(obj) {
    const keyValueArray = Object.entries(obj);
    const sortedArray = keyValueArray.sort((a, b) => b[1] - a[1]);
    const sortedObject = Object.fromEntries(sortedArray);

    return sortedObject;
  }
  function groupStatesByRegion(_data) {
    const southKeys = ["RS", "PR", "SC"];
    const southeastKeys = ["RJ", "SP", "MG", "ES"];
    const northKeys = ["AM", "AC", "RO", "RR", "AP", "TO", "PA"];
    const centerWestKeys = ["MT", "MS", "DF", "GO"];
    const northeastKeys = [
      "AL",
      "BA",
      "CE",
      "MA",
      "PB",
      "PE",
      "PI",
      "RN",
      "SE",
    ];

    let _obj = {
      north: "",
      south: "",
      centralWest: "",
      southeast: "",
      northeast: "",
    };
    _obj.north = groupFunction(_data, northKeys);
    _obj.south = groupFunction(_data, southKeys);
    _obj.centralWest = groupFunction(_data, centerWestKeys);
    _obj.southeast = groupFunction(_data, southeastKeys);
    _obj.northeast = groupFunction(_data, northeastKeys);
    let res = sortByValue(_obj);

    setRegions(res);
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between">
        <div className="titlePage">Hibernação - Mapa</div>
        <div
          className={`${dashboardStyle.containerMonth} ${dashboardStyle.containerRelative} pe-5`}
        >
          <input
            type="month"
            className="input-bootstrap"
            onChange={(e) => handleParams(e.target.value)}
          />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {loading ? (
          <div style={{ height: "90vh" }}>
            <CircularLoading />
          </div>
        ) : (
          <>
            <MapChart data={data} />
            <div className={dashboardStyle.customCard}>
              {Object.entries(regions).map(([key, value]) => (
                <p>
                  {statesPortuguese[key]}: <span>{value}</span>
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
