import { useState, useEffect } from "react";
import { config } from "../../services/utils";
import cardStyle from "./asc.module.css";
import api from "../../services/api";
import SetHoliday from "./holidaysSet/setHoliday";
import SetSchedule from "./scheduleSet/setSchedule";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

export default function AssociationV1() {
  const [holidays, setHolidays] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [mapingHoliday, setMapingHoliday] = useState([]);
  const [mapingSchedule, setMapingSchedule] = useState([]);
  const [configuration, setConfiguration] = useState([]);

  useEffect(() => {
    async function loadHolidays() {
      await api
        .get("api/espresso/holidays", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setHolidays(res.data);
        });
    }

    async function loadSchedules() {
      await api
        .get("api/espresso/workschedule", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setSchedule(res.data);
        });
    }

    async function loadConfig() {
      await api
        .get("api/espresso/configuration", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setConfiguration(res.data);
        });
    }

    loadConfig();
    loadHolidays();
    loadSchedules();
  }, []);

  async function submitUra(origin, idx) {
    let parsedHolidays = [];
    let notEmpty = true;

    if (mapingHoliday.length !== 0 && notEmpty) {
      if (mapingHoliday[idx] == undefined) {
        mapingHoliday[idx] = [];
      }
      for (let i = 0; i < mapingHoliday[idx].length && notEmpty; i++) {
        notEmpty = Object.keys(mapingHoliday[idx][i]).length != 0;
      }
    }

    if (mapingHoliday.length !== 0) {
      for (let holiday of mapingHoliday[idx]) {
        if (Object.keys(holiday).length !== 0) {
          parsedHolidays.push(JSON.parse(holiday));
        }
      }
    }

    let obj = {
      origin: origin,
      holidays: !!mapingHoliday[idx] ? parsedHolidays : [],
      workSchedule: !!mapingSchedule[idx]
        ? schedule.find((s) => s._id === mapingSchedule[idx])
        : {},
    };

    // console.log(obj);

    if (Object.keys(obj.workSchedule).length !== 0 && notEmpty) {
      await api
        .post("api/espresso/configuration", obj, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          toast.success("Associação realizada com sucesso");
        })
        .catch(() => {
          toast.error("Erro na chamada de dados");
        });
    }
    if (Object.keys(obj.workSchedule).length === 0 && notEmpty) {
      console.log(obj);
      toast.warn("Favor inserir ao menos o Schedule para sua ura");
    }
    if (
      mapingHoliday.length !== 0 &&
      mapingHoliday[idx].length !== 0 &&
      !notEmpty
    ) {
      toast.warn("Favor selecione um feriado ou remover campos em branco");
    }
  }

  async function clearUra(origin) {
    await api
      .delete(`api/espresso/configuration?origin=${origin}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        toast.success("Configurações Removidas Com Sucesso");
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        if (error.response.data === "Not Found")
          toast.warn(`Não há configuração para deletar na ura: ${origin}`);
        else toast.error("Algo deu errado");
      });
  }

  function MountGrid() {
    return config.summarize.map((value, idx) => {
      const config = configuration.find((c) => c.origin === value.origin);
      return (
        <Grid className="mb-3 " key={idx} item md={4} xl={3}>
          <div className={cardStyle.cardContainer}>
            <div className={cardStyle.cardTitle}>
              {" "}
              <Tooltip title={value.visualName}>
                <p className={cardStyle.textTitle}> {value.visualName} </p>
              </Tooltip>{" "}
            </div>

            <SetSchedule
              schedule={schedule}
              mapingSchedule={mapingSchedule}
              setMapingSchedule={setMapingSchedule}
              outerIndex={idx}
              configuration={config}
            />

            <SetHoliday
              holidays={holidays}
              mapingHoliday={mapingHoliday}
              setMapingHoliday={setMapingHoliday}
              outerIndex={idx}
              configuration={config}
            />

            <br />
            <div className="d-flex w-100 justify-content-center pb-3">
              <Tooltip title="Limpar Configuração">
                <button
                  onClick={() => clearUra(value.origin)}
                  className="btn btn-danger btn-sm d-flex align-items-center me-3 "
                >
                  <p className="me-2"> Limpar</p>
                  <DeleteIcon sx={{ fontSize: "20px" }} />
                </button>
              </Tooltip>
              <Tooltip title="Salvar">
                <button
                  onClick={() => submitUra(value.origin, idx)}
                  className="btn btn-primary btn-sm d-flex align-items-center "
                >
                  <p className="me-2">Salvar</p>
                  <SaveIcon sx={{ fontSize: "20px" }} />
                </button>
              </Tooltip>
            </div>
          </div>
        </Grid>
      );
    });
  }

  return (
    <div className="container pe-3">
      <div className="d-flex justify-content-start w-100">
        <div className="titlePage py-2">Gerenciamento de Uras</div>
      </div>

      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {MountGrid()}
        </Grid>
      </Box>
    </div>
  );
}
