import React, { useState, useEffect } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/core";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export default function RdStationTable({ data, onEditClick }) {
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1 / 2,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "origin",
      headerName: "Origem",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "publicToken",
      headerName: "Chave Pública",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.integrations?.rdstationmkt?.publicToken) {
          return params.row.integrations.rdstationmkt.publicToken;
        } else {
          return "";
        }
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "privateToken",
      headerName: "Chave Privada",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.integrations?.rdstationmkt?.privateToken) {
          return params.row.integrations.rdstationmkt.privateToken;
        } else {
          return "";
        }
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "rule",
      headerName: "Regra",
      flex: 1,
      valueGetter: (params) => {
        if (params.row.integrations?.rdstationmkt?.HSMRules[0]?.rule) {
          return params.row.integrations.rdstationmkt.HSMRules.length;
        } else {
          return "0";
        }
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span className="table-cell-trucate">{params.value}</span>
          </Tooltip>
        );
      },
    },
    // {
    //   field: "skill",
    //   headerName: "Habilidades",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     if (params.row.integrations?.rdstationmkt?.HSMRules[0]?.skills) {
    //       return params.row.integrations.rdstationmkt.HSMRules[0].skills.join(
    //         ", "
    //       );
    //     } else {
    //       return "";
    //     }
    //   },
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title={params.value}>
    //         <span className="table-cell-trucate">{params.value}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Ações",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center pe-5">
            <Tooltip title="Editar">
              <IconButton
                sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#1abc9c" }}
                onClick={() => onEditClick(params.row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Excluir">
              <IconButton
                sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c" }}
                onClick={() => {
                  dellConfig(params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        );
      },
    },
  ];
  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );

  return (
    <div style={{ display: "flex", height: "110%", width: "98%" }}>
      <DataGrid
        columns={columns}
        rows={data}
        getRowId={(row) => row._id}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableVirtualization
        disableSelectionOnClick
      />
    </div>
  );
}
