import "./App.css";
import Routes from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
// import { getConfig } from "./services/newUtils";

function App() {
  useEffect(() => {
    const handleLogout = (event) => {
      if (
        (event.origin === "https://crm.espressolw.com" ||
          event.origin == "https://devcrm.espressolw.com") &&
        event.data == "logout"
      ) {
        localStorage.clear();
      }
    };

    window.addEventListener("message", handleLogout);
    return () => {
      setTimeout(() => {
        window.removeEventListener("message", handleLogout);
      }, 1500);
    };
  }, []);
  // getConfig()
  return (
    <div className="App">
      <Routes />
      <ToastContainer autoClose={3000} theme="colored" />
    </div>
  );
}

export default App;
