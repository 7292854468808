import React from "react";
import Unblock from "./unblock/unblock";
import ConfigTabs from "./configTabs/configTabs";
import styleTools from "./tools.module.css";
import { config } from "../../services/utils";

export default function Tools() {
  return (
    <div className={styleTools.toolsContainer}>
      <div className="titlePage">Ferramentas:</div>
      <div className={styleTools.container}>
        {config.application.modules.toolsGeneral.configs ? (
          <div className={styleTools.cardConfig}>
            <ConfigTabs />
          </div>
        ) : null}
        {config.application.modules.toolsGeneral.unlocker ? (
          <div className={styleTools.cardUnblock}>
            <div className={styleTools.toolsCentral}>
              <Unblock />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
