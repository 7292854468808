import { useState, useEffect } from "react";
import CircularLoading from "../../../components/loadModules/circularLoading";
import { toast } from "react-toastify";
import dashStyle from "../dashboardHibernation.module.css";
import api from "../../../services/api";
import RankingNumber from "../../../components/tables/hibernationVia/RankingNumber.js";

export default function DashboardUniqueNumberRanking() {
  const [params, setParams] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (params.length) {
      callApi();
    }
  }, [params]);

  async function callApi() {
    setLoading(true);
    await api
      .get(
        `/api/database/report/dashRankingUniqueNumber?year=${params[0]}&month=${params[1]}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        let _data = formatData(res.data[0]);
        let _percent = calcPercent(_data);
        let _unified = unifyObjects(_data, _percent);
        setData(_unified);
      })
      .catch((e) => {
        setData([]);
        toast.error("Houve um erro ao tentar consultar o banco de dados!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getTotal(obj, keys) {
    let total = 0;

    for (let key of keys) {
      total = total + obj[key];
    }
    return total;
  }

  function calcPercent(value) {
    const categories = ["DISCONNECTED", "RETURNED_AGENT", "RETURNED_QUEUE"];

    const totalDisconected = getTotal(
      value[categories[0]],
      Object.keys(value[categories[0]])
    );

    const totalReturnedAgent = getTotal(
      value[categories[1]],
      Object.keys(value[categories[2]])
    );

    const totalReturnedQueue = getTotal(
      value[categories[2]],
      Object.keys(value[categories[2]])
    );

    let totals = {
      DISCONNECTED: totalDisconected,
      RETURNED_AGENT: totalReturnedAgent,
      RETURNED_QUEUE: totalReturnedQueue,
    };
    const percents = {};
    for (let category of categories) {
      let percent = getPercent(
        value[category],
        Object.keys(value[category]),
        totals[category]
      );
      percents[category] = percent;
    }
    return percents;
  }

  function unifyObjects(_data, percent) {
    let keys = Object.keys(_data.RETURNED_AGENT);
    const _dataFormatted = [];

    for (let key of keys) {
      let obj = {
        id: key,
        qtdCasos: key,
        returnedAgent: _data.RETURNED_AGENT[key],
        returnedQueue: _data.RETURNED_QUEUE[key],
        disconnected: _data.DISCONNECTED[key],
        percentDisconnected: percent.DISCONNECTED[key],
        percentReturnedQueue: percent.RETURNED_QUEUE[key],
        percentReturnedAgent: percent.RETURNED_AGENT[key],
      };
      _dataFormatted.push(obj);
    }
    console.log(_dataFormatted);
    return _dataFormatted;
  }

  function getPercent(obj, keys, total) {
    let result = {};

    for (let key of keys) {
      result[key] = `${((obj[key] / total) * 100).toFixed(2)}%`;
    }
    return result;
  }

  function formatData(value) {
    const categories = ["DISCONNECTED", "RETURNED_AGENT", "RETURNED_QUEUE"];

    const allKeys = new Set();

    categories.forEach((category) => {
      Object.keys(value[category]).forEach((key) => allKeys.add(key));
    });

    const orderKeys = Array.from(allKeys).sort((a, b) => a - b);

    let result = {};
    categories.forEach((category) => {
      result[category] = {};
      orderKeys.forEach((key) => {
        result[category][key] = value[category][key] || 0;
      });
    });

    return result;
  }
  function handleChange(value) {
    let _params = value.split("-");
    setParams(_params);
    if (_params[0] < 2024) {
      toast.error("O ano não pode ser inferior a 2024");
      return;
    }
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between me-5 pe-3 mb-4">
        <div className="titlePage">Hibernação - Números únicos ranking</div>
        <div className={dashStyle.containerMonth}>
          <input
            onChange={(e) => handleChange(e.target.value)}
            className="input-bootstrap"
            type="month"
          />
        </div>
      </div>
      <div className="me-5">
        {loading ? (
          <div style={{ height: "90vh", width: "98%" }}>
            <CircularLoading />
          </div>
        ) : (
          <RankingNumber data={data} />
        )}
      </div>
    </div>
  );
}
