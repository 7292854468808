import Chart from "react-apexcharts";

export default function LineChartHibernation({ data }) {
  const chartData = {
    series: [
      {
        name: "Desconectado",
        data: [],
      },
      {
        name: "Retorno para agente",
        data: [],
      },
      {
        name: "Retorno para fila",
        data: [],
      },
    ],
    options: {
      plotOptions: {
        chart: {
          type: "line",
          markers: {
            size: 6,
            strokeWidth: 0,
            hover: {
              size: 8,
            },
          },
        },
      },
      markers: {
        size: 5,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#007BFF", "#26979E", "#456099"],

      legend: {
        show: true,
      },

      xaxis: {
        categories: [],
      },

      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
    },
  };

  data.forEach((day) => {
    const dayName = day.day;
    chartData.options.xaxis.categories.push(dayName);
    chartData.series[0].data.push(parseInt(day.DISCONNECTED));
    chartData.series[1].data.push(parseInt(day.RETURNED_AGENT));
    chartData.series[2].data.push(parseInt(day.RETURNED_QUEUE));
  });

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={`270px`}
    />
  );
}
