import * as React from "react";
import ReactPlayer from "react-player";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
//import "./table.css";
import "./chatballon.css";

function midiaFormat(value) {
  const images = [".jpeg", ".gif", ".png", ".bmp", ".jpg", "webp"];
  const video = [".amr", ".mpeg", ".mp4"];
  const audio = [
    ".basic",
    ".L24",
    ".ogg",
    ".vorbis",
    ".vnd",
    ".wave",
    ".3gpp",
    ".3gpp2",
    ".ac3",
    ".webm",
    ".audio",
    ".mp3",
    ".mpga",
    ".voice",
    ".oga",
    ".opus",
    ".wav",
  ];
  const arquivo = [
    ".doc",
    ".docx",
    ".pdf",
    ".xlsx",
    ".xls",
    ".csv",
    ".txt",
    ".pptx",
    ".ppt",
  ];

  for (let i = 0; i < images.length; i++) {
    if (value.includes(images[i])) {
      return (
        <div className="autoResize">
          <a href={value} target="_blank">
            <img src={value} alt="" />
          </a>
        </div>
      );
    }
  }
  for (let i = 0; i < video.length; i++) {
    if (value.includes(video[i])) {
      return <ReactPlayer url={value} controls width="100%" height="auto" />;
    }
  }
  for (let i = 0; i < audio.length; i++) {
    if (value.includes(audio[i])) {
      if (audio[i] == ".mp3" || audio[i] == ".ogg" || audio[i] == ".opus") {
        return (
          <audio controls>
            <source src={value} />
          </audio>
        );
      } else {
        return (
          <div>
            <ReactPlayer url={value} controls width="100%" height="auto" />
          </div>
        );
      }
    }
  }
  for (let i = 0; i < arquivo.length; i++) {
    if (value.includes(arquivo[i])) {
      return (
        <a href={value} target="_blank" className="linkHistory">
          <span className="playBtn">
            <PlayCircleFilledWhiteIcon />
            Abrir Arquivo
          </span>
        </a>
      );
    }
  }

  return value;
}

function formatData(data) {
  let arrayData = data.split(" ");
  if (arrayData.length !== 2) {
    return data;
  }

  const datePart = arrayData[0];
  const timePart = arrayData[1];

  let arrayDate = datePart.split("-");
  if (arrayDate.length !== 3) {
    return data;
  }

  const d = arrayDate[2];
  const m = arrayDate[1];
  const y = arrayDate[0].slice(-2);

  return `${d}/${m}/${y} ${timePart}`;
}

function isAgent(person) {
  if (person == "Cliente") {
    return "agent";
  }
  if (person.toLowerCase() == "bot" || person.toLowerCase().includes("ura")) {
    return "bot agent";
  } else {
    return "client";
  }
}

function sideBallon(origen) {
  if (
    origen.toLowerCase() == "cliente" ||
    origen.toLowerCase() == "bot" ||
    origen.toLowerCase().includes("ura")
  ) {
    return "side-l container-ballon mb-4";
  } else {
    return "side-r container-ballon mb-4";
  }
}

function updateClass(string, query) {
  if (query.length) {
    query = query.toLowerCase();
    if (string.toLowerCase().includes(query)) {
      return "text query-text";
    }
    return "text";
  } else {
    return "text";
  }
}

function origenIcon(origen) {
  if (origen.toLowerCase() == "bot" || origen.toLowerCase().includes("ura")) {
    return <SmartToyIcon className="pr" />;
  }
  if (origen.toLowerCase() == "cliente") {
    return <AccountCircleIcon className={origen == "Cliente" ? "pr" : "pl"} />;
  } else {
    return <SupportAgentIcon className="pl" />;
  }
}

export default function ChatBaloon({ conversations, query }) {
  return (
    <div className={"container-chat"}>
      {conversations.conversas.map((conversa, index) => (
        <div
          className={sideBallon(conversa.origem)}
          key={`${index}-${conversa.origem}`}
        >
          {origenIcon(conversa.origem)}
          <div className={isAgent(conversa.origem)}>
            <p className={"type"}>{conversa.origem}</p>
            <div className={updateClass(conversa.texto, query)}>
              {midiaFormat(conversa.texto)}
            </div>
            <div className={"data-container"}>
              <p className={"data"}>{formatData(conversa.data)}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
