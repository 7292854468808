import Chart from "react-apexcharts";

export default function PieChartHibernation({ data, status }) {
  const chartData = {
    series: [],
    options: {
      plotOptions: {
        type: "pie",
        width: "500px",
      },
      colors: ["#007BFF", "#26979E", "#456099"],
      labels: [],
      legend: {
        position: "bottom",
      },
    },
    dataLabels: {
      enabled: true,
    },

    legend: {
      show: true,
    },
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
  };

  data.forEach((item) => {
    chartData.series.push(item.data);
    chartData.options.labels.push(item.name);
  });
  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="pie"
      height={"250px"}
    />
  );
}
