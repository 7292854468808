import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import ConsolidatedBackOffice from "../../components/tables/ConsolidatedBackOffice.js";
import api from "../../services/api";
import { toast } from "react-toastify";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import CircularLoading from "../../components/loadModules/circularLoading.js";

export default function SummarizeConsolidateBackOffice() {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [call, setCall] = useState(false);
  const [retornoAPI, setRetornoAPI] = useState([]);

  const loadTables = async () => {
    if (!end.length || !start.length) {
      toast.warning("Preencha os dados para a busca");
      return;
    }
    setCall(true);
    await api
      .get(
        `/api/database/report/consolidado_por_envio?date_start=${dateConversion(
          start
        )}&date_end=${dateConversion(end)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha na pesquisa");
      })
      .finally(() => {
        setCall(false);
      });
  };

  const cleanSearch = () => {
    setEnd("");
    setStart("");
    setRetornoAPI([]);
    toast.success("Os Campos foram limpos");
  };

  function handleChange(type, value) {
    switch (type) {
      case "start":
        setStart(value);
        break;
      case "end":
        setEnd(value);
        break;

      default:
        break;
    }
  }

  const dateConversion = (dateToConvert) => {
    return new Date(new Date(dateToConvert).getTime()).toISOString();
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex justify-content-between">
        <div className="titlePage">Back Office Consolidado</div>
        <div className="d-flex pe-3">
          <div className="d-flex align-items-center">
            <label className="defaultLabel me-2">De: </label>
            <div className={summarizeStyle.containerInputHeader}>
              <input
                type="dateTime-Local"
                value={start}
                id="searchdatefrom"
                className="input-bootstrap"
                onChange={(e) => handleChange("start", e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex align-items-center ms-4 me-3">
            <label className="defaultLabel me-2">Até: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={end}
              id="searchdateto"
              onChange={(e) => handleChange("end", e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        {!call ? (
          <StyledEngineProvider injectFirst>
            <ConsolidatedBackOffice data={retornoAPI}></ConsolidatedBackOffice>
          </StyledEngineProvider>
        ) : (
          <CircularLoading />
        )}
      </div>
    </div>
  );
}
