import React, { useState } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../../components/tables/table.css";
import Tooltip from "@mui/material/Tooltip";
import ChatIcon from "@mui/icons-material/Chat";
import CustomizedDialogs from "../../../components/tables/modalOpen";

export default function WidgetTimedHSMTable({ timedHSM }) {
  const columns = [
    { field: "phonecontact", headerName: "Telefone", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "scheduleDate", headerName: "Data", type: "dateTime", flex: 2, headerAlign: "center", align: "center",
      renderCell: (params) => (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-trucate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "mensagemreq",
      headerName: "Mensagem",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
          <strong>
            <CustomizedDialogs
              modalSize={{ height: 40, width: 30 }}
              buttonString={<ChatIcon style={{ color: "#1abc9c" }} />}
              boxName="Mensagem"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
              iconButton={true}
            >
              <div>{params.value}</div>
            </CustomizedDialogs>
          </strong>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < timedHSM.length; i++) {
    rows.push({
      id: i + 1,
      ...timedHSM[i],
    });
  }

  return (
    <div style={{ height: '100%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "scheduleDate", sort: "asc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
