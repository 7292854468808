import interactionsStyle from "./DashInteraction.module.css";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import BarChartIcon from "@mui/icons-material/BarChart";
import ActionBtn from "../../components/UI/actionbtn";

export default function DashInteraction() {
  // States

  const [days, setDays] = useState([]);
  // const [Wrapup, setWrapup] = useState([]);
  // const [newTab, setNewTab] = useState({ queue: '', descricao: '', child: [] });

  // End State

  //OnPage Load
  useEffect(() => {
    // let w = [
    //   {
    //     day: 1,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 2,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 3,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 4,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 5,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 6,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 7,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 8,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 9,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 10,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 11,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 12,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 13,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 14,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 15,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 16,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 17,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 18,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 19,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 20,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 21,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 22,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 23,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 24,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 25,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 26,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 27,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 28,
    //     months: {
    //       jan: 0,
    //       fev: 0,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 29,
    //     months: {
    //       jan: 0,
    //       fev: undefined,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 30,
    //     months: {
    //       jan: 0,
    //       fev: undefined,
    //       mar: 0,
    //       abr: 0,
    //       mai: 0,
    //       jun: 0,
    //       jul: 0,
    //       ago: 0,
    //       set: 0,
    //       out: 0,
    //       nov: 0,
    //       dez: 0,
    //     },
    //   },
    //   {
    //     day: 31,
    //     months: {
    //       jan: 0,
    //       fev: undefined,
    //       mar: 0,
    //       abr: undefined,
    //       mai: 0,
    //       jun: undefined,
    //       jul: 0,
    //       ago: 0,
    //       set: undefined,
    //       out: 0,
    //       nov: undefined,
    //       dez: 0,
    //     },
    //   },
    // ];

    // setDays(w);
    api
      .get("api/database/report/interactions", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        setDays(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  }, []);
  // End Page Load//
  const tdays = [];
  for (const i in days) {
    tdays.push(
      <tr key={days[i].day}>
        <td>{days[i].day}</td>
        <td>{days[i].months.jan}</td>
        <td>{days[i].months.fev}</td>
        <td>{days[i].months.mar}</td>
        <td>{days[i].months.abr}</td>
        <td>{days[i].months.mai}</td>
        <td>{days[i].months.jun}</td>
        <td>{days[i].months.jul}</td>
        <td>{days[i].months.ago}</td>
        <td>{days[i].months.set}</td>
        <td>{days[i].months.out}</td>
        <td>{days[i].months.nov}</td>
        <td>{days[i].months.dez}</td>
      </tr>
    );
  }
  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between me-4">
        <p className="titlePage">Conversas</p>
        <Link to="/app/dashboard/yearinteractions">
          <ActionBtn color="green" icon={<BarChartIcon />} />
        </Link>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <table className={interactionsStyle.dashTable}>
            <thead>
              <tr>
                <th>Dia</th>
                <th>Janeiro</th>
                <th>Fevereiro</th>
                <th>Março</th>
                <th>Abril</th>
                <th>Maio</th>
                <th>Junho</th>
                <th>Julho</th>
                <th>Agosto</th>
                <th>Setembro</th>
                <th>Outubro</th>
                <th>Novembro</th>
                <th>Dezembro</th>
              </tr>
            </thead>
            <tbody>{tdays}</tbody>
          </table>
        </StyledEngineProvider>
      </div>
    </div>
  );
}
