import React, { useState } from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../components/tables/table.css";
import CustomizedDialogs from "../../components/tables/modalOpen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../services/api";
import { toast } from "react-toastify";
import RegisterMessageHSM from "../messages/registerMessage"; //mudar para o de numbero
import { dellCell } from "../../services/utils";

export default function PeriodTable({ period }) {
  const [cdMessage, setCDMessage] = useState({});

  const columns = [
    { field: "codemessage", headerName: "Ura", flex: 1 },
    { field: "type", headerName: "Período", flex: 1 },
    { field: "info", headerName: "Mensagem", flex: 3 },

    {
      field: "alteration",
      headerName: "Alterações",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="inline_Alt">
          <div onClick={() => {}}>
            <CustomizedDialogs
              modalSize={{height:40,width:35}}
              buttonString={<EditIcon />}
              boxName="Editar Número da Blocklist"
              className="send"
              styleButton={{
                bgcolor: "#1ABC9C",
                color: "white",
                fontSize: 16,
                borderRadius: 2,
                minWidth: "5px",
                borderSize: 0,
                padding: "1vh 0.5vw 1vh 0.5vw",
                ":hover": { bgcolor: "#16a085" },
              }}
            >
              <RegisterMessageHSM codemessage={params.value} />
            </CustomizedDialogs>
          </div>
          <button
            className="btn_DEL"
            onClick={() => {
              dellCell(params.value);
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  if(!!period){  
    for (let i = 0; i < period.length; i++) {
      rows.push({
        id: i + 1,
        alteration: period[i].codemessage,
        ...period[i],
      });
    }
  }

  return (
    <div style={{ height: '110%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createDate", sort: "desc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
