import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";
import SmallModal from "../UI/Modals/Small";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChatIcon from "@mui/icons-material/Chat";
import ListIcon from "@mui/icons-material/List";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.5vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "_id",
    headerName: "Id",
    align: "left",
    headerAlign: "center",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        "-"
      );
    },
  },
  {
    field: "Code Contact",
    headerName: "Cod. contato",
    flex: 1 / 2,
    align: "left",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },

  {
    field: "Nome Mailing",
    headerName: "Nome mailing",
    headerAlign: "center",
    flex: 1,
    align: "left",
    renderCell: (params) => {
      return params.value ? (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      ) : (
        <div className="text-center">0</div>
      );
    },
  },
  {
    field: "Data Envio",
    headerName: "Data do envio",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={new Date(params.value).toLocaleString()}>
            <span className="table-cell-truncate">
              {new Date(params.value).toLocaleString()}
            </span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Telefone",
    headerName: "Telefone",
    headerAlign: "center",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Telefone1",
    headerName: "Telefone_1",
    headerAlign: "center",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Telefone2",
    headerName: "Telefone_2",
    headerAlign: "center",
    align: "left",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },

  {
    field: "Origem",
    headerName: "Origem",
    flex: 1,
    align: "left",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Nome",
    headerName: "Nome",
    align: "left",
    headerAlign: "center",
    flex: 1 / 2,
    renderCell: (params) => {
      return (
        <div>
          <Tooltip title={params.value}>
            <span className="table-cell-truncate">{params.value}</span>
          </Tooltip>
        </div>
      );
    },
  },
  {
    field: "Pedido",
    headerName: "Pedido",
    align: "center",
    headerAlign: "center",
    flex: 1 / 2,
    renderCell: (params) => {
      return params.value ? (
        <div>
          <div>
            <Tooltip title={params.value}>
              <span className="table-cell-truncate">{params.value}</span>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="text-center">0</div>
      );
    },
  },
  {
    field: "Protocolo",
    headerName: "Protocolo",
    flex: 1 / 2,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return params.value ? (
        <div>
          <div>
            <Tooltip title={params.value}>
              <span className="table-cell-truncate">{params.value}</span>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="text-center">0</div>
      );
    },
  },
  {
    field: "Endereço",
    headerName: "Endereço",
    flex: 1,
    headerAlign: "center",
    align: "left",
    renderCell: (params) => (
      <div>
        <Tooltip title={params.value}>
          <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      </div>
    ),
  },
];

function transformObjects(data) {
  let arr = [];
  for (let i in data) {
    arr.push({
      ...data[i],
      codeMailing: data[i]?._id.codemailing,
      data: data[i]?._id.data,
      nameMailing: data[i]?._id.namemailing,
    });
  }

  return arr;
}
function generateDynamicId(length = 15) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    id += characters.charAt(randomIndex);
  }

  return id;
}

export default function MailingGenesys(data) {
  const rows = transformObjects(data.data);
  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          getRowId={(row) => {
            return generateDynamicId(26);
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
