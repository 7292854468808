import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../components/tables/table.css"
import { withStyles } from "@material-ui/core";
import CircularLoading from "../../components/loadModules/circularLoading";
import { v4 as UwUid } from "uuid";
import Tooltip from "@mui/material/Tooltip";

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport csvOptions={{ delimiter: ";" }} printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    }
  },
})(DataGrid);

export default function ComebackTable({ retornoAPI, loading }) {
  var rows = [];

  const columns = [
    {
      field: "origin",
      headerName: "Origem",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "crmId",
      headerName: "CRM ID",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "startTime",
      headerAlign: "center",
      align: "center",
      headerName: "Data de Entrada",
      type: "dateTime",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip title={hours(new Date(params.value))}>
            <span className="table-cell-trucate">{hours(new Date(params.value))}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "endingTime",
      headerName: "Data de Saída",
      type: "date",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip title={hours(new Date(params.value))}>
            <span className="table-cell-trucate">{hours(new Date(params.value))}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Motivo Desconexão",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <Tooltip title={status(params.value)}>
            <span className="table-cell-trucate">{status(params.value)}</span>
          </Tooltip>
        </div>
      ),
    }
  ];

  function hours(date) {
    var placeholder = new Date(date.getTime() + (date.getTimezoneOffset() * 60000)).toLocaleString()
    return placeholder
  }

  function status(sts){
    switch (sts) {
      case 'HIBERNATE': return <span>Hibernado</span>
      case 'RETURNED': return <span>Retorno</span>
      case 'DISCONNECTED': return <span>Disconectado</span>
      default:
        <span>{sts}</span>;
    }
  }

  if (retornoAPI.length !== 0) {
    for (let i = 0; i < retornoAPI.length; i++) {
      for (let j = 0; j < retornoAPI[i].hibernationData.length; j++) {
        rows.push({ id: UwUid(), ...retornoAPI[i].hibernationData[j], ...retornoAPI[i] });
      }
    }
  }

  return loading ?
    (<div style={{ height: '110%' }}>
      <StyledDataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
      />
    </div>) : (<div style={{ width: '96%', height: '110%' }}><CircularLoading /></div>)
}
