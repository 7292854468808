import { Button } from "@mui/material";
import "./gb.css";

export default function ActionBtn({ color, icon, text }) {
  const greenStyle = {
    bgcolor: "#0d6efd",
    color: "white",
    padding: 1,
    fontSize: 12,
    borderRadius: 2,
    boxShadow: 3,
    marginTop: 0,
    marginBottom: 0.2,
    marginLeft: 1,
    fontFamily: "Open Sans",
    fontWeight: 600,
    ":hover": { bgcolor: "#0347ad" },
  };

  const yellowStyle = {
    bgcolor: "#f52727",
    color: "white",
    padding: 1,
    fontSize: 12,
    fontFamily: "Open Sans",
    fontWeight: 600,
    borderRadius: 2,
    boxShadow: 3,
    marginTop: 0,
    marginBottom: 0.2,
    marginLeft: 1,
    ":hover": { bgcolor: "#9e0101" },
  };

  return (
    <Button
      variant="contained"
      sx={color === "yellow" ? yellowStyle : greenStyle}
    >
      {!text ? (
        <div className="notText">{icon}</div>
      ) : (
        <div className="containedbtn">
          {icon} {text}
        </div>
      )}
    </Button>
  );
}
